import {urlSanitizer} from '@/services/sanitizers';

import {ErrorEvent, EventHint} from '@sentry/react';
import {RaygunPayload} from 'raygun4js';
import {IRequest, IResponse} from './types';

export function responseSanitizer(response: IResponse) {
    delete response.body;
    return response;
}

export function requestSanitizer(request: IRequest) {
    delete request.headers['x-auth-token'];
    delete request.headers['Authorization'];
    delete request.headers['X-Impersonation-Token'];
    delete request.body;
    request.url = urlSanitizer(request.url);
    return request;
}

type ExtendedRaygunPayload = RaygunPayload & {
    Details: {
        Breadcrumbs?: Array<{
            CustomData?: {
                baseUrl?: string;
                requestURL?: string;
                responseURL?: string;
            };
            message?: string;
        }>;
    };
};
export function raygunPayloadSanitizer(payload: ExtendedRaygunPayload) {
    if (payload.Details?.Breadcrumbs) {
        payload.Details.Breadcrumbs = payload.Details.Breadcrumbs.filter(breadcrumb => {
            return breadcrumb.CustomData?.baseUrl !== 'https://r.lr-in-prod.com/i';
        });
        payload.Details.Breadcrumbs.forEach(breadcrumb => {
            if (breadcrumb.message) {
                breadcrumb.message = urlSanitizer(breadcrumb.message);
            }
            if (breadcrumb.CustomData) {
                if (breadcrumb.CustomData.baseUrl) {
                    breadcrumb.CustomData.baseUrl = urlSanitizer(
                        breadcrumb.CustomData.baseUrl
                    );
                }
                if (breadcrumb.CustomData.requestURL) {
                    breadcrumb.CustomData.requestURL = urlSanitizer(
                        breadcrumb.CustomData.requestURL
                    );
                }
                if (breadcrumb.CustomData.responseURL) {
                    breadcrumb.CustomData.responseURL = urlSanitizer(
                        breadcrumb.CustomData.responseURL
                    );
                }
            }
        });
    }
    payload.Details.Request.Url = urlSanitizer(payload.Details.Request.Url);
    payload.Details.Request.Headers.Referer = urlSanitizer(
        payload.Details.Request.Headers.Referer
    );
    return payload;
}

export function sentryEventSanitizer(event: ErrorEvent, _hint: EventHint) {
    if (event.request) {
        if (event.request.url) {
            event.request.url = urlSanitizer(event.request.url);
        }
        if (event.request.headers?.Referer) {
            event.request.headers.Referer = urlSanitizer(event.request.headers.Referer);
        }
    }
    event.breadcrumbs = event.breadcrumbs?.filter(breadcrumb => {
        const url = breadcrumb.data?.url as string;
        if (url) {
            return (
                !url.includes('https://api.raygun.io') &&
                !url.includes('https://r.lr-in-prod.com/i')
            );
        }
        return true;
    });
    event.breadcrumbs?.forEach(breadcrumb => {
        if (breadcrumb.data) {
            if (breadcrumb.data.url) {
                breadcrumb.data.url = urlSanitizer(breadcrumb.data.url);
            }
        }
    });
    return event;
}
