import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useUpdateUserRelevanceInformation} from '@/api/jobRecommendations/useUpdateUserRelevanceInformation';
import {ConsentType, UpdateUserExperienceInput} from '@/api/types/__generated__/graphql';
import {useGiveConsent} from '@/api/users/useGiveConsent';
import {
    ERROR_TYPES,
    FormType
} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {useInformationLogic} from '@/pages/User/components/RecommendationRelevanceForm/hooks/useInformationLogic';
import {useFormOptions} from '@/pages/common/RelevanceForm/hooks/useFormOptions';
import {useFormValidationErrors} from '@/pages/common/RelevanceForm/hooks/useFormValidationErrors';
import {useInterestedInLeadershipPositionsLogic} from '@/pages/common/RelevanceForm/hooks/useInterestedInLeadershipPositionsLogic';
import {useJobFamilyLogic} from '@/pages/common/RelevanceForm/hooks/useJobFamilyLogic';
import {useLinkedInProfileLogic} from '@/pages/common/RelevanceForm/hooks/useLinkedInProfileLogic';
import {useLocationLogic} from '@/pages/common/RelevanceForm/hooks/useLocationLogic';
import {useScrollToFormError} from '@/pages/common/RelevanceForm/hooks/useScrollToFormError';
import {logger} from '@/services/logrocket';
import {useStaticLegalContent} from '@/services/staticLegalContent/useStaticLegalContent';
import {isDefined} from '@/utils/typeGuards/isDefined';

import isEqual from 'lodash.isequal';
import {useWorkExperienceLogic} from './hooks/useWorkExperienceLogic';
import {RecommendationRelevanceFormProps} from './types';
import {UserExperience, getClientSelectedUserRelevanceInformation} from './utils';

export function useCandidateRelevanceFormLogic({
    close,
    userId,
    relevanceParametersOptions,
    userRelevanceInformation,
    onSubmit
}: RecommendationRelevanceFormProps) {
    const {
        formValidationErrors,
        setFormValidationErrors,
        removeValidationError,
        getErrorText
    } = useFormValidationErrors(FormType.CANDIDATE);

    const [submitTimestamp, setSubmitTimestamp] = useState<number | undefined>();
    const [isFormSubmittedSuccessfully, setIsFormSubmittedSuccessfully] = useState(false);

    const [updateUserRelevanceInformation, {loading: updatingUserRelevanceInformation}] =
        useUpdateUserRelevanceInformation();

    const [giveConsent, {loading: givingConsent}] = useGiveConsent();

    const navigate = useNavigate();

    const closeInitialProfileSubmitSucessModal = () => {
        close();
        navigate('/start');
    };
    const {scrollToError} = useScrollToFormError();
    const [showConfirmLeaveRelevanceModal, setShowConfirmLeaveRelevanceModal] =
        useState(false);
    const {
        companyTypes,
        languages,
        cities,
        jobFamilies,
        getSelectableJobFamilySpecialities,
        workLocations
    } = useFormOptions(relevanceParametersOptions);

    const {selectedLanguages, setSelectedLanguages, handleLanguagesSelection} =
        useInformationLogic(removeValidationError);

    const {
        selectedWorkLocations,
        setSelectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        selectedCities,
        setSelectedCities,
        handleCitiesSelection,
        isCitySelectionsValid,
        setCitiesSearchKey,
        citiesQueryResults,
        citiesQueryLoading
    } = useLocationLogic(removeValidationError);

    const {
        jobFamily,
        setJobFamily,
        handleJobFamilySelection,
        jobFamilySpecialties,
        selectableJobFamilySpecialities,
        setSelectableJobFamilySpecialities,
        setJobFamilySpecialities,
        handleJobFamilySpecialitySelection
    } = useJobFamilyLogic(getSelectableJobFamilySpecialities, removeValidationError);

    const {interestedInLeadershipPositions, setInterestedInLeadershipPositions} =
        useInterestedInLeadershipPositionsLogic();
    const {
        setLinkedinProfileUrl,
        isLinkedinProfileUrlValid,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange
    } = useLinkedInProfileLogic(removeValidationError);
    const {loading: loadingCandidateServices, data: candidateServices} = useStaticLegalContent(
        {
            type: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES
        }
    );

    const {
        workExperience,
        setWorkExperience,
        isValid: isWorkExperienceValid,
        invalidIds: invalidWorkExperienceIds,
        hasFieldError: workExperienceHasFieldError
    } = useWorkExperienceLogic();

    const loading =
        updatingUserRelevanceInformation || givingConsent || loadingCandidateServices;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!userRelevanceInformation) {
            return;
        }

        const clientSelectedUserRelevanceInformation =
            getClientSelectedUserRelevanceInformation(userRelevanceInformation);

        setSelectedWorkLocations(
            clientSelectedUserRelevanceInformation.preferredWorkLocations
        );
        setInterestedInLeadershipPositions(
            clientSelectedUserRelevanceInformation.interestedInLeadershipPositions
        );
        setJobFamily(clientSelectedUserRelevanceInformation.preferredJobFamily);
        setSelectableJobFamilySpecialities(
            getSelectableJobFamilySpecialities(
                clientSelectedUserRelevanceInformation.preferredJobFamily
            )
        );
        setJobFamilySpecialities(clientSelectedUserRelevanceInformation.preferredSpecialties);
        setSelectedLanguages(clientSelectedUserRelevanceInformation.spokenLanguages);
        if (clientSelectedUserRelevanceInformation?.preferredCitiesWithMetaData) {
            setSelectedCities(
                clientSelectedUserRelevanceInformation.preferredCitiesWithMetaData
            );
        }
        setLinkedinProfileUrl(clientSelectedUserRelevanceInformation.linkedinProfileUrl);
        setWorkExperience(clientSelectedUserRelevanceInformation.userExperiences);
    }, [userRelevanceInformation, getSelectableJobFamilySpecialities]);

    const editedExistingValues = useMemo(() => {
        if (!userRelevanceInformation) {
            return false;
        }

        const clientSelectedUserRelevanceInformation =
            getClientSelectedUserRelevanceInformation(userRelevanceInformation);

        const areEqual = (valueOne: unknown, valueTwo: unknown) =>
            JSON.stringify(valueOne) === JSON.stringify(valueTwo);

        return !(
            areEqual(userRelevanceInformation.linkedinProfileUrl, linkedinProfileUrl) &&
            areEqual(userRelevanceInformation.preferredJobFamily, jobFamily) &&
            areEqual(userRelevanceInformation.preferredSpecialties, jobFamilySpecialties) &&
            areEqual(
                Boolean(userRelevanceInformation.interestedInLeadershipPositions),
                interestedInLeadershipPositions
            ) &&
            areEqual(userRelevanceInformation.spokenLanguages, selectedLanguages) &&
            areEqual(userRelevanceInformation.preferredWorkLocations, selectedWorkLocations) &&
            areEqual(
                userRelevanceInformation.preferredCitiesWithMetaData.map(city => city.id),
                selectedCities?.map(city => city.id)
            ) &&
            isEqual(clientSelectedUserRelevanceInformation.userExperiences, workExperience)
        );
    }, [
        interestedInLeadershipPositions,
        jobFamily,
        jobFamilySpecialties,
        linkedinProfileUrl,
        selectedCities,
        selectedLanguages,
        selectedWorkLocations,
        workExperience,
        userRelevanceInformation
    ]);

    const handleSubmit = useCallback(async () => {
        setSubmitTimestamp(Date.now());
        const validationErrors = [];
        if (!isLinkedinProfileUrlValid) {
            validationErrors.push(ERROR_TYPES.LINKEDIN_URL);
        }
        if (!jobFamily || jobFamilySpecialties.length === 0) {
            validationErrors.push(ERROR_TYPES.JOB_FAMILY);
        }
        if (!languages || selectedLanguages.length === 0) {
            validationErrors.push(ERROR_TYPES.LANGUAGES);
        }
        if (!workLocations || selectedWorkLocations.length === 0) {
            validationErrors.push(ERROR_TYPES.WORK_LOCATIONS);
        }
        if (!isCitySelectionsValid) {
            validationErrors.push(ERROR_TYPES.CITIES);
        }
        if (!isWorkExperienceValid) {
            validationErrors.push(ERROR_TYPES.EXPERIENCE);
        }

        setFormValidationErrors(validationErrors);
        if (validationErrors.length > 0) {
            scrollToError(validationErrors[0]);
            return;
        }

        // don't submit a request if nothing is changed or if the consent has not been loaded
        if (
            userRelevanceInformation &&
            !editedExistingValues &&
            !isDefined(candidateServices)
        ) {
            close();
            return;
        }

        const toIncludeUserId = (userExperience: UserExperience) => ({
            ...userExperience,
            userId
        });

        const hasValidDates = (
            experience: UserExperience
        ): experience is UpdateUserExperienceInput =>
            isDefined(experience.startDate) &&
            (isDefined(experience.endDate) || experience.endDate === null);

        const relevanceInformation = {
            userId,
            interestedInLeadershipPositions,
            preferredWorkLocations: selectedWorkLocations,
            linkedinProfileUrl: linkedinProfileUrl,
            preferredJobFamily: jobFamily,
            preferredSpecialties: jobFamilySpecialties,
            preferredCities: selectedCities?.map(city => city.id),
            spokenLanguages: selectedLanguages,
            userExperience: workExperience.map(toIncludeUserId).filter(hasValidDates)
        };

        const updateUserRelevanceInformationResponse = await updateUserRelevanceInformation({
            ...relevanceInformation
        });

        if (!updateUserRelevanceInformationResponse.data?.updateUserRelevanceInformation?.ok) {
            const errorMessage =
                updateUserRelevanceInformationResponse.data?.updateUserRelevanceInformation
                    ?.errorMessage;
            logger.error(errorMessage);
            return;
        }

        const giveConsentResponse = await giveConsent({
            consentType: ConsentType.PRIVACY_POLICY_CANDIDATE_SERVICES,
            version: candidateServices?.version ?? ''
        });
        if (!giveConsentResponse.data?.giveConsent?.ok) {
            const errorMessage = giveConsentResponse.data?.giveConsent?.errorMessage;
            logger.error(errorMessage);
            return;
        }

        setIsFormSubmittedSuccessfully(true);
        onSubmit();
    }, [
        editedExistingValues,
        isLinkedinProfileUrlValid,
        jobFamily,
        jobFamilySpecialties,
        languages,
        selectedLanguages,
        workLocations,
        selectedWorkLocations,
        isCitySelectionsValid,
        setFormValidationErrors,
        userId,
        userRelevanceInformation,
        interestedInLeadershipPositions,
        linkedinProfileUrl,
        selectedCities,
        updateUserRelevanceInformation,
        candidateServices,
        close,
        scrollToError,
        onSubmit,
        giveConsent,
        workExperience,
        isWorkExperienceValid
    ]);

    const handleCandidateRelevanceModalLeave = useCallback(
        closeParent => {
            const someFieldIsFilled =
                selectedWorkLocations.length !== 0 ||
                interestedInLeadershipPositions ||
                jobFamilySpecialties.length !== 0 ||
                selectedLanguages.length !== 0 ||
                selectedCities?.length !== 0;

            const firstTimeAndEditedValues = !userRelevanceInformation && someFieldIsFilled;

            if (firstTimeAndEditedValues || editedExistingValues) {
                setShowConfirmLeaveRelevanceModal(true);
            } else {
                closeParent();
            }
        },
        [
            selectedWorkLocations.length,
            interestedInLeadershipPositions,
            jobFamilySpecialties.length,
            selectedLanguages.length,
            selectedCities?.length,
            userRelevanceInformation,
            editedExistingValues
        ]
    );

    return {
        closeInitialProfileSubmitSucessModal,
        workLocations,
        selectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        interestedInLeadershipPositions,
        setInterestedInLeadershipPositions,
        jobFamily,
        jobFamilySpecialties,
        handleJobFamilySelection,
        handleJobFamilySpecialitySelection,
        handleSubmit,
        jobFamilies,
        selectableJobFamilySpecialities,
        companyTypes,
        languages,
        selectedLanguages,
        cities,
        selectedCities,
        handleLanguagesSelection,
        handleCitiesSelection,
        loading,
        formValidationErrors,
        getErrorText,
        isFormSubmittedSuccessfully,
        showConfirmLeaveRelevanceModal,
        setShowConfirmLeaveRelevanceModal,
        handleCandidateRelevanceModalLeave,
        citiesQueryResults,
        citiesQueryLoading,
        setCitiesSearchKey,
        linkedinProfileUrl,
        handleLinkedinProfileUrlChange,
        workExperience,
        setWorkExperience,
        invalidWorkExperienceIds,
        workExperienceHasFieldError,
        submitTimestamp
    };
}
