import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    ColorFgInfo,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  position: relative;

  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    padding: 0 ${SpacingMedium};
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: ${SpacingSmall} ${SpacingMedium};
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 950px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  gap: ${SpacingLarge};
`;

export const Title = styled.div`
  display: flex;
  gap: ${SpacingXxsmall};
  align-items: center;
  text-wrap: balance;
`;

export const Button = styled(PrimaryButton)`
  width: fit-content;
`;

export const CustomIcon = styled.div`
    width: 54px;
    height: 54px;
    fill: ${ColorFgInfo};
`;
