import {FormattedMessage, useIntl} from 'react-intl';

import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {Tag} from '@/componentLibrary/components/Tag';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {Spinner} from '@/componentLibrary/components/spinners';
import {P2} from '@/componentLibrary/components/typography/Paragraphs';
import {ErrorDisplay} from '@/pages/common/RelevanceForm/styled';

import {Separator} from '../../Separator';
import {S1} from '../../typography';
import {useSearchMultiSelectModalLogic} from './logic';
import messages from './messages';
import {
    BodyWrapper,
    CheckBoxWrapper,
    ItemWrapper,
    NoMatch,
    SearchInfoTextWrapper,
    SearchWrapper,
    SelectedTags,
    SpinnerWrapper,
    SubTitle,
    TagsWrapper
} from './styled';
import {Props} from './types';

export function SearchMultiSelectModal({
    selectedItems,
    items,
    addButtonText,
    editButtonText,
    searchInfoText,
    modalTitle,
    onChange,
    onSearchKeyChange,
    errorType,
    validationErrors,
    getErrorText,
    withQuery,
    queryResults = [],
    queryLoading,
    subTitle,
    buttonOnly = false
}: Props) {
    const {
        selectedOptions,
        finalSelectedOptions,
        showSelectorModal,
        setShowSelectorModal,
        onSelectorModalClose,
        onSelectorModalPrimaryAction,
        removeItem,
        handleChange,
        filterValue,
        isEmptyFilterValue,
        handleFilterChange,
        clearFilter,
        filteredItems,
        loading
    } = useSearchMultiSelectModalLogic(
        selectedItems,
        items,
        onChange,
        queryResults,
        onSearchKeyChange,
        withQuery,
        queryLoading
    );
    const intl = useIntl();

    let bodyPart;
    if (loading) {
        bodyPart = (
            <SpinnerWrapper>
                <Spinner $size="small" $isCentered />
            </SpinnerWrapper>
        );
    } else if (filteredItems?.length === 0) {
        bodyPart = <NoMatch>{intl.formatMessage(messages.noMatchesText)}</NoMatch>;
    } else {
        bodyPart = (
            <div>
                {filteredItems.map(item => (
                    <div key={item.id} onClick={() => handleChange(item)}>
                        <ItemWrapper>
                            <CheckBoxWrapper>
                                <CheckBox
                                    value={selectedOptions.some(
                                        selectedItem => selectedItem.id === item.id
                                    )}
                                />
                            </CheckBoxWrapper>
                            <P2>{item.name}</P2>
                        </ItemWrapper>
                    </div>
                ))}
                {isEmptyFilterValue && searchInfoText && (
                    <SearchInfoTextWrapper>{searchInfoText}</SearchInfoTextWrapper>
                )}
            </div>
        );
    }

    const footer = () => ({
        primaryAction: (
            <PrimaryButton onClick={onSelectorModalPrimaryAction}>
                <FormattedMessage {...messages.done} />
            </PrimaryButton>
        )
    });

    return (
        <div>
            {!buttonOnly && subTitle && (
                <SubTitle>
                    <S1>{subTitle}</S1>
                </SubTitle>
            )}
            {!buttonOnly && (
                <SelectedTags>
                    {finalSelectedOptions.map((item, index) => (
                        <Tag key={index}>{item.name}</Tag>
                    ))}
                </SelectedTags>
            )}
            {!buttonOnly && (
                <SecondaryButton onClick={() => setShowSelectorModal(true)}>
                    {finalSelectedOptions.length === 0 ? addButtonText : editButtonText}
                </SecondaryButton>
            )}
            {buttonOnly && (
                <PlainButton onClick={() => setShowSelectorModal(true)}>
                    {selectedOptions.length === 0 ? addButtonText : editButtonText}
                </PlainButton>
            )}

            <div>
                {validationErrors?.includes(errorType) ? (
                    <ErrorDisplay>{getErrorText?.(errorType)}</ErrorDisplay>
                ) : null}
            </div>
            {showSelectorModal && (
                <Modal
                    headerTitle={modalTitle}
                    close={onSelectorModalClose}
                    closeOnClickOutside={false}
                    footer={footer()}
                    isVerticallyCentered
                >
                    <BodyWrapper>
                        {buttonOnly && (
                            <>
                                <P2>{subTitle}</P2>
                                <br />
                            </>
                        )}
                        <SearchWrapper>
                            <TagsWrapper>
                                {selectedOptions.map((item, index) => (
                                    <Tag
                                        key={index}
                                        $clearable
                                        onClick={() => removeItem(index)}
                                    >
                                        {item.name}
                                    </Tag>
                                ))}
                            </TagsWrapper>
                            <TextField
                                type="text"
                                placeholder={intl.formatMessage(messages.searchText)}
                                $isClearable
                                $fullWidth
                                value={filterValue}
                                onClear={clearFilter}
                                onChange={handleFilterChange}
                                autoFocus
                            />
                        </SearchWrapper>
                        <br />
                        <Separator />
                        <br />
                        {bodyPart}
                    </BodyWrapper>
                </Modal>
            )}
        </div>
    );
}
