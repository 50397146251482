import {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useSetNewPassword} from '@/api/users/useSetNewPassword';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {usePasswordInputLogic} from '@/hooks/usePasswordInputLogic';
import messages from '@/pages/ResetPassword/components/ResetPasswordForm/messages';

const REQUIRED_PASSWORD_STRENGTH = 2;

const SUCCESS_REDIRECT = '/login';

export function useResetPasswordFormLogic() {
    const navigate = useNavigate();
    const phrases = useExtractPhraseConstants(messages);

    const [strength, setStrength] = useState(-1);
    const [password, setPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');

    const [doSetNewPassword, {loading}] = useSetNewPassword();

    const {handleChange, passwordInfo} = usePasswordInputLogic(
        e => setPassword(e.target.value),
        setStrength
    );

    const setNewPassword = useCallback(() => {
        doSetNewPassword(password)
            .then(({data}) => {
                const {ok} = data?.setNewPassword ?? {};

                if (!ok) {
                    toast({type: 'error', message: phrases.error});
                    return;
                }

                toast({type: 'success', message: phrases.success, removeAfterMs: 1500});
                window.setTimeout(() => {
                    navigate(SUCCESS_REDIRECT);
                }, 1500);
            })
            .catch(() => toast({type: 'error'}));
    }, [navigate, password, doSetNewPassword, phrases]);

    const handleSubmit = useCallback(
        e => {
            e.preventDefault();

            if (!password) {
                return;
            }

            setNewPassword();
        },
        [password, setNewPassword]
    );

    const handleConfirmPassword = useCallback(event => {
        const {value} = event.target;
        setConfirmedPassword(value);
    }, []);

    const hasRequiredStrength = useMemo(() => {
        return strength > REQUIRED_PASSWORD_STRENGTH;
    }, [strength]);

    const isPasswordValid = useMemo(() => {
        const isSet = password && confirmedPassword;
        const isConfirmed = password === confirmedPassword;
        return isSet && isConfirmed && hasRequiredStrength;
    }, [password, confirmedPassword, hasRequiredStrength]);

    const showFailedConfirmation = useMemo(() => {
        return hasRequiredStrength && password !== confirmedPassword;
    }, [hasRequiredStrength, password, confirmedPassword]);

    return {
        isPasswordValid,
        loading,
        handleChange,
        passwordInfo,
        handleSubmit,
        handleConfirmPassword,
        showFailedConfirmation,
        phrases
    };
}
