import {useEffect, useState} from 'react';

import {toast, useToasts} from '@/componentLibrary/components/Toast/toast';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {FormHeader} from '@/pages/LogicTestIrt/components/FormHeader';
import {Options} from '@/pages/LogicTestIrt/components/Options';
import {Question as OptionQuestionType} from '@/pages/LogicTestIrt/components/Options/types';
import {QuestionData} from '@/pages/LogicTestIrt/components/Question/types';
import {SelectAnswer} from '@/pages/LogicTestIrt/components/SelectAnswer';
import {Timer} from '@/pages/LogicTestIrt/components/Timer';

import {Question} from '../../components/Question';
import InstructionImage1 from './assets/InstructionImages/card1.png';
import InstructionImage2 from './assets/InstructionImages/card2.png';
import InstructionImage3 from './assets/InstructionImages/card3.png';
import {SampleTestTopBanner} from './components/SampleTestTopBanner';
import {TutorialCard} from './components/TutorialCard';
import messages from './messages';
import {SampleQuestion, firstExampleQuestion, secondExampleQuestion} from './sampleQuestions';
import {
    BodyWrapper,
    ButtonWrapper,
    HeaderWrapper,
    OptionsWrapper,
    QuestionWrapper,
    TimerWrapper,
    ToastButton,
    ToastContent,
    ToastText,
    TopWrapper,
    Wrapper
} from './styled';

type LogicTestTutorialProps = {
    goToStartLogicTest: () => void;
    timerLimit?: string;
    goBack: () => void;
};

export function LogicTestTutorial({
    goToStartLogicTest,
    timerLimit = 'PT2M',
    goBack
}: LogicTestTutorialProps): JSX.Element {
    const phrases = useExtractPhraseConstants(messages);
    const {onRemoveToast} = useToasts();
    const firstSampleTestToastId = 'firstSampleTestToast';
    const [showFirstTutorialCard, setShowFirstTutorialCard] = useState(true);
    const [showSecondTutorialCard, setShowSecondTutorialCard] = useState(false);
    const [showThirdTutorialCard, setShowThirdTutorialCard] = useState(false);
    const [showSampleTestStartCard, setShowSampleTestStartCard] = useState(false);
    const [cardsCompleted, setCardsCompleted] = useState(false);
    const [firstSampleCompleted, setFirstSampleCompleted] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [correctAnswerSubmitted, setCorrectAnswerSubmitted] = useState(false);
    const [timeIsUp, setTimeIsUp] = useState(false);

    useEffect(() => {
        return () => {
            onRemoveToast(firstSampleTestToastId);
        };
    }, [onRemoveToast]);

    const handleNextCard = (): void => {
        if (showFirstTutorialCard) {
            setShowFirstTutorialCard(false);
            setShowSecondTutorialCard(true);
        }
        if (showSecondTutorialCard) {
            setShowSecondTutorialCard(false);
            setShowThirdTutorialCard(true);
        }
        if (showThirdTutorialCard) {
            setShowThirdTutorialCard(false);
            setCardsCompleted(true);
        }
        if (showSampleTestStartCard) {
            setShowSampleTestStartCard(false);
            setCardsCompleted(true);
        }
    };

    const handlePreviousCard = (): void => {
        if (showSampleTestStartCard) {
            setShowSampleTestStartCard(false);
            setShowFirstTutorialCard(true);
        }
        if (showThirdTutorialCard) {
            setShowThirdTutorialCard(false);
            setShowSecondTutorialCard(true);
        }
        if (showSecondTutorialCard) {
            setShowSecondTutorialCard(false);
            setShowFirstTutorialCard(true);
        }
    };

    const handleSkipTutorial = (): void => {
        setShowSampleTestStartCard(true);
        setShowFirstTutorialCard(false);
        setShowSecondTutorialCard(false);
        setShowThirdTutorialCard(false);
    };

    const skipSampleTest = (): void => {
        goToStartLogicTest();
    };

    const onNewQuestionLoaded = (): void => {
        setSelectedOption(null);
        setTimeIsUp(false);
        setCorrectAnswerSubmitted(false);
    };

    const onTimeIsUp = (): void => {
        setTimeIsUp(true);
    };

    const selectOption = (optionValue: string): void => {
        setSelectedOption(optionValue);
    };

    const moveToNextQuestion = () => {
        setFirstSampleCompleted(true);
        onRemoveToast(firstSampleTestToastId);
        onNewQuestionLoaded();
    };

    const submitAnswer = () => {
        if (!firstSampleCompleted) {
            if (firstExampleQuestion.correctAnswer === selectedOption) {
                toast({
                    type: 'success',
                    persist: true,
                    hideCloseButton: true,
                    id: firstSampleTestToastId,
                    message: (
                        <ToastContent>
                            <ToastText>{phrases.correctAnswer}</ToastText>
                            <ToastButton>
                                <PrimaryButton onClick={moveToNextQuestion}>
                                    {phrases.nextQuestion}
                                </PrimaryButton>
                            </ToastButton>
                        </ToastContent>
                    )
                });
                setCorrectAnswerSubmitted(true);
            } else {
                showErrorToast();
            }
        } else if (secondExampleQuestion.correctAnswer === selectedOption) {
            toast({type: 'success', message: phrases.correctAnswer});
            setCorrectAnswerSubmitted(true);
            window.setTimeout(() => goToStartLogicTest(), 2000);
        } else {
            showErrorToast();
        }
    };

    const showErrorToast = () => toast({type: 'error', message: phrases.wrongAnswer});

    const renderQuestion = (sampleQuestion: SampleQuestion): JSX.Element => {
        return (
            <>
                <QuestionWrapper>
                    {timeIsUp ? (
                        <SelectAnswer isOptionSelected={!!selectedOption} />
                    ) : (
                        <Question question={sampleQuestion as QuestionData} isSvg />
                    )}
                </QuestionWrapper>

                <OptionsWrapper>
                    {cardsCompleted ? (
                        <TimerWrapper>
                            <Timer
                                key={sampleQuestion.id}
                                limit={timerLimit}
                                onTimeIsUp={onTimeIsUp}
                                disableExtraTime
                            />
                        </TimerWrapper>
                    ) : null}

                    <Options
                        key={sampleQuestion.id}
                        question={sampleQuestion as OptionQuestionType}
                        selectedOption={selectedOption}
                        selectOption={selectOption}
                        isSvg
                    />

                    <ButtonWrapper>
                        <PrimaryButton
                            onClick={submitAnswer}
                            disabled={!selectedOption || correctAnswerSubmitted}
                            isFullWidth
                        >
                            {phrases.submit}
                        </PrimaryButton>
                    </ButtonWrapper>
                </OptionsWrapper>
            </>
        );
    };

    const renderTutorialCards = (): JSX.Element | null => {
        if (showFirstTutorialCard) {
            return (
                <TutorialCard
                    title={phrases.firstCardTitle}
                    description={phrases.firstCardDescription}
                    onSkip={handleSkipTutorial}
                    onNext={handleNextCard}
                    showPrevious={false}
                    cardImage={InstructionImage1}
                    activeStep={1}
                />
            );
        }
        if (showSecondTutorialCard) {
            return (
                <TutorialCard
                    title={phrases.secondCardTitle}
                    description={phrases.secondCardDescription}
                    onSkip={handleSkipTutorial}
                    onPrevious={handlePreviousCard}
                    onNext={handleNextCard}
                    cardImage={InstructionImage2}
                    activeStep={2}
                />
            );
        }
        if (showThirdTutorialCard) {
            return (
                <TutorialCard
                    title={phrases.thirdCardTitle}
                    description={phrases.thirdCardDescription}
                    onSkip={skipSampleTest}
                    skipButtonText={phrases.startLogicTest}
                    onPrevious={handlePreviousCard}
                    nextButtonText={phrases.trySampleTest}
                    onNext={handleNextCard}
                    cardImage={InstructionImage3}
                    activeStep={3}
                />
            );
        }
        if (showSampleTestStartCard) {
            return (
                <TutorialCard
                    title={phrases.startSampleCardTitle}
                    description={phrases.startSampleCardDescription}
                    onSkip={skipSampleTest}
                    skipButtonText={phrases.startLogicTest}
                    previousButtonText={phrases.goBackToTutorial}
                    nextButtonText={phrases.trySampleTest}
                    onPrevious={handlePreviousCard}
                    onNext={handleNextCard}
                />
            );
        }
        return null;
    };

    return (
        <Wrapper>
            <TopWrapper>
                <SampleTestTopBanner onSkip={skipSampleTest} cardsCompleted={cardsCompleted} />
                <HeaderWrapper $cardsCompleted={cardsCompleted}>
                    <FormHeader
                        logicTestStatus={
                            firstSampleCompleted ? secondExampleQuestion : firstExampleQuestion
                        }
                        goBack={goBack}
                    />
                </HeaderWrapper>
            </TopWrapper>

            <BodyWrapper $cardsCompleted={cardsCompleted}>
                {renderQuestion(
                    firstSampleCompleted ? secondExampleQuestion : firstExampleQuestion
                )}
            </BodyWrapper>
            {renderTutorialCards()}
        </Wrapper>
    );
}
