import {defineMessages} from 'react-intl';

export default defineMessages({
    jobTitle: {id: 'app.pages.user.candidateRelevanceModal.jobTitle'},
    startDate: {id: 'app.pages.user.candidateRelevanceModal.workExperience.date.startDate'},
    endDate: {id: 'app.pages.user.candidateRelevanceModal.workExperience.date.endDate'},
    companyName: {id: 'app.pages.user.candidateRelevanceModal.workExperience.company'},
    description: {id: 'app.pages.user.candidateRelevanceModal.workExperience.description'},
    removeButtonText: {id: 'app.pages.user.candidateRelevanceModal.remove'},
    removeExperienceTitle: {
        id: 'app.jobRecommandations.workExperience.removeExperienceAlert.title'
    },
    removeExperienceDescription: {
        id: 'app.jobRecommandations.workExperience.removeExperienceAlert.description'
    },
    cancel: {id: 'app.general.cancel'}
});
