import styled from 'styled-components';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {
    BorderRadiusBaseXsmall,
    ColorFgActionTertriaryHover,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const CheckBoxWrapper = styled(FlexLayout)<{$hasLabel: boolean; $withMargin: boolean}>`
    cursor: pointer;
    width: ${SpacingXsmall};
    height: ${SpacingXsmall};
    position: relative;
    margin-top: ${({$hasLabel, $withMargin}) => ($hasLabel && $withMargin ? `7px` : 0)};
`;

export const CheckBoxInput = styled.input<{
    $isDisabled: boolean;
    $backgroundColor: string;
    $borderColor: string;
}>`
    appearance: none;
    width: ${SpacingXsmall};
    height: ${SpacingXsmall};
    background-color: ${({$backgroundColor}) => $backgroundColor};
    border: 1px solid ${({$borderColor}) => $borderColor};
    box-sizing: border-box;
    border-radius: ${BorderRadiusBaseXsmall};

    &:focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    &:focus-visible {
        outline: 0.25rem solid ${ColorFgActionTertriaryHover};
        outline-offset: 0.125rem;
    }

    cursor: ${({$isDisabled}) => ($isDisabled ? 'not-allowed' : 'pointer')};
    margin: 0 !important;
`;

export const IconWrapper = styled.div`
    position: absolute;
    pointer-events: none;
`;
