import LogRocket from 'logrocket';
import rg4js from 'raygun4js';

import {getConfig} from '@/config';
import {urlSanitizer} from '@/services/sanitizers';

import {browserTracingIntegration, init, replayIntegration} from '@sentry/react';
import {logger} from '.';
import {
    raygunPayloadSanitizer,
    requestSanitizer,
    responseSanitizer,
    sentryEventSanitizer
} from './sanitizers';
import {IOptions} from './types';

const config = getConfig();

const options: IOptions = {
    dom: {
        textSanitizer: true,
        inputSanitizer: true
    },
    shouldCaptureIP: false,
    console: {
        isEnabled: true,
        shouldAggregateConsoleErrors: true
    },
    browser: {
        urlSanitizer
    },
    network: {
        responseSanitizer,
        requestSanitizer
    }
};

if (!window.TestingMode) {
    LogRocket.init(config.LOG_ROCKET_KEY, options);
    logger.log('LogRocket initialized');

    rg4js('apiKey', config.RAYGUN_KEY);
    rg4js('enableCrashReporting', true);
    rg4js('enableRealUserMonitoring', true);
    rg4js('onBeforeSend', raygunPayloadSanitizer);
    logger.log('Raygun initialized');

    init({
        dsn: config.SENTRY.DSN,
        integrations: [browserTracingIntegration(), replayIntegration()],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/alvalabs\.io/],
        replaysSessionSampleRate: config.SENTRY.REPLAYS_RATE,
        replaysOnErrorSampleRate: config.SENTRY.REPLAYS_RATE_ERROR,
        beforeSend: sentryEventSanitizer
    });
} else {
    console.log('Playwright detected, skipping LogRocket');
}
