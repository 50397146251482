import {TextField} from '@/componentLibrary/components/inputs/TextField';

import {usePasswordInputLogic} from '../../../../../../../../../../hooks/usePasswordInputLogic';
import {HelpText, Progress} from './styled';
import {Props} from './types';
import {getProgressColor} from './utils';

export function PasswordInput(props: Props) {
    const {
        value,
        onChange,
        onPasswordStrengthChanged,
        hasError = false,
        label,
        placeholder
    } = props;

    const {handleChange, passwordInfo} = usePasswordInputLogic(
        onChange,
        onPasswordStrengthChanged
    );

    return (
        <div>
            <TextField
                hasError={hasError}
                type="password"
                name="password"
                autoComplete="off"
                placeholder={placeholder}
                $fullWidth
                value={value}
                label={label}
                onChange={handleChange}
            />
            <Progress
                $backgroundColor={getProgressColor(passwordInfo.strength)}
                value={passwordInfo.strength + 1}
                max={5}
            />
            <HelpText>{passwordInfo.feedback}</HelpText>
        </div>
    );
}
