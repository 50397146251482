import {useIntl} from 'react-intl';

import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Caption, P2, S1, S2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {capAndRoundStenValue} from '@/utils/misc';

import {LeadershipReportDimensionsValue} from '../../../constants';
import messages, {FACET_MESSAGES, FACET_MESSAGES_TYPE} from '../../../messages';
import {FactorResult, StyledDimensionRow} from '../styled';
import {getDimensionMessages} from '../utils';
import {DescriptionText, FacetWrapper, LabelsWrapper, SubHeader, TitleWrapper} from './styled';
import {DimensionRowProps, messagesKeyType} from './types';

export const DimensionRow = ({dimension: {name: dName, facets, label}}: DimensionRowProps) => {
    const {formatMessage} = useIntl();
    const facetPhrases = useExtractPhraseConstants(FACET_MESSAGES);
    const dimensionMessages = getDimensionMessages(dName as LeadershipReportDimensionsValue);
    type dimensionMessagesKeyType = keyof typeof dimensionMessages;

    const Content = () => (
        <FlexLayout $direction="column">
            <S2>{formatMessage(dimensionMessages.definitionTitle)}</S2>
            <P2>{formatMessage(dimensionMessages.definitionDescription)}</P2>
        </FlexLayout>
    );

    const facetDescription = (facets.map(({name, score}) => name + score).join('_') +
        '.description') as dimensionMessagesKeyType;
    const facetPerceivedByReports = (facets.map(({name, score}) => name + score).join('_') +
        '.perceivedByReports') as dimensionMessagesKeyType;

    return (
        <StyledDimensionRow>
            <div>
                <TitleWrapper>
                    <S1>{formatMessage(dimensionMessages.title)}</S1>
                    <Popover
                        openOnHover
                        content={<Content />}
                        trigger={<Icon icon={Icons.INFO} size={16} />}
                    />
                </TitleWrapper>
                <P2 color={ColorFgSubtle}>
                    {formatMessage(messages[label as messagesKeyType])}
                </P2>
                <DescriptionText>
                    {formatMessage(dimensionMessages[facetDescription])}
                </DescriptionText>
                <SubHeader>{formatMessage(messages.perceivedByOthers)}</SubHeader>
                <DescriptionText>
                    {formatMessage(dimensionMessages[facetPerceivedByReports])}
                </DescriptionText>
            </div>
            <FactorResult>
                <Caption $color={ColorFgSubtle}>
                    {formatMessage(messages.basedOnFacets)}
                </Caption>
                {facets.map(({name, value}, index) => (
                    <FacetWrapper key={index}>
                        <S2>{facetPhrases[`${name}.title` as FACET_MESSAGES_TYPE]}</S2>
                        <StandardTenChart
                            showBadge
                            selectedValue={capAndRoundStenValue(value)}
                        />
                        <LabelsWrapper>
                            <P2 color={ColorFgSubtle}>
                                {facetPhrases[`${name}.labelLower` as FACET_MESSAGES_TYPE]}
                            </P2>
                            <P2 color={ColorFgSubtle}>
                                {facetPhrases[`${name}.labelUpper` as FACET_MESSAGES_TYPE]}
                            </P2>
                        </LabelsWrapper>
                    </FacetWrapper>
                ))}
            </FactorResult>
        </StyledDimensionRow>
    );
};
