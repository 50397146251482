import qs from 'qs';
import {useCallback, useState} from 'react';

import {LogicTestIrtQuestion, LogicTestStatus} from '@/api/types/__generated__/graphql';
import {toast} from '@/componentLibrary/components/Toast/toast';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {H4} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {logicTestDataCollectionLinks} from '@/pages/LogicTestDataCollection/links';
import {LOGIC_TEST_IRT_LINKS} from '@/pages/LogicTestIrt/links';
import {LogicTestCompleted} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestCompleted';
import {LogicTestManagerProps} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTestManager/types';
import {LogicTesting} from '@/pages/LogicTestIrt/pages/LogicTest/components/LogicTesting';
import messages from '@/pages/LogicTestIrt/pages/LogicTest/messages';
import {logger} from '@/services/logrocket';
import {useNavigate} from 'react-router-dom';

export function LogicTestManager({
    location,
    loadingStatus,
    doSaveAnswer,
    savingAnswer,
    latestFetchedLogicTestStatus,
    goBack,
    doFetchNewStatus,
    isValidationTest
}: LogicTestManagerProps) {
    const navigate = useNavigate();
    const [logicTestStatus, setLogicTestStatus] = useState<LogicTestStatus | null>(
        latestFetchedLogicTestStatus
    );
    const [currentQuestion, setCurrentQuestion] = useState<
        LogicTestIrtQuestion | null | undefined
    >(latestFetchedLogicTestStatus?.nextQuestion);
    const phrases = useExtractPhraseConstants(messages);

    const getCurrentQuestion = useCallback(() => {
        if (logicTestStatus) {
            return currentQuestion;
        }

        return latestFetchedLogicTestStatus ? latestFetchedLogicTestStatus.nextQuestion : null;
    }, [currentQuestion, logicTestStatus, latestFetchedLogicTestStatus]);

    const saveAnswerWrapper = useCallback(
        (questionId: string, answer: string | null, requestNewQuestion?: boolean) => {
            if (savingAnswer) {
                return;
            }

            doSaveAnswer(questionId, answer, requestNewQuestion)
                .then(({data}) => {
                    const saveAnswerResult = data?.saveAnswerForLogicTestQuestion;
                    if (saveAnswerResult?.ok) {
                        const nextQuestion = saveAnswerResult.logicTestStatus?.nextQuestion;

                        setLogicTestStatus(
                            saveAnswerResult.logicTestStatus as LogicTestStatus
                        );
                        setCurrentQuestion(nextQuestion ? nextQuestion : getCurrentQuestion());
                    } else {
                        throw new Error(saveAnswerResult?.errorMessage ?? 'Unknown error');
                    }
                })
                .catch(() =>
                    toast({
                        type: 'error',
                        message: phrases.failedToSaveAnswer
                    })
                );
        },
        [doSaveAnswer, getCurrentQuestion, savingAnswer, phrases]
    );

    const fetchNewQuestion = useCallback(() => {
        doFetchNewStatus()
            .then(({data: {logicTestStatus: newLogicTestStatus}}) => {
                if (newLogicTestStatus) {
                    setLogicTestStatus(newLogicTestStatus as LogicTestStatus);
                    setCurrentQuestion(newLogicTestStatus.nextQuestion);
                }
            })
            .catch(logger.error);
    }, [doFetchNewStatus]);

    const goToTestResult = useCallback(
        logicTestId => {
            let url = LOGIC_TEST_IRT_LINKS.report(logicTestId);
            const params = qs.parse(location.search, {ignoreQueryPrefix: true});
            if (params.redirect) {
                url += `?redirect=${params.redirect}`;
            }
            if (params.organization_id) {
                url += `&organization_id=${params.organization_id}`;
            }
            navigate(url);
        },
        [location, navigate]
    );

    const goToDataCollectionTestCompleted = useCallback(
        logicTestId => {
            const url = logicTestDataCollectionLinks.completed(logicTestId);
            navigate(url);
        },
        [navigate]
    );

    if (!loadingStatus && !latestFetchedLogicTestStatus) {
        return (
            <FlexLayout
                $alignItems={'center'}
                $justifyContent={'center'}
                {...{style: {height: '100vh'}}}
            >
                <H4>
                    Not found, try refreshing the page or contact us if the problem persists.
                </H4>
            </FlexLayout>
        );
    }

    if (!loadingStatus && logicTestStatus?.isCompleted) {
        if (logicTestStatus.logicTest.isUsedInDataCollection) {
            goToDataCollectionTestCompleted(logicTestStatus.logicTest.id);
        }
        return (
            <LogicTestCompleted
                goToTestResult={goToTestResult}
                logicTestId={logicTestStatus.logicTest.id}
                goBack={
                    !loadingStatus && !logicTestStatus.logicTest.isUsedInDataCollection
                        ? goBack
                        : null
                }
                isValidationTest={isValidationTest}
            />
        );
    }

    return logicTestStatus ? (
        <LogicTesting
            loading={loadingStatus}
            logicTestStatus={logicTestStatus}
            currentQuestion={currentQuestion}
            saveAnswer={saveAnswerWrapper}
            savingAnswer={savingAnswer}
            fetchNewQuestion={fetchNewQuestion}
            goBack={
                !loadingStatus && !logicTestStatus?.logicTest?.isUsedInDataCollection
                    ? goBack
                    : null
            }
        />
    ) : null;
}
