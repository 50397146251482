import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ANIMATION_DURATION} from './constants';
import {Props} from './types';

export const useAccordionLogic = ({
    contentRef,
    defaultExpanded,
    expanded: externallyExpanded,
    onToggle
}: Props) => {
    const [internallyExpanded, setInternallyExpanded] = useState(defaultExpanded);
    const isControlled = isDefined(externallyExpanded);
    const expanded = isControlled ? externallyExpanded : internallyExpanded;

    const toggle = useCallback(() => {
        if (isControlled) {
            onToggle?.();
            return;
        }

        setInternallyExpanded(!internallyExpanded);
    }, [internallyExpanded, isControlled, onToggle]);

    useEffect(() => {
        const updateStyle = () => {
            if (!isDefined(contentRef?.current)) {
                return;
            }

            const maxHeight = expanded ? `${contentRef.current.scrollHeight}px` : '0px';
            contentRef.current.style.maxHeight = maxHeight;
            contentRef.current.style.overflow = 'hidden';
            contentRef.current.style.transition = `max-height ${ANIMATION_DURATION}s ease`;
        };

        updateStyle();
        window.addEventListener('resize', updateStyle);

        return () => window.removeEventListener('resize', updateStyle);
    }, [expanded, contentRef]);

    const iconStyle = useMemo(
        () => ({
            display: 'inline-block',
            transition: `transform ${ANIMATION_DURATION}s ease`,
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)'
        }),
        [expanded]
    );

    return {toggle, iconStyle};
};
