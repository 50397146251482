import {FormattedMessage, useIntl} from 'react-intl';

import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {Caption} from '@/componentLibrary/components/typography/Captions';
import {P1} from '@/componentLibrary/components/typography/Paragraphs';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useResizeListener} from '@/hooks/useResizeListener';
import {Section} from '@/pages/User/components/RecommendationRelevanceForm/components/Section';
import messages from '@/pages/User/components/RecommendationRelevanceForm/components/messages';
import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {Question} from '@/pages/common/RelevanceForm/components/Question';
import {ErrorDisplay} from '@/pages/common/RelevanceForm/styled';

import {
    CheckBoxGroupWrapper,
    CheckBoxWrapper,
    DropdownWithCheckboxesEmptyStateWrapper,
    DropdownWithCheckboxesWrapper
} from './styled';
import {Props} from './types';

export function PreferencesSection(props: Props) {
    const intl = useIntl();

    const {
        jobFamilies,
        formValidationErrors,
        getErrorText,
        jobFamily,
        handleJobFamilySelection,
        selectableJobFamilySpecialities,
        jobFamilySpecialties,
        handleJobFamilySpecialitySelection,
        interestedInLeadershipPositions,
        setInterestedInLeadershipPositions
    } = props;

    const {width} = useResizeListener();
    const isTabletOrPhone =
        width < Number(deviceBreakPointTokens.TABLET.max.replace(/px$/, ''));

    return (
        <Section title={intl.formatMessage(messages.sectionNextWork)}>
            <Question
                id={ERROR_TYPES.JOB_FAMILY}
                title={<FormattedMessage {...messages.jobFamilyTitle} />}
                content={
                    <div>
                        <DropdownWithCheckboxesWrapper>
                            <Dropdown
                                $fullWidth={isTabletOrPhone}
                                items={jobFamilies}
                                selectedItem={jobFamilies.find(jf => jf.id === jobFamily)}
                                onSelect={handleJobFamilySelection}
                                placeholder={intl.formatMessage(messages.jobFamilyPlaceholder)}
                                label={intl.formatMessage(messages.jobFamilyLabel)}
                            />
                            {jobFamily ? (
                                <CheckBoxGroupWrapper
                                    $count={selectableJobFamilySpecialities.length}
                                >
                                    {selectableJobFamilySpecialities.map((v, i) => (
                                        <CheckBoxWrapper key={i}>
                                            <CheckBox
                                                checkboxId={v.id}
                                                value={jobFamilySpecialties.includes(v.id)}
                                                onChange={handleJobFamilySpecialitySelection.bind(
                                                    null,
                                                    v.id
                                                )}
                                                label={<P1>{v.name}</P1>}
                                            />
                                        </CheckBoxWrapper>
                                    ))}
                                </CheckBoxGroupWrapper>
                            ) : (
                                <DropdownWithCheckboxesEmptyStateWrapper>
                                    <Caption $color={ColorBaseGrey700}>
                                        <FormattedMessage
                                            {...messages.jobFamilyEmptyStateTitle}
                                        />
                                    </Caption>
                                </DropdownWithCheckboxesEmptyStateWrapper>
                            )}
                        </DropdownWithCheckboxesWrapper>
                        {formValidationErrors.includes(ERROR_TYPES.JOB_FAMILY) ? (
                            <ErrorDisplay>{getErrorText(ERROR_TYPES.JOB_FAMILY)}</ErrorDisplay>
                        ) : null}
                    </div>
                }
            />
            <Question
                title={<FormattedMessage {...messages.leadershipPositionsTitle} />}
                subtitle={<FormattedMessage {...messages.leadershipPositionsSubtitle} />}
                content={
                    <CheckBoxWrapper>
                        <CheckBox
                            value={interestedInLeadershipPositions}
                            onChange={setInterestedInLeadershipPositions}
                            label={
                                <P1>
                                    <FormattedMessage
                                        {...messages.leadershipPositionsInterested}
                                    />
                                </P1>
                            }
                        />
                    </CheckBoxWrapper>
                }
            />
        </Section>
    );
}
