import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {LogicTestTutorial} from '@/pages/LogicTestIrt/pages/LogicTestTutorial';
import {useEffect, useState} from 'react';
import {Navigate} from 'react-router-dom';

import {useValidateLogicTestValidationToken} from '@/api/logicTestIrt/useValidateLogicTestValidationToken';
import {ValidateLogicTestValidationTokenMutation} from '@/api/types/__generated__/graphql';
import {Wrapper} from '@/pages/LogicTestValidation/pages/LogicTestValidation/styled';
import {LogicTestValidationProps} from '@/pages/LogicTestValidation/pages/LogicTestValidation/types';
import {setUserAuthToken} from '@/services/auth/utils';
import {LOGIC_TEST_VALIDATION_LINKS} from '../../links';
import {ContinueValidationTest} from './components/ContinueValidationTest';
import {LogicTestValidationError} from './components/LogicTestValidationError';
import {StartValidationTestPage} from './components/StartValidationTestPage';

export const LogicTestValidation = ({token, goToValidationTest}: LogicTestValidationProps) => {
    const [tutorialCompleted, setTutorialCompleted] = useState(false);
    const [doValidateToken, {loading, error}] = useValidateLogicTestValidationToken(token);
    const [data, setData] = useState<
        ValidateLogicTestValidationTokenMutation | null | undefined
    >(null);

    useEffect(() => {
        doValidateToken()
            .then(({data}) => {
                setData(data);
                const {authToken} = data?.validateLogicTestValidationToken || {};

                if (authToken) {
                    setUserAuthToken(authToken);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }, [doValidateToken]);

    const onTutorialCompleted = () => {
        setTutorialCompleted(true);
    };

    if (tutorialCompleted) {
        return (
            <StartValidationTestPage token={token} goToValidationTest={goToValidationTest} />
        );
    }

    if (loading) {
        return <CenteredSpinner />;
    }

    if (error) {
        return <LogicTestValidationError error={error.message} />;
    }

    if (data) {
        const {ok, errorMessage, validationTestId, validationTestCompletedAt} =
            data?.validateLogicTestValidationToken || {};

        if (!ok) {
            return <LogicTestValidationError error={errorMessage ?? 'Unknown error'} />;
        }

        if (validationTestCompletedAt) {
            const testUrl = LOGIC_TEST_VALIDATION_LINKS.test(validationTestId as string);
            return <Navigate to={testUrl} />;
        }

        if (validationTestId) {
            return (
                <ContinueValidationTest
                    goToTest={goToValidationTest.bind(null, validationTestId)}
                />
            );
        }
    }

    return (
        <Wrapper>
            <LogicTestTutorial goToStartLogicTest={onTutorialCompleted} goBack={() => null} />
        </Wrapper>
    );
};
