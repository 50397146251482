import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseMedium,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled, {css} from 'styled-components';
import {HasRegularActionProp, OuterWrapperProps} from './types';

export const BannerWrapper = styled.div<OuterWrapperProps>`
    background-color: ${({$bgColor}) => $bgColor};
    border-radius: ${BorderRadiusBaseMedium};
    border: 1px solid ${({$borderColor}) => $borderColor};
    ${({$noFullwidth}) => ($noFullwidth ? 'display: inline;' : 'width: 100%;')}
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingSmall};
    padding: 12px;
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        gap: ${SpacingXxsmall};
    }
`;

export const ContentActionWrapper = styled.div<HasRegularActionProp>`
    width: 100%;
    display: flex;
    align-items: center;
    ${({$hasRegularAction}) => $hasRegularAction && css`gap: ${SpacingLarge};`}

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        gap: ${SpacingXxsmall};
        ${({$hasRegularAction}) => $hasRegularAction && 'flex-direction: column;'}
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const ActionWrapper = styled.div<HasRegularActionProp>`
    height: 100%;
    display: flex;
    gap: ${SpacingMedium};
    align-items: center;

    ${({$hasRegularAction}) =>
        $hasRegularAction &&
        css`
            @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
                padding: 0;
                width: 100%;

                button {
                    padding: ${SpacingXxxsmall};
                    display: flex;
                    width: 100%;
                }
            }
        `}
`;
