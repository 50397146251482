import {useCallback, useEffect, useState} from 'react';

import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {RadioButton} from '@/componentLibrary/components/controls/RadioButton';
import {userDemographicsMessages} from '@/pages/UserDemographics/messages';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {SelectBirthYearWrapper} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectBirthYear/styled';
import {
    HandleSelectBirthYearProps,
    SelectBirthYearProps
} from '@/pages/UserDemographics/components/UserDemographicsForm/components/SelectBirthYear/types';
import {InputField, InputText} from '../styled';
import {generateYearOptions} from './utils';

export function SelectBirthYear({birthYear, setBirthYear}: SelectBirthYearProps) {
    const phrases = useExtractPhraseConstants(userDemographicsMessages);
    return (
        <InputField>
            <InputText>{phrases.birthDate}</InputText>
            <HandleSelectBirthYear birthYear={birthYear} setBirthYear={setBirthYear} />
        </InputField>
    );
}

function HandleSelectBirthYear({birthYear, setBirthYear}: HandleSelectBirthYearProps) {
    const [birthYearRatherNotSay, setBirthYearRatherNotSay] = useState(false);
    const phrases = useExtractPhraseConstants(userDemographicsMessages);

    useEffect(() => {
        if (birthYearRatherNotSay) {
            setBirthYear(null);
        }
    }, [setBirthYear, birthYearRatherNotSay]);

    useEffect(() => {
        if (birthYear && birthYearRatherNotSay) {
            setBirthYearRatherNotSay(false);
        }
    }, [birthYear, birthYearRatherNotSay]);

    const convertItemToYear = useCallback(item => {
        return item ? item.name : null;
    }, []);

    const handleSelect = useCallback(
        item => {
            const year = item ? Number(convertItemToYear(item)) : null;
            setBirthYear(year);
        },
        [setBirthYear, convertItemToYear]
    );

    return (
        <>
            <SelectBirthYearWrapper>
                <Dropdown
                    items={generateYearOptions()}
                    onSelect={handleSelect}
                    selectedItem={birthYear ? {name: birthYear.toString()} : null}
                    itemToId={convertItemToYear}
                    $isClearable
                    noFocusTrap
                />
            </SelectBirthYearWrapper>
            <RadioButton
                value={'RATHER_NOT_SAY'}
                isSelected={birthYearRatherNotSay}
                onSelect={setBirthYearRatherNotSay.bind(null, true)}
                label={phrases.ratherNotSay}
            />
        </>
    );
}
