import LogRocket from 'logrocket';
import {useCallback, useEffect} from 'react';

import {AuthenticatedUser} from '@/api/types/__generated__/graphql';
import {useAuthenticatedUser} from '@/api/users/useAuthenticatedUser';
import {getConfig} from '@/config';
import {isDefined} from '@/utils/typeGuards/isDefined';

import rg4js from 'raygun4js';
import {REDACT_KEYS} from './constants';
import {createRedactor} from './utils';

const getLogger = () => {
    const config = getConfig();

    if (config.ENVIRONMENT === 'local') {
        return console;
    }
    rg4js('setFilterScope', 'all');
    rg4js('filterSensitiveData', REDACT_KEYS);
    return createRedactor(LogRocket, {redactKeys: REDACT_KEYS});
};

export const logger = getLogger();

export function LogRocketWrapper() {
    const {user} = useAuthenticatedUser();

    const identifyLogRocket = useCallback(() => {
        const userId = (user as AuthenticatedUser).userId.toString();
        LogRocket.identify(userId, {name: `User ${userId}`});
        logger.log('LogRocket User identified');
    }, [user]);

    const identifyRaygun = useCallback(() => {
        const userId = (user as AuthenticatedUser).userId.toString();
        rg4js('setUser', {
            identifier: userId, // A unique ID, email address, or another ID such as a UUID
            isAnonymous: false, // Indicates whether the user is anonymous or not
            firstName: `User ${userId}` // Optional
        });
        logger.log('Raygun User identified');
    }, [user]);

    useEffect(() => {
        if (isDefined(user)) {
            identifyLogRocket();
            identifyRaygun();
        }
    }, [user, identifyLogRocket, identifyRaygun]);

    return null;
}
