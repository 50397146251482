export enum FormattingOptions {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    CODE = 'CODE',
    CODE_BLOCK = 'CODE_BLOCK',
    BULLET = 'BULLET',
    NUMBER = 'NUMBER',
    LINK = 'LINK',
    INDENT_DECREASE = 'INDENT_DECREASE',
    INDENT_INCREASE = 'INDENT_INCREASE'
}
