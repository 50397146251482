import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from '@/pages/ReadinessDataCollection/components/FindMoreJobOpportunities/messages';
import {useUserIsEligibleForLinkedinDataCollection} from '@/pages/ReadinessDataCollection/hooks/useUserIsEligibleForLinkedinDataCollection';
import {useState} from 'react';
import {ErrorSyncStatus, OnErrorProps} from './types';

export const useFindMoreJobOpportunitiesLogic = () => {
    const phrases = useExtractPhraseConstants(messages);
    const {errorTitle, errorDescription, errorStatus, onError} = useOnError();
    const {
        loading: loadingIsEligibleForEuDataCollection,
        isEligible: isEligibleForEuDataCollection,
        error: isEligibleForEuDataCollectionError,
        reload: reloadIsEligibleForEuDataCollection
    } = useUserIsEligibleForLinkedinDataCollection();

    return {
        phrases,
        loadingIsEligibleForEuDataCollection,
        isEligibleForEuDataCollection,
        isEligibleForEuDataCollectionError,
        reloadIsEligibleForEuDataCollection,
        errorTitle,
        errorDescription,
        errorStatus,
        onError
    };
};

const useOnError = () => {
    const [errorTitle, setErrorTitle] = useState<string | undefined | null>(undefined);
    const [errorDescription, setErrorDescription] = useState<string | undefined>(undefined);
    const [errorStatus, setErrorStatus] = useState<ErrorSyncStatus | undefined>(undefined);

    const onError = ({description, title, errorStatus}: OnErrorProps) => {
        setErrorTitle(title);
        setErrorDescription(description);
        setErrorStatus(errorStatus);
    };

    return {
        errorTitle,
        errorDescription,
        errorStatus,
        onError
    };
};
