import {BannerType} from '../constants';
import {COLOR_MAP} from './constants';

export const getBannerBgColor = (type?: BannerType) => {
    return type ? COLOR_MAP[type].background : COLOR_MAP[BannerType.INFO].background;
};

export const getProminentBannerColor = (type?: BannerType) => {
    return type ? COLOR_MAP[type].bannerColor : COLOR_MAP[BannerType.INFO].bannerColor;
};
