import styled from 'styled-components';

import {
    ColorBaseGrey700,
    ColorBaseGrey900,
    FontSizeDefault,
    FontSizeLarge,
    FontSizeLarger,
    FontSizeMedium,
    FontSizeSmall
} from '@/componentLibrary/tokens/variables';

type Props = {
    $sub?: boolean;
    $withMargin?: boolean;
};

const Display = styled.h1<Props>`
    white-space: pre-line;
    font-weight: normal;
    margin: 0;
    color: ${({$sub}) => ($sub ? ColorBaseGrey700 : ColorBaseGrey900)};
    hyphens: auto;
    word-break: break-word;
    ${({$withMargin}) => $withMargin && 'margin-bottom: 16px;'}
`;

export const DisplayMedium = styled(Display)`
    font-size: ${FontSizeLarger};

    @media (max-width: 768px) {
        font-size: ${FontSizeLarge};
    }
`;

export const DisplaySmall = styled(Display)`
    font-size: ${FontSizeLarge};

    @media (max-width: 768px) {
        font-size: ${FontSizeMedium};
    }
`;

export const DisplaySmaller = styled(Display)`
    font-size: ${FontSizeMedium};

    @media (max-width: 768px) {
        font-size: ${FontSizeDefault};
    }
`;

export const DisplaySmallest = styled(Display)`
    font-size: ${FontSizeDefault};

    @media (max-width: 768px) {
        font-size: ${FontSizeSmall};
    }
`;
