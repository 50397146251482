import styled from 'styled-components';

import {
    ColorBaseGrey200,
    SpacingSmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {IsHorizontalProps, WrapperProps} from './types';

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    height: ${({$isHorizontal}) => ($isHorizontal ? 'auto' : '100%')};
    width: ${({$isHorizontal}) => (!$isHorizontal ? 'auto' : '100%')};
    flex-direction: ${({$isHorizontal}) => ($isHorizontal ? 'row' : 'column')};
    align-items: center;
    text-align: center;
    ${p => (p.$padded ? `padding-${p.$isHorizontal ? 'block' : 'inline'}: ${p.$padding};` : '')}
`;

export const Bar = styled.div<IsHorizontalProps>`
    height: ${({$isHorizontal}) => ($isHorizontal ? '1px' : '100%')};
    width: ${({$isHorizontal}) => (!$isHorizontal ? '1px' : '100%')};
    background-color: ${ColorBaseGrey200};
`;

export const ContentWrapper = styled.div<IsHorizontalProps>`
    margin: ${p => (p.$isHorizontal ? `0 ${SpacingSmall}` : `${SpacingXxsmall} 0`)};
    white-space: nowrap;
`;
