import {PercentileText} from '@/componentLibrary/blocks/testReports/components/PercentileText';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {PercentileWrapper, PercentileWrapperSmall} from './styled';

type PersonalityPercentileProps = {
    scoreValue: number;
    isSmall?: boolean;
};

export function PersonalityPercentile({scoreValue, isSmall}: PersonalityPercentileProps) {
    if (isSmall) {
        return (
            <PercentileWrapperSmall $color={ColorFgSubtle}>
                <PercentileText standardScore={scoreValue} />
            </PercentileWrapperSmall>
        );
    }

    return (
        <PercentileWrapper color={ColorFgSubtle}>
            <PercentileText standardScore={scoreValue} />
        </PercentileWrapper>
    );
}
