import {SpacingSmall, SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';
import {Content} from '../../Tooltips/Tooltip/components/styled';

export const Wrapper = styled.div`
    position: absolute;
    top: ${SpacingXxsmall};
    right: ${SpacingXxsmall};
`;
export const ExtendedContent = styled(Content)<{$noPadding: boolean}>`
    height: auto;
    max-height: fit-content;
    white-space: normal;
    border-radius: ${SpacingXxsmall};
    padding: ${({$noPadding}) => ($noPadding ? 0 : SpacingSmall)};
`;
