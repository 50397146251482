import {ElementType, forwardRef} from 'react';
import styled, {css} from 'styled-components';

import {SubtitleS2} from '@/componentLibrary/tokens/typography';
import {
    BorderRadiusBaseRounded,
    BorderWidthBase,
    ColorBaseGrey600,
    ColorBaseGrey900,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {Link, NavLink} from 'react-router-dom';
import {Icon} from '../icons/Icon';
import {Icons} from '../icons/constants';
import {BUTTON_VARIANTS} from './constants';
import {
    AnchorButtonProps,
    ButtonBaseProps,
    ButtonProps,
    ButtonShellProps,
    LinkButtonProps,
    NavLinkButtonProps
} from './types';

export const getIcon = (icon?: Icons, size = 16) =>
    !!icon && <Icon icon={icon} size={size} fill="currentColor" as="span" />;

const ButtonBaseLoadingStyle = css`
    pointer-events: none;
    color: transparent !important;

    &::after {
        animation: spin-around 0.5s infinite linear;
        border: 2px solid ${ColorBaseGrey600};
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 1em;
        width: 1em;
        left: calc(50% - (1em / 2));
        top: calc(50% - (1em / 2));
        position: absolute !important;
    }
`;

export const ButtonShellStyling = css<ButtonShellProps>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${SubtitleS2};
    border: ${BorderWidthBase} solid transparent; /* Needs to be here, to make all buttons the same size */
    border-radius: ${BorderRadiusBaseRounded};
    white-space: nowrap;
    gap: ${SpacingXxsmall};
    padding: ${({small: $small}) => ($small ? SpacingXxxsmall : SpacingXsmall)} ${SpacingSmall};
    max-height: 46px;
`;

const ButtonBaseStyling = css<ButtonBaseProps>`
    color: ${ColorBaseGrey900};
    position: relative;
    width: ${props => (props.isFullWidth ? '100%' : 'auto')};

    ${({minWidth: $minWidth}) => ($minWidth ? `min-width: ${$minWidth}px;` : '')}

    ${({isLoading: $isLoading}) => ($isLoading ? ButtonBaseLoadingStyle : '')}

    &:disabled,
    &:disabled:hover {
        cursor: not-allowed;
    }
`;

export const StyledBaseButton = styled.button<ButtonProps>`
    ${ButtonShellStyling}
    ${ButtonBaseStyling}
`;

export const StyledBaseNavLink = styled(NavLink)<NavLinkButtonProps>`
    ${ButtonShellStyling}
    ${ButtonBaseStyling}
`;

export const StyledBaseLink = styled(Link)<LinkButtonProps>`
    ${ButtonShellStyling}
    ${ButtonBaseStyling}
`;

export const StyledBaseAnchor = styled.a<AnchorButtonProps>`
    ${ButtonShellStyling}
    ${ButtonBaseStyling}
`;

export const ButtonBase = forwardRef<ElementType, ButtonBaseProps>(
    ({children, icon, iconSize, type = 'button', variant, ...rest}, ref) => {
        let Component: ElementType;
        switch (variant) {
            case BUTTON_VARIANTS.NAVLINK:
                Component = StyledBaseNavLink;
                break;
            case BUTTON_VARIANTS.LINK:
                Component = StyledBaseLink;
                break;
            case BUTTON_VARIANTS.ANCHOR:
                Component = StyledBaseAnchor;
                break;
            default:
                Component = StyledBaseButton;
        }

        return (
            <Component {...rest} type={type} ref={ref}>
                {getIcon(icon, iconSize)}
                {children}
            </Component>
        );
    }
);
