import {TopBanner} from '@/componentLibrary/components/banners/TopBanner';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {useState} from 'react';
import {useIntl} from 'react-intl';

import messages from './messages';
import {hasSeenPrompt, isUnsupported, markPromptAsSeen} from './utils';

export const IncompatibleBrowserBanner = () => {
    const {formatMessage} = useIntl();
    const [visible, setVisible] = useState(() => isUnsupported() && !hasSeenPrompt());

    const closePrompt = () => {
        setVisible(false);
        markPromptAsSeen();
    };

    const browserLinks = () => ({
        chromeLink: (
            <a rel="noreferrer noopener" target="_blank" href="https://chrome.google.com">
                Chrome
            </a>
        )
    });

    return (
        <TopBanner visible={visible}>
            <FlexLayout $justifyContent="space-between" $alignItems="center">
                {formatMessage(messages.incompatibleBrowser, browserLinks())}
                <Icon icon={Icons.CLOSE} isAction onClick={closePrompt} />
            </FlexLayout>
        </TopBanner>
    );
};
