import {BorderActionSecondaryActive} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseSmall,
    SpacingXlarge,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingXsmall};
    padding: ${SpacingXlarge} 0;
    align-items: center;
    border-radius: ${BorderRadiusBaseSmall};
    border: ${BorderActionSecondaryActive};
`;
