import {defineMessages} from 'react-intl';

export default defineMessages({
    addInformation: {
        id: 'app.readinessDataCollectionCTA.action.addInformation'
    },
    maybeLater: {
        id: 'app.general.maybeLater'
    }
});
