import qs from 'qs';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {urlRelative} from '@/utils/url';
import {LOGIC_TEST_VALIDATION_BASE_URL, LOGIC_TEST_VALIDATION_LINKS} from './links';
import {LogicTestValidation} from './pages/LogicTestValidation';
import {LogicTestWrapper} from './pages/LogicTestValidation/components/LogicTestWrapper';

export const LogicTestValidationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const goToValidationTest = (testId: string) => {
        const url = LOGIC_TEST_VALIDATION_LINKS.test(testId);
        navigate(url);
    };

    const queryParams: {t?: string} = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const token = queryParams['t'] ?? null;

    return (
        <Routes>
            <Route
                path={urlRelative(
                    LOGIC_TEST_VALIDATION_LINKS.start(),
                    LOGIC_TEST_VALIDATION_BASE_URL
                )}
                element={
                    <ErrorBoundary>
                        {token ? (
                            <LogicTestValidation
                                token={token}
                                goToValidationTest={goToValidationTest}
                            />
                        ) : null}
                    </ErrorBoundary>
                }
            />
            <Route
                path={urlRelative(
                    LOGIC_TEST_VALIDATION_LINKS.test(':logicTestId'),
                    LOGIC_TEST_VALIDATION_BASE_URL
                )}
                element={
                    <ErrorBoundary>
                        <LogicTestWrapper />
                    </ErrorBoundary>
                }
            />
            <Route path="*" element={<Navigate to={LOGIC_TEST_VALIDATION_LINKS.start()} />} />
        </Routes>
    );
};
