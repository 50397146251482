import {
    DECISION_MAKING_MESSAGES,
    DEFINING_THE_DIRECTION_MESSAGES,
    DRIVING_TOWARDS_RESULTS_MESSAGES,
    GETTING_OTHERS_ONBOARD_MESSAGES,
    HELPING_OTHERS_GROW_MESSAGES,
    INNER_COMPASS_MESSAGES,
    MANAGING_CHANGE_MESSAGES,
    RELATIONSHIP_BUILDING_MESSAGES,
    RESILIENCE_MESSAGES
} from './messages';

export const LEADERSHIP_REPORT_SECTIONS = {
    ACHIEVING_RESULTS: 'ACHIEVING_RESULTS',
    INFLUENCING_OTHERS: 'INFLUENCING_OTHERS',
    NAVIGATING: 'NAVIGATING'
} as const;

export type LeadershipReportSectionValue =
    (typeof LEADERSHIP_REPORT_SECTIONS)[keyof typeof LEADERSHIP_REPORT_SECTIONS];

export const LEADERSHIP_REPORT_SECTION_VALUES: LeadershipReportSectionValue[] = Object.values(
    LEADERSHIP_REPORT_SECTIONS
);

const LEADERSHIP_REPORT_DIMENSIONS = {
    DECISION_MAKING: 'DECISION_MAKING',
    DEFINING_THE_DIRECTION: 'DEFINING_THE_DIRECTION',
    DRIVING_TOWARDS_RESULTS: 'DRIVING_TOWARDS_RESULTS',
    GETTING_OTHERS_ONBOARD: 'GETTING_OTHERS_ONBOARD',
    HELPING_OTHERS_GROW: 'HELPING_OTHERS_GROW',
    INNER_COMPASS: 'INNER_COMPASS',
    MANAGING_CHANGE: 'MANAGING_CHANGE',
    RELATIONSHIP_BUILDING: 'RELATIONSHIP_BUILDING',
    RESILIENCE: 'RESILIENCE'
} as const;

export type LeadershipReportDimensionsValue = keyof typeof LEADERSHIP_REPORT_DIMENSIONS;

type MessageStructure = {
    definitionDescription: {id: string};
    definitionTitle: {id: string};
    title: {id: string};
};

// Messages for each personality factor key should be typed as well
type LeadershipReportDimensionsMessages = {
    [key in LeadershipReportDimensionsValue]: MessageStructure; // assuming each factor has an array of strings as messages, modify as needed
};

export const LEADERSHIP_REPORT_DIMENSIONS_MESSAGES: LeadershipReportDimensionsMessages = {
    DECISION_MAKING: DECISION_MAKING_MESSAGES,
    DEFINING_THE_DIRECTION: DEFINING_THE_DIRECTION_MESSAGES,
    DRIVING_TOWARDS_RESULTS: DRIVING_TOWARDS_RESULTS_MESSAGES,
    GETTING_OTHERS_ONBOARD: GETTING_OTHERS_ONBOARD_MESSAGES,
    HELPING_OTHERS_GROW: HELPING_OTHERS_GROW_MESSAGES,
    INNER_COMPASS: INNER_COMPASS_MESSAGES,
    MANAGING_CHANGE: MANAGING_CHANGE_MESSAGES,
    RELATIONSHIP_BUILDING: RELATIONSHIP_BUILDING_MESSAGES,
    RESILIENCE: RESILIENCE_MESSAGES
};

export const LEADERSHIP_DEVELOPMENT_PLAN_URL = {
    EN: 'https://storage.googleapis.com/alva-assessment.appspot.com/Leadership%20development%20plan.pdf',
    SV: 'https://storage.googleapis.com/alva-assessment.appspot.com/Ledarutvecklingsplan.pdf'
};
