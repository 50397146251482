import {useIntl} from 'react-intl';

import {SearchMultiSelectModal} from '@/componentLibrary/components/inputs/SearchMultiSelectModal/SearchMultiSelectModal';
import {Item} from '@/componentLibrary/components/inputs/SearchMultiSelectModal/types';
import {Section} from '@/pages/User/components/RecommendationRelevanceForm/components/Section';
import messages from '@/pages/User/components/RecommendationRelevanceForm/components/messages';
import {ERROR_TYPES} from '@/pages/User/components/RecommendationRelevanceForm/constants';
import {CheckBoxGroupQuestion} from '@/pages/common/RelevanceForm/components/CheckBoxGroupQuestion';
import {Props} from './types';

export function InformationSection(props: Props) {
    const intl = useIntl();
    const {
        languages,
        selectedLanguages,
        handleLanguagesSelection,
        cities,
        selectedCities,
        handleCitiesSelection,
        workLocations,
        selectedWorkLocations,
        handleWorkLocationSelection,
        showCitiesSelection,
        formValidationErrors,
        getErrorText,
        citiesQueryResults,
        citiesQueryLoading,
        setCitiesSearchKey
    } = props;

    const WorkLocationQuestion = CheckBoxGroupQuestion;
    const LanguagesQuestion = SearchMultiSelectModal;
    const CitiesQuestion = SearchMultiSelectModal;

    return (
        <Section title={intl.formatMessage(messages.sectionJobPreferences)}>
            <LanguagesQuestion
                subTitle={intl.formatMessage(messages.languagesTitle)}
                editButtonText={intl.formatMessage(messages.editButtonText)}
                addButtonText={intl.formatMessage(messages.addButtonText)}
                modalTitle={intl.formatMessage(messages.modalTitle)}
                searchInfoText={intl.formatMessage(messages.searchText)}
                items={languages.map(language => ({
                    name: language.name,
                    id: language.id
                }))}
                onChange={handleLanguagesSelection}
                selectedItems={languages
                    .filter(language => selectedLanguages.includes(language.id))
                    .map(language => ({
                        name: language.name,
                        id: language.id
                    }))}
                errorType={ERROR_TYPES.LANGUAGES}
                validationErrors={formValidationErrors}
                getErrorText={getErrorText}
            />
            <WorkLocationQuestion
                title={intl.formatMessage(messages.workLocationsTitle)}
                subTitle={intl.formatMessage(messages.workLocationsSubTitle)}
                options={workLocations}
                selectedOptionIds={selectedWorkLocations}
                onChange={handleWorkLocationSelection}
                errorType={ERROR_TYPES.WORK_LOCATIONS}
                formValidationErrors={formValidationErrors}
                getErrorText={getErrorText}
            />
            {showCitiesSelection && (
                <div id={ERROR_TYPES.CITIES}>
                    <CitiesQuestion
                        subTitle={intl.formatMessage(messages.citiesTitle)}
                        editButtonText={intl.formatMessage(messages.editCitiesButtonText)}
                        addButtonText={intl.formatMessage(messages.addCitiesButtonText)}
                        modalTitle={intl.formatMessage(messages.citiesModalTitle)}
                        searchInfoText={intl.formatMessage(messages.searchCitiesInfoText)}
                        items={cities}
                        onChange={handleCitiesSelection as (newCities: Item[]) => void}
                        selectedItems={selectedCities}
                        errorType={ERROR_TYPES.CITIES}
                        validationErrors={formValidationErrors}
                        getErrorText={getErrorText}
                        withQuery
                        onSearchKeyChange={setCitiesSearchKey}
                        queryResults={citiesQueryResults}
                        queryLoading={citiesQueryLoading}
                    />
                </div>
            )}
        </Section>
    );
}
