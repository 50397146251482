import {ColorFgSubtle, SpacingXsmall} from '@/componentLibrary/tokens/variables';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {FlexLayout} from '../layout/FlexLayout';
import {H4, S2} from '../typography';
import {Box} from './Box';
import {DetailsBoxProps} from './types';

export function DetailsBox({title, subtitle, description, ...rest}: DetailsBoxProps) {
    return (
        <Box {...rest}>
            <FlexLayout
                $justifyContent="space-between"
                style={{marginBlockEnd: SpacingXsmall}}
            >
                <H4>{title}</H4>
                {isDefined(subtitle) && <S2 $color={ColorFgSubtle}>{subtitle}</S2>}
            </FlexLayout>
            <div>{description}</div>
        </Box>
    );
}
