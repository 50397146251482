import {
    ColorBaseBlue600,
    ColorBaseGreen600,
    ColorBaseRed600,
    ColorBaseYellow700,
    ColorBgAttention,
    ColorBgDanger,
    ColorBgInfo,
    ColorBgInset,
    ColorBgSubtle,
    ColorBgSuccess
} from '@/componentLibrary/tokens/variables';
import {BannerType} from '../constants';

export const COLOR_MAP = {
    [BannerType.PLAIN]: {background: ColorBgSubtle, bannerColor: ColorBgInset},
    [BannerType.ATTENTION]: {background: ColorBgAttention, bannerColor: ColorBaseYellow700},
    [BannerType.SUCCESS]: {background: ColorBgSuccess, bannerColor: ColorBaseGreen600},
    [BannerType.INFO]: {background: ColorBgInfo, bannerColor: ColorBaseBlue600},
    [BannerType.ERROR]: {background: ColorBgDanger, bannerColor: ColorBaseRed600}
};
