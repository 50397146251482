import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {LINKEDIN_SYNC_STATUS} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/constants';
import {useLinkedinSyncStatus} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/useParams';
import {useUserFeatureFlags} from '@/services/experimentalFeatures/useUserFeatureFlags';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useState} from 'react';

export function useReadinessDataCollectionLogic() {
    const {privateUserFeatureFlags, loading: loadingFeatureFlags} = useUserFeatureFlags();
    const {user, loading: loadingUser} = useLoggedInUser();
    const loading = loadingFeatureFlags || loadingUser;

    const onSuccess = useCallback(() => {
        setShowSuccessView(true);
    }, []);

    const linkedinSyncStatus = useLinkedinSyncStatus();
    const collectedFromLinkedin = isDefined(linkedinSyncStatus);
    const [showSuccessView, setShowSuccessView] = useState(
        linkedinSyncStatus === LINKEDIN_SYNC_STATUS.SUCCESS
    );

    return {
        loading,
        onSuccess,
        user,
        privateUserFeatureFlags,
        showSuccessView,
        collectedFromLinkedin
    };
}
