import {createGlobalStyle} from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

// Hack to make Prettier format `createGlobalStyle` correctly
const styled = {createGlobalStyle};
type CookiebotBadgeGlobalStyleProps = {
    $intercomEnabled: boolean;
    $showCookiebotBadge: boolean;
};
export const CookiebotBadgeGlobalStyle = styled.createGlobalStyle<CookiebotBadgeGlobalStyleProps>`
    /* stylelint-disable-next-line selector-id-pattern */
    #CookiebotWidget {
        right: ${p => (p.$intercomEnabled ? '80px' : '20px')} !important;
        display: ${p => (p.$showCookiebotBadge ? 'block' : 'none')} !important;

        @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
            right: ${p => (p.$intercomEnabled ? '80px' : '10px')} !important;
            bottom: ${p => (p.$intercomEnabled ? '20px' : '10px')} !important;
        }

        @media only print {
            display: none !important;
        }
    }
`;
