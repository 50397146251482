import {getConfig} from '@/config';
import {logger} from '@/services/logrocket';
import {eeaMember, euMember} from 'is-european';
import {useCallback, useEffect, useState} from 'react';

export const IP_API_URL = 'https://pro-eu.ip-api.com/json';
const SWITZERLAND_COUNTRY_CODE = 'CH';

export const useUserIsEligibleForLinkedinDataCollection = () => {
    const [isEligible, setIsEligible] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean | undefined>(false);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const countryCode = await fetchIpAddressCountryCode();
            const isEligible = countryIsEligibleForLinkedinDataCollection(countryCode);
            setError(false);
            setIsEligible(isEligible);
        } catch (e) {
            logger.info(e);
            setError(true);
        } finally {
            setLoading(false);
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: want to trigger an initial fetch
    useEffect(() => {
        fetchData();
    }, []);

    return {loading, error, isEligible, reload: fetchData};
};

async function fetchIpAddressCountryCode() {
    const searchParams = new URLSearchParams({key: getConfig().IP_API_KEY});
    const url = `${IP_API_URL}?${searchParams.toString()}`;
    const response = await fetch(url, {method: 'GET'});

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const data = await response.json();
    return data.countryCode;
}

function countryIsEligibleForLinkedinDataCollection(countryCode: string) {
    const countryCodeUpperCase = countryCode.toUpperCase();

    if (euMember(countryCodeUpperCase)) {
        return true;
    }

    if (eeaMember(countryCodeUpperCase)) {
        return true;
    }

    return countryCode === SWITZERLAND_COUNTRY_CODE;
}
