import {H4, P2} from '@/componentLibrary/components/typography';

import {BannerButton} from '../components/BannerButton';
import {BannerProps} from '../types';
import {
    BannerBlurSquare,
    ButtonWrapper,
    MessageWrapper,
    ProminentBannerCircle,
    ProminentBannerWrapper,
    SectionWrapper,
    Wrapper
} from './styled';

export const ProminentBanner = ({
    type,
    title,
    children,
    action,
    customAction
}: BannerProps) => {
    return (
        <Wrapper type={type} $shouldAlignContentInCenter={!action && !customAction}>
            <SectionWrapper>
                <MessageWrapper>
                    <H4>{title}</H4>
                    <P2>{children}</P2>
                </MessageWrapper>
                <ButtonWrapper>
                    {!!action && <BannerButton isPromo action={action} />}
                    {!!customAction && customAction}
                </ButtonWrapper>
            </SectionWrapper>
            <ProminentBannerWrapper>
                <ProminentBannerCircle $type={type} />
                <BannerBlurSquare />
            </ProminentBannerWrapper>
        </Wrapper>
    );
};
