import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useVerifyImpersonationToken} from '@/api/impersonation/useVerifyImpersonationToken';
import {logger} from '@/services/logrocket';
import {clearImpersonationToken, setImpersonationToken} from '@/utils/ajax';
import {Params} from './types';

export function Impersonation() {
    const navigate = useNavigate();
    const client = useApolloClient();
    const [verifyImpersonationToken] = useVerifyImpersonationToken();

    const {token}: Params = useParams();
    const getTokenFromParams = useCallback(() => {
        return token ? window.decodeURIComponent(token) : null;
    }, [token]);

    const initImpersonation = useCallback(
        _token => {
            setImpersonationToken(_token);
            client.resetStore();
            navigate('/o', {replace: true});
        },
        [client, navigate]
    );

    const handleInvalidToken = useCallback(() => {
        navigate('/o', {replace: true});
    }, [navigate]);

    useEffect(() => {
        clearImpersonationToken();
    }, []);

    useEffect(() => {
        const _token = getTokenFromParams();
        if (!_token) {
            return handleInvalidToken();
        }

        verifyImpersonationToken(_token)
            .then(({data}) => {
                const ok = data?.verifyImpersonationToken?.ok;
                if (ok) {
                    initImpersonation(_token);
                } else {
                    handleInvalidToken();
                }
            })
            .catch(error => {
                logger.error(error);
                handleInvalidToken();
            });
    }, [getTokenFromParams, handleInvalidToken, initImpersonation, verifyImpersonationToken]);

    return null;
}
