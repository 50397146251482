import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {BorderRadiusBaseSmall, SpacingSmall} from '@/componentLibrary/tokens/variables';

type CheckBoxGroupWrapperProps = {
    $count: number;
};

export const CheckBoxGroupWrapper = styled.div<CheckBoxGroupWrapperProps>`
    display: grid;
    grid-template-rows: repeat(${({$count}) => Math.ceil($count / ($count >= 8 ? 3 : 2))}, 1fr);
    grid-auto-flow: column;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        grid-template-rows: repeat(${({$count}) => $count}, 1fr);
    }
`;

export const CheckBoxWrapper = styled.div`
    margin: 10px 0;
`;

export const DropdownWithCheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
`;

export const DropdownWithCheckboxesEmptyStateWrapper = styled.div`
    display: flex;
    width: 100%;
    min-height: 200px;
    align-items: center;
    justify-content: center;
    ${BorderBaseDefaultRule}
    border-radius: ${BorderRadiusBaseSmall};
`;
