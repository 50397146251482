import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GetUserRelevanceInformationQuery} from '@/api/types/__generated__/graphql';
import {GET_USER_RELEVANCE_INFORMATION} from '@/api/users/queries';
import {DeepPick} from '@/types/generic.types';
import {isDefined} from '@/utils/typeGuards/isDefined';

export type UserExperience = DeepPick<
    GetUserRelevanceInformationQuery,
    'user.userRelevanceInformation.userExperiences'
>[0];

export function useGetRelevanceInformation(userId?: number) {
    const {data, loading, error, refetch, startPolling, stopPolling} = useQuery(
        GET_USER_RELEVANCE_INFORMATION,
        {
            variables: {userId: userId ?? 0},
            skip: !isDefined(userId),
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first'
        }
    );

    const userRelevanceInformation = useMemo(
        () => data?.user?.userRelevanceInformation ?? null,
        [data]
    );

    return {userRelevanceInformation, loading, error, refetch, startPolling, stopPolling};
}
