import {useApolloClient} from '@apollo/client';
import {useCallback, useEffect} from 'react';

import {useRegisterUser} from '@/api/users/register/useRegisterUser';
import {useChangeLocaleForUser} from '@/api/users/useChangeLocaleForUser';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {RegisterUserLogicProps} from '@/pages/Signup/pages/RegisterUser/types';
import {setUserAuthToken} from '@/services/auth/utils';
import {PrivateExperimentalFeatureKeys} from '@/services/experimentalFeatures/features';
import {useUserFeatureFlags} from '@/services/experimentalFeatures/useUserFeatureFlags';
import {logger} from '@/services/logrocket';

export function useRegisterUserLogic({
    token,
    onSuccess,
    userId,
    registrationType,
    userLanguage,
    handleError
}: RegisterUserLogicProps) {
    const client = useApolloClient();
    const {locale, changeLocale} = useChangeLocale();
    const [doChangeLocaleForUser] = useChangeLocaleForUser();
    const [doRegisterUser, {loading, error}] = useRegisterUser(token);

    const {refetch: refetchUserFeatureFlags} = useUserFeatureFlags();

    useEffect(() => {
        if (userLanguage) {
            changeLocale(userLanguage);
        }
    }, [changeLocale, userLanguage]);

    const registerUser = useCallback(
        userData => {
            doRegisterUser(userData)
                .then(({data}) => {
                    const {ok, authToken, errorMessage} = data?.registerUser ?? {};
                    if (ok && authToken) {
                        setUserAuthToken(authToken);
                        client.cache.reset().then(() => {
                            doChangeLocaleForUser(userId, locale).catch(logger.error);
                            refetchUserFeatureFlags().then(({data}) => {
                                const readinessDataCollectionEnabled =
                                    data?.me?.featureFlags.includes(
                                        PrivateExperimentalFeatureKeys.READINESS_DATA_COLLECTION
                                    ) ?? false;
                                onSuccess(registrationType, readinessDataCollectionEnabled);
                            });
                        });
                    } else {
                        throw new Error(errorMessage ?? '');
                    }
                })
                .catch(handleError);
        },
        [
            handleError,
            doRegisterUser,
            client,
            onSuccess,
            doChangeLocaleForUser,
            locale,
            userId,
            registrationType,
            refetchUserFeatureFlags
        ]
    );

    return {loading, error, registerUser};
}
