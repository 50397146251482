import {defineMessages} from 'react-intl';

export default defineMessages({
    linkedInDataTitle: {
        id: 'app.readinessDataCollectionCTA.processing.title'
    },
    description: {
        id: 'app.readinessDataCollectionCTA.success.description'
    },
    continueToTests: {
        id: 'app.readinessDataCollectionCTA.success.continueToTests'
    }
});
