import {MarkdownSupportedBodyTypes} from '@/componentLibrary/components/typography/Markdown';

import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {
    DescriptionText,
    FormWrapper,
    TitleText,
    Wrapper
} from '@/pages/UserDemographics/styled';
import {UserDemographicsProps} from '@/pages/UserDemographics/types';
import {UserDemographicsForm} from './components/UserDemographicsForm';
import {useUserDemographicsLogic} from './logic';
import {userDemographicsMessages} from './messages';

export function UserDemographics({isMandatory, onSubmitSuccess}: UserDemographicsProps) {
    const {redirect} = useUserDemographicsLogic();
    const phrases = useExtractPhraseConstants(userDemographicsMessages);

    return (
        <Wrapper>
            <FormWrapper>
                <TitleText data-testid="demographics-title">{phrases.title}</TitleText>
                <DescriptionText body={MarkdownSupportedBodyTypes.P2}>
                    {phrases.description}
                </DescriptionText>
                <UserDemographicsForm
                    redirect={redirect}
                    onSubmitSuccess={onSubmitSuccess ?? redirect}
                    isMandatory={isMandatory}
                />
            </FormWrapper>
        </Wrapper>
    );
}
