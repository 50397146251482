import qs from 'qs';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {SsoOperationType, SsoProvider, SsoProviderType} from '@/services/auth/sso/constants';
import {
    getSsoStateFromSessionStorage,
    removeSsoStateFromSessionStorage,
    setSsoStateInSessionStorage
} from '@/services/auth/sso/utils';
import {clearAuthTokens} from '@/services/auth/utils';
import {isEmptyObject} from '@/utils/misc';
import {isValidEmail} from '@/utils/validators';

import {redirectToGoogle, redirectToMicrosoft} from '../../loginUtils';
import {errorMessages} from './messages';

export function useLoginFormLogic(
    login: (email: string | null, password: string | null) => void
) {
    const intl = useIntl();
    const emailFieldRef = useRef<HTMLInputElement>(null);
    const passwordFieldRef = useRef<HTMLInputElement>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [userAlreadyActiveError, setUserAlreadyActiveError] = useState(false);
    const [loginMethod, setLoginMethod] = useState<string | null>(null);
    const [ssoLoading, setSsoLoading] = useState(false);
    const navigate = useNavigate();

    const handleEmailValidation = useCallback(() => {
        const email = emailFieldRef.current ? emailFieldRef.current.value : null;
        if (!email) {
            return intl.formatMessage(errorMessages.NO_EMAIL);
        }

        if (!isValidEmail(email)) {
            return intl.formatMessage(errorMessages.INVALID_EMAIL_FORMAT);
        }

        return null;
    }, [intl]);

    const handlePasswordValidation = useCallback(() => {
        const password = passwordFieldRef.current ? passwordFieldRef.current.value : null;
        if (!password) {
            return intl.formatMessage(errorMessages.NO_PASSWORD);
        }

        return null;
    }, [intl]);

    const handleSubmit = useCallback(
        e => {
            e?.preventDefault();

            setLoginMethod('EMAIL_PASSWORD');
            const queryParams = new URLSearchParams(location.search);

            if (queryParams.has('message')) {
                queryParams.delete('message');
                navigate(
                    {pathname: location.pathname, search: queryParams.toString()},
                    {replace: true}
                );
            }

            const emailErrorMessage = handleEmailValidation();
            setEmailError(emailErrorMessage);

            const passwordErrorMessage = handlePasswordValidation();
            setPasswordError(passwordErrorMessage);

            const password = passwordFieldRef.current ? passwordFieldRef.current.value : null;
            const email = emailFieldRef.current ? emailFieldRef.current.value : null;

            if (emailErrorMessage || passwordErrorMessage) {
                return;
            }

            setUserAlreadyActiveError(false);
            login(email, password);
        },
        [login, navigate, handleEmailValidation, handlePasswordValidation]
    );

    const handleLoginWithSso = useCallback((ssoProvider: SsoProviderType) => {
        if (Object.values(SsoProvider).indexOf(ssoProvider) === -1) {
            return;
        }

        setLoginMethod(ssoProvider);
        setSsoLoading(true);

        const state = {
            ssoProvider: ssoProvider as SsoProviderType,
            operationType: SsoOperationType.LOGIN,
            redirectPath: qs.parse(location.search, {ignoreQueryPrefix: true})
                .redirect as string,
            redirectToLoginOnError: true,
            token: '',
            tosRevision: ''
        };

        setSsoStateInSessionStorage(state);
        if (ssoProvider === SsoProvider.GOOGLE) {
            redirectToGoogle(location.search);
            return;
        }

        if (ssoProvider === SsoProvider.MICROSOFT) {
            redirectToMicrosoft(location.search);
        }
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        clearAuthTokens();
    }, [handleSubmit]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const error = qs.parse(location.search, {ignoreQueryPrefix: true}).message;
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('message')) {
            if (error === 'USER_ALREADY_ACTIVATED') {
                setUserAlreadyActiveError(true);
            }
        }
        const ssoState = getSsoStateFromSessionStorage();
        if (isEmptyObject(ssoState)) {
            return;
        }
        if (ssoState.ssoProvider) {
            setLoginMethod(ssoState.ssoProvider);
        }
        if (ssoState.completed) {
            removeSsoStateFromSessionStorage();
            setSsoLoading(false);
        }
    }, [navigate]);

    return {
        emailFieldRef,
        passwordFieldRef,
        handleSubmit,
        emailError,
        passwordError,
        ssoLoading,
        loadingGoogleSso: ssoLoading && loginMethod === SsoProvider.GOOGLE,
        loadingMicrosoftSso: ssoLoading && loginMethod === SsoProvider.MICROSOFT,
        loadingOktaSso: ssoLoading && loginMethod === SsoProvider.OKTA,
        loginMethod,
        handleLoginWithSso,
        userAlreadyActiveError
    };
}
