import styled from 'styled-components';

import {
    BorderRadiusBaseLarge,
    ColorBgDefault,
    ColorBgInset,
    SpacingLarge,
    SpacingMedium,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{$width: number}>`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: ${SpacingMedium};
    gap: ${SpacingLarge};
    width: ${({$width}) => $width}px;
    height: 80px;
    background: ${ColorBgDefault};
    border: 1px solid ${ColorBgInset};
    box-sizing: border-box;
    border-radius:  ${BorderRadiusBaseLarge} ${BorderRadiusBaseLarge} 0 0;
    margin: -${SpacingSmall} 0;
`;
