import styled from 'styled-components';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {
    BorderRadiusBaseXsmall,
    BoxShadowBaseDrag,
    ColorBgSubtle,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';

export const ToastWrapper = styled.div`
    width: 100%;
    transition: all 1s;
    animation: 0.5s slideup;
    position: relative;

    @keyframes slideup {
        from {
            margin-top: -88px;
            bottom: -88px;
        }

        to {
            margin-top: 0;
            bottom: 0;
        }
    }
`;

export const LoadingIcon = styled(Icon)`
    animation: spin 1.5s ease-in-out infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(359deg);
        }
    }
`;

export const CloseButtonWrapper = styled(FlexLayout)`
    margin-inline: ${SpacingSmall};
    opacity: 0;
    pointer-events: none;
`;

export const Wrapper = styled.div<{$insetColor: string}>`
    max-width: 720px;
    width: 100%;
    background-color: ${ColorBgSubtle};
    min-height: 72px;
    border-top: 2px solid ${({$insetColor}) => $insetColor};
    box-shadow: ${BoxShadowBaseDrag};
    border-radius: ${BorderRadiusBaseXsmall};
    box-sizing: border-box;
    display: flex;

    &:hover ${CloseButtonWrapper} {
        opacity: 1;
        pointer-events: all;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-inline: ${SpacingSmall};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2em;
    flex: 1;
`;
