import {IconButton} from '@/componentLibrary/components/buttons/IconButton';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {
    ChangeEvent,
    WheelEvent,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import {v4 as uuid} from 'uuid';
import {
    TextFieldErrorMessage,
    TextFieldHelpText,
    TextFieldLabel,
    TextFieldSublabel
} from '../styled';
import {DEFAULT_WIDTH} from './constants';
import {InputWrapper, RightSideWrapper, StyledTextField, Wrapper} from './styled';
import {TextFieldProps} from './types';

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
    (
        {
            id,
            label,
            labelProps = {},
            sublabel,
            placeholder,
            disabled,
            readOnly,
            isRequired,
            value,
            defaultValue,
            helpText,
            onClear,
            onChange,
            actionComponent,
            tabIndex,
            errorMessage = '',
            hasError = false,
            additionalCss = [],
            $isClearable: isClearable = false,
            $fullWidth: fullWidth = false,
            $width: width = DEFAULT_WIDTH,
            ...rest
        },
        ref
    ): JSX.Element => {
        const [fallbackId] = useState(id ?? uuid());

        const [innerValue, setInnerValue] = useState(defaultValue ?? '');

        useEffect(() => {
            if (isDefined(value) && value !== innerValue) {
                setInnerValue(value);
            }
        }, [innerValue, value]);

        const handleInputChange = useCallback(
            (e: ChangeEvent<HTMLInputElement>) => {
                setInnerValue(e.target.value);
                onChange?.(e);
            },
            [onChange]
        );

        const handleClear = useCallback(() => {
            if (onClear) {
                onClear();
            } else {
                setInnerValue('');
            }
        }, [onClear]);

        const preventOnWheelEventForNumberType = useCallback(
            (e: WheelEvent<HTMLInputElement>) => {
                // focus out to prevent event updating the value for input with number type
                const target = e.target as HTMLInputElement;
                target.type === 'number' && target.blur();
            },
            []
        );

        const closeOrClearButtonOnClock = useMemo(
            () => (disabled ? () => undefined : handleClear),
            [disabled, handleClear]
        );

        return (
            <Wrapper $fullWidth={fullWidth}>
                {!!label && (
                    <TextFieldLabel
                        htmlFor={fallbackId}
                        $isRequired={isRequired}
                        $hasSublabel={!!sublabel}
                        {...labelProps}
                    >
                        {label}
                    </TextFieldLabel>
                )}
                {!!sublabel && <TextFieldSublabel>{sublabel}</TextFieldSublabel>}
                <InputWrapper $fullWidth={fullWidth} $width={width}>
                    <StyledTextField
                        id={fallbackId}
                        ref={ref}
                        disabled={disabled}
                        readOnly={readOnly}
                        tabIndex={readOnly ? -1 : tabIndex}
                        placeholder={placeholder}
                        value={innerValue}
                        onChange={handleInputChange}
                        defaultValue={defaultValue}
                        onWheel={preventOnWheelEventForNumberType}
                        className={hasError ? 'error' : ''}
                        additionalCss={additionalCss}
                        $fullWidth={fullWidth}
                        $width={width}
                        $isClearable={isClearable}
                        {...rest}
                    />
                    <RightSideWrapper $disabled={disabled}>
                        {actionComponent
                            ? actionComponent
                            : isClearable &&
                              innerValue && (
                                  <IconButton
                                      isSmall
                                      onClick={closeOrClearButtonOnClock}
                                      isDisabled={disabled}
                                  />
                              )}
                    </RightSideWrapper>
                </InputWrapper>
                {!!helpText && !(hasError && errorMessage) && (
                    <TextFieldHelpText>{helpText}</TextFieldHelpText>
                )}
                {!!errorMessage && hasError && (
                    <TextFieldErrorMessage>{errorMessage}</TextFieldErrorMessage>
                )}
            </Wrapper>
        );
    }
);
