import {P2, S2} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import messages from './messages';
import {Wrapper} from './styled';
import {Props} from './types';

export const EmptyWorkExperience = ({addAction}: Props) => {
    const phrases = useExtractPhraseConstants(messages);
    return (
        <Wrapper>
            <S2>{phrases.title}</S2>
            <P2>{phrases.description}</P2>
            {addAction}
        </Wrapper>
    );
};
