import {gql} from '../types/__generated__';

export const GET_ACCESS_TOKEN_DETAILS = gql(`
    query getAccessTokenDetails {
        accessTokenDetails {
            authenticatedWith
            organizationOktaConfiguration {
                domain
            }
        }
    }
`);

export const GET_ORGANIZATION_OKTA_CONFIGURATION = gql(`
    query getOrganizationOktaConfiguration($email: String!) {
        organizationOktaConfiguration(email: $email) {
            domain
            clientId
        }
    }
`);
