import {useCallback, useEffect, useState} from 'react';

import {LandingPageLayoutHeader} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader';
import {DestinationType} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader/types';
import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {LINKS} from '@/links';
import {CreateAccountSelector} from '@/pages/SignupV2/components/CreateAccountSelector';
import {useCreateAccountLogic} from '@/pages/SignupV2/components/CreateAccountSelector/logic';
import {CreateFreeTrial} from '@/pages/SignupV2/components/Email/CreateFreeTrial';
import {InfoPanel} from '@/pages/SignupV2/components/InfoPanel';
import {InvitationNotice} from '@/pages/SignupV2/components/InvitationNotice';
import {CreateOrganization} from '@/pages/SignupV2/components/SSO/CreateOrganization';
import {RegistrationChannel} from '@/pages/SignupV2/components/types';
import {SignupMode} from '@/pages/SignupV2/constants';
import {useEmailValidator} from '@/pages/SignupV2/hooks/useEmailValidator';
import '@/pages/SignupV2/recaptcha.css';
import {
    Column,
    ContentWrapper,
    HeaderWrapper,
    Page,
    Row,
    SingleColumn,
    Wrapper
} from '@/pages/SignupV2/styled';
import {GoogleTagManager} from '@/services/gtm';
import {HubspotTracking} from '@/services/hubspot';
import {Recaptcha} from '@/services/recaptcha';

import {useLocation, useNavigate} from 'react-router-dom';
import {SsoAuthData} from './types';

export function SignupPage() {
    const [isOrganizationStep, setIsOrganizationStep] = useState(false);
    const [emailToValidate, setEmailToValidate] = useState<string>('');
    const {isBlacklistedDomain} = useEmailValidator(emailToValidate);
    const navigate = useNavigate();
    const [ssoData, setSsoData] = useState({} as SsoAuthData);
    const {
        getSsoDataInSessionStorage,
        parseAndStoreHubspotParams,
        clearSsoDataInSessionStorage
    } = useCreateAccountLogic();

    const location = useLocation();

    const [signupMode, setSignupMode] = useState<SignupMode>(SignupMode.UNSELECTED);

    const redirectToSubpage = useCallback(
        (subpage: string) => {
            const urlParams = new URLSearchParams(window.location.search);
            navigate(`${LINKS.freeTrialSignup}/${subpage}?${urlParams.toString()}`);
        },
        [navigate]
    );

    const doSignupWithEmail = () => {
        redirectToSubpage('email/');
    };

    useEffect(() => {
        if (location.pathname.endsWith('email/')) {
            setSignupMode(SignupMode.EMAIL_SIGNUP);
            clearSsoDataInSessionStorage();
        } else if (location.pathname.endsWith('sso/')) {
            setSignupMode(SignupMode.SSO_SIGNUP);
        } else {
            setSignupMode(SignupMode.UNSELECTED);
            clearSsoDataInSessionStorage();
        }
    }, [clearSsoDataInSessionStorage, location]);

    useEffect(() => {
        parseAndStoreHubspotParams(
            signupMode === SignupMode.EMAIL_SIGNUP
                ? RegistrationChannel.EMAIL
                : RegistrationChannel.SSO
        );
    }, [signupMode, parseAndStoreHubspotParams]);

    useEffect(() => {
        const storedSsoData = getSsoDataInSessionStorage();
        if (storedSsoData && JSON.stringify(storedSsoData) !== JSON.stringify(ssoData)) {
            setSsoData(storedSsoData);
        }
    }, [getSsoDataInSessionStorage, ssoData]);

    useEffect(() => {
        if (isBlacklistedDomain) {
            setIsOrganizationStep(false);
        } else if ('email' in ssoData && !isBlacklistedDomain) {
            setEmailToValidate(ssoData.email);
            setIsOrganizationStep(true);
        }
    }, [ssoData, isBlacklistedDomain]);

    let content = (
        <Row $minHeight={'750px'}>
            <Column>
                <InfoPanel />
            </Column>

            <Column>
                <CreateAccountSelector
                    doSignupWithEmail={doSignupWithEmail}
                    showPersonalEmailBanner={isBlacklistedDomain}
                />
                <InvitationNotice />
            </Column>
        </Row>
    );

    if (isOrganizationStep) {
        content = (
            <Row $minHeight={'650px'}>
                <SingleColumn>
                    <CreateOrganization ssoData={ssoData} />
                </SingleColumn>
            </Row>
        );
    } else if (signupMode === SignupMode.EMAIL_SIGNUP) {
        content = (
            <Row $minHeight={'850px'}>
                <SingleColumn>
                    <CreateFreeTrial />
                </SingleColumn>
            </Row>
        );
    }

    const destination: DestinationType = {
        url: signupMode === SignupMode.UNSELECTED ? LINKS.home : null
    };

    return (
        <Wrapper>
            <ErrorBoundary wrapperStyle={{display: 'none'}}>
                <Recaptcha />
                <HubspotTracking />
                <GoogleTagManager />
            </ErrorBoundary>
            <Page>
                <HeaderWrapper>
                    <LandingPageLayoutHeader
                        isWhite
                        withNameAndLogo
                        marginBottom={0}
                        showLanguageDropdown={false}
                        destination={destination}
                    />
                </HeaderWrapper>
                <ContentWrapper>{content}</ContentWrapper>
            </Page>
        </Wrapper>
    );
}
