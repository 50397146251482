import {City, UserRelevanceInformation} from '@/api/types/__generated__/graphql';
import {Modify, NoNullFields} from '@/types/generic.types';

import {UserExperience as UserExperienceType} from '@/api/users/useGetRelevanceInformation';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {RecommendationRelevanceFormProps} from './types';

type UserRelevanceInformationNonNull = Omit<
    NoNullFields<UserRelevanceInformation>,
    '__typename'
>;

export type UserExperience = Modify<
    Omit<NoNullFields<UserExperienceType>, '__typename'>,
    {startDate?: Date; endDate?: Date | null}
>;

type ClientSelectedUserRelevanceInformation = Modify<
    UserRelevanceInformationNonNull,
    {
        preferredCitiesWithMetaData?: City[];
        preferredCities: City[] | undefined;
        userExperiences: UserExperience[];
    }
>;

function getClientSelectedUserRelevanceInformation(
    userRelevanceInformation: NonNullable<
        RecommendationRelevanceFormProps['userRelevanceInformation']
    >
): ClientSelectedUserRelevanceInformation {
    const preferredCities =
        userRelevanceInformation.preferredCitiesWithMetaData.map(toViewOptions);

    const interestedInLeadershipPositions =
        !!userRelevanceInformation.interestedInLeadershipPositions;

    return {
        userId: userRelevanceInformation.userId,
        preferredJobFamily: userRelevanceInformation.preferredJobFamily,
        interestedInLeadershipPositions: interestedInLeadershipPositions,
        linkedinProfileUrl: userRelevanceInformation.linkedinProfileUrl,
        preferredWorkLocations: userRelevanceInformation.preferredWorkLocations,
        preferredSpecialties: userRelevanceInformation.preferredSpecialties,
        preferredCitiesWithMetaData: preferredCities,
        spokenLanguages: userRelevanceInformation.spokenLanguages,
        userExperiences: userRelevanceInformation.userExperiences.map(toWorkExperienceItem)
    } as ClientSelectedUserRelevanceInformation;
}

const toViewOptions = (city: City) => ({
    id: city.id,
    name: `${city.name}, ${city.countryName}`,
    countryName: city.countryName
});

const toWorkExperienceItem = (workExperience: UserExperienceType): UserExperience => {
    const startDate = parseDate(workExperience.startDate) ?? new Date();
    const endDate = isDefined(workExperience.endDate)
        ? parseDate(workExperience.endDate)
        : null;

    return {
        id: workExperience.id,
        jobTitle: workExperience.jobTitle,
        companyName: workExperience.companyName ?? '',
        description: workExperience.description ?? '',
        startDate,
        endDate
    };
};

const parseDate = (date: string | null): Date | null => {
    if (!isDefined(date)) {
        return null;
    }

    try {
        return new Date(date);
    } catch {
        return null;
    }
};

export {getClientSelectedUserRelevanceInformation};
