import {UserExperience} from '@/pages/User/components/RecommendationRelevanceForm/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useMemo} from 'react';
import {EMPTY_CONTENT, EMPTY_STRING} from './constants';
import {FieldsState, UserExperienceKey, WorkExperienceValidationState} from './types';

export const useWorkExperienceFormValidation = (userExperience: UserExperience[]) => {
    const validationState: WorkExperienceValidationState = useMemo(
        () => userExperience.reduce(toValidationState, {}),
        [userExperience]
    );

    const invalidWorkExperienceIds = useMemo(
        () =>
            Object.entries(validationState)
                .filter(([_, fieldState]) => !isValid(fieldState))
                .map(([id]) => id),
        [validationState]
    );

    return {
        isValid: invalidWorkExperienceIds.length === 0,
        invalidIds: invalidWorkExperienceIds,
        hasFieldError: useHasFieldError(validationState)
    };
};

const toValidationState = (
    accumulator: WorkExperienceValidationState,
    workExperience: UserExperience
): WorkExperienceValidationState => {
    const toFieldState = (workExperience: UserExperience): FieldsState => {
        return {
            jobTitle: hasValue(workExperience.jobTitle),
            companyName: hasValue(workExperience.companyName),
            startDate: isDefined(workExperience.startDate),
            endDate: hasValidEndDate(workExperience.endDate, workExperience.startDate)
        };
    };

    return {
        ...accumulator,
        [workExperience.id]: toFieldState(workExperience)
    };
};

const useHasFieldError = (validationState: WorkExperienceValidationState) => {
    return useCallback(
        (id: string, field: UserExperienceKey) => validationState[id][field] === false,
        [validationState]
    );
};

const isValid = (fieldState: FieldsState) =>
    Object.values(fieldState).every(boolean => boolean);

const hasValidEndDate = (endDate?: Date | null, startDate?: Date) => {
    if (endDate === null) {
        return true;
    }

    if (startDate === undefined) {
        return false;
    }

    if (isDefined(startDate) && isDefined(endDate)) {
        return startDate <= endDate;
    }

    return false;
};

const hasValue = (value?: string) => {
    if (!isDefined(value)) {
        return false;
    }

    if (value === EMPTY_STRING) {
        return false;
    }

    return value !== EMPTY_CONTENT;
};
