import {FormattedMessage, useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {useOrganization} from '@/api/organizations/useOrganization';
import {useUserOrganizations} from '@/api/users/useUserOrganizations';
import {EmptyState} from '@/componentLibrary/blocks/EmptyState';
import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {organizationLinks} from '@/pages/Organization/links';
import {userLinks} from '@/pages/User/links';

import messages from './messages';

export function NoAccessFallback() {
    const {formatMessage} = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const {userOrganizations, loading, error, refetch} = useUserOrganizations();
    const {
        organization,
        loading: loadingOrganization,
        error: organizationError,
        refetch: organizationRefetch
    } = useOrganization();

    if (error) {
        return <ErrorState error={error} refetch={refetch} />;
    }
    if (organizationError || !organization) {
        return <ErrorState error={organizationError} refetch={organizationRefetch} />;
    }

    if (loading || loadingOrganization) {
        return <CenteredSpinner />;
    }

    const actions = {
        primary: {
            text: formatMessage(messages.goHome),
            onClick: () => {
                navigate(organizationLinks.home);
            }
        },
        secondary:
            userOrganizations.length > 1
                ? {
                      text: formatMessage(messages.switchOrganization),
                      onClick: () => {
                          navigate(userLinks.myWorkspacesWithRedirect(location.pathname));
                      }
                  }
                : undefined
    };

    return (
        <Wrapper>
            <EmptyState
                titleText={<FormattedMessage {...messages.accessDenied} />}
                contentText={
                    <FormattedMessage
                        {...messages.body}
                        values={{
                            organization: organization.name,
                            organizations: userOrganizations.length
                        }}
                    />
                }
                actions={actions}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    max-width: 1000px;
    height: calc(100vh - 100px);
    align-items: center;
    justify-content: center;
`;
