import qs from 'qs';
import {useCallback} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';

import {PERSONALITY_IRT_LINKS} from '@/pages/PersonalityTestIrt/links';

export function useGoToPersonalityTest(goBackUrl?: string) {
    const navigate = useNavigate();
    const location = useLocation();

    return useCallback(
        (personalityTestId, organizationId) => {
            const testUrl = PERSONALITY_IRT_LINKS.getLinkToTest({
                testId: personalityTestId,
                organizationId: organizationId?.toString()
            });
            const params = qs.parse(location.search, {ignoreQueryPrefix: true});
            let url = goBackUrl ? `${testUrl}?redirect=${goBackUrl}` : testUrl;
            if (params.organization_id) {
                url += `&organization_id=${params.organization_id}`;
            }
            navigate(url);
        },
        [navigate, location, goBackUrl]
    );
}
