import styled from 'styled-components';

import {
    BorderRadiusBaseXsmall,
    ColorBaseBlue900,
    ColorBaseGrey000,
    SpacingMedium,
    SpacingSmall,
    SpacingXsmall,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import {OptionItemWrapperProps} from '@/pages/LogicTestIrt/components/Options/types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: 760px) {
        margin-top: ${SpacingXxsmall};
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        margin-top: ${SpacingMedium};
    }
`;

export const OptionsRow = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${SpacingMedium};
    flex: none;

    &:last-child {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 370px) {
        margin-bottom: ${SpacingXsmall};
    }

    @media only screen and (min-width: 371px) and (max-width: 760px) {
        margin-bottom: ${SpacingSmall};
    }
`;

export const OptionItemWrapper = styled.div<OptionItemWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${SpacingXsmall};
    margin: 0 ${SpacingXsmall};
    background-color: #fff;
    border: ${p => (p.$isSelected ? `2px solid ${ColorBaseBlue900}` : `2px solid ${ColorBaseGrey000}`)};
    box-shadow: ${p =>
        p.$isSelected
            ? `0 ${SpacingXxsmall} ${SpacingSmall} rgb(0 0 0 / 15%)`
            : '0 4px 4px rgb(0, 0, 0 / 8%)'};
    border-radius: ${BorderRadiusBaseXsmall};

    &:hover {
        box-shadow: 0 ${SpacingXxsmall} ${SpacingSmall} rgb(0 0 0 / 15%);
    }

    @media only screen and (max-width: 370px) {
        margin: 0 ${SpacingXxsmall};
        padding: ${SpacingXxxsmall};
    }

    @media only screen and (min-width: 371px) and (max-width: 760px) {
        margin: 0 ${SpacingXxsmall};
    }
`;

export const OptionItem = styled.img`
    height: 80px;
    width: 80px;
    cursor: pointer;
`;
