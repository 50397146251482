import {AuthenticationPageLayout} from 'pages/common/authentication/AuthenticationPageLayout';
import {useLocation} from 'react-router-dom';

import {useCheckLoggedIn} from '@/api/auth/useCheckLoggedIn';
import {LoadingScreen} from '@/componentLibrary/components/LoadingScreen';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';

import {LoginStatusHandler} from './LoginStatusHandler';

export function LoginPage() {
    const location = useLocation();
    const {isLoggedIn, user, loading} = useCheckLoggedIn();
    const {locale, changeLocale} = useChangeLocale();

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <AuthenticationPageLayout>
            <LoginStatusHandler
                locale={locale}
                changeLocale={changeLocale}
                user={user}
                searchString={location.search}
                isLoggedIn={isLoggedIn}
            />
        </AuthenticationPageLayout>
    );
}

export {LoginPage as default};
