import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useLinkedinSyncStatus} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/useParams';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {OnError} from '../FindMoreJobOpportunities/types';
import messages, {linkedinSyncErrorMessages} from './messages';
import {CustomPlainButton} from './styled';
import {LogicProps} from './types';
import {getLinkedinAuthorizationUrl} from './utils';

export function useDataCollectionLogic({userId, redirectTo, onSuccess, onError}: LogicProps) {
    const intl = useIntl();
    const phrases = useExtractPhraseConstants(messages);

    const detailedInformationModalLogic = useSimpleModalLogic();
    const relevanceFormModalLogic = useSimpleModalLogic();

    const {relevanceParametersOptions} = useRelevanceParametersOptions();
    const {userRelevanceInformation} = useGetRelevanceInformation(userId);

    const linkedInCaption = intl.formatMessage(messages.linkedInCaption, {
        seeDetailedInformationPlainButton: (
            <CustomPlainButton onClick={detailedInformationModalLogic.openModal}>
                {phrases.seeDetailedInformation}
            </CustomPlainButton>
        )
    });

    const [isRetrieveLinkedinDataLoading, setIsRetrieveLinkedinDataLoading] = useState(false);
    const onClickRetrieveLinkedinData = useCallback(() => {
        setIsRetrieveLinkedinDataLoading(true);
        window.location.href = getLinkedinAuthorizationUrl(redirectTo);
    }, [redirectTo]);

    const onRelevanceFormSubmitSuccess = useCallback(() => {
        relevanceFormModalLogic.closeModal();
        onSuccess?.();
    }, [onSuccess, relevanceFormModalLogic]);

    useOnError(onError);

    return {
        phrases,
        linkedInCaption,
        detailedInformationModalLogic,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation,
        isRetrieveLinkedinDataLoading,
        onClickRetrieveLinkedinData,
        onRelevanceFormSubmitSuccess
    };
}

const useOnError = (onError?: OnError) => {
    const linkedinSyncErrorPhrases = useExtractPhraseConstants(linkedinSyncErrorMessages);
    const linkedinSyncStatus = useLinkedinSyncStatus();

    const getErrorPhrase = (key?: string) => {
        if (!isDefined(key)) {
            return null;
        }

        return linkedinSyncErrorPhrases[key as keyof typeof linkedinSyncErrorPhrases] || null;
    };

    const errorTitle = getErrorPhrase(`${linkedinSyncStatus}_title`);
    const errorDescription = getErrorPhrase(`${linkedinSyncStatus}_description`);

    useEffect(() => {
        const hasError = isDefined(errorDescription);
        if (!isDefined(linkedinSyncStatus) || !hasError) {
            return;
        }

        onError?.({
            description: errorDescription,
            title: errorTitle,
            errorStatus: linkedinSyncStatus
        });
    }, [errorTitle, errorDescription, onError, linkedinSyncStatus]);
};
