import {UserExperience} from '../../../../utils';

export const isEmpty = (userExperience: UserExperience) => {
    return (
        !userExperience.jobTitle &&
        !userExperience.companyName &&
        !userExperience.description &&
        !userExperience.startDate &&
        !userExperience.endDate
    );
};
