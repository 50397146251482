import {useEffect, useState} from 'react';

import {getConfig} from '@/config';
import {CookiebotBadgeGlobalStyle} from '@/services/cookiebot/CookiebotBadgeGlobalStyle';
import {displayIntercom, hideIntercom} from '@/services/intercom/utils';
import {logger} from '@/services/logrocket';
import {shouldHideBadges} from '@/utils/shouldHideBadges';

export const CookiebotBanner = () => {
    const isInitialized = useCookiebot();
    const [isIntercomEnabled, setIsIntercomEnabled] = useState(false);
    const [isCookiebotBadgeVisible, setIsCookiebotBadgeVisible] = useState(true);
    const inSharedResultsPage = /shared-results/.test(location.pathname);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }

        window.Intercom('onShow', function () {
            setIsCookiebotBadgeVisible(false);
        });
        window.Intercom('onHide', function () {
            setIsCookiebotBadgeVisible(true);
        });

        window.addEventListener('CookiebotOnAccept', function () {
            if ('Cookiebot' in window) {
                if (window.Cookiebot.consent.preferences === true) {
                    setIsIntercomEnabled(true);
                    displayIntercom();
                } else if (window.Cookiebot.consent.preferences === false) {
                    setIsIntercomEnabled(false);
                    hideIntercom();
                }
            }
        });

        window.addEventListener('CookiebotOnDecline', function () {
            setIsIntercomEnabled(false);
            hideIntercom();
        });

        logger.log('Loaded cookiebot ok ', getConfig().COOKIEBOT_ID);
    }, [isInitialized]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setIsIntercomEnabled(
            !inSharedResultsPage &&
                'Cookiebot' in window &&
                window.Cookiebot.consent.preferences === true
        );
    }, [isIntercomEnabled]);

    return (
        <CookiebotBadgeGlobalStyle
            $intercomEnabled={isIntercomEnabled}
            $showCookiebotBadge={
                isCookiebotBadgeVisible && !shouldHideBadges(location.pathname)
            }
        />
    );
};

function useCookiebot() {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        (function () {
            if ('TestingMode' in window) {
                console.log('Playwright detected, skipping cookiebot');
                return;
            }
            const parent = document.getElementsByTagName('script')[0].parentNode;
            if (parent === null) {
                return;
            }

            const script = document.createElement('script');

            script.src = 'https://consent.cookiebot.com/uc.js';
            script.setAttribute('data-cbid', getConfig().COOKIEBOT_ID);
            script.setAttribute('data-blockingmode', 'auto');
            script.onload = () => {
                setInitialized(true);
            };
            script.onerror = () => {
                logger.error('Error loading cookiebot');
            };
            parent.insertBefore(script, parent.firstChild);
        })();
    }, []);
    return initialized;
}
