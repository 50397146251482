import {useIntl} from 'react-intl';

import {FormattedHTMLMessage} from '@/componentLibrary/components/FormattedHTMLMessage';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';

import {VALIDATION_RESULT_TYPE} from '../../constants';
import messages, {validationResultTypeMessages} from './messages';
import {ReasonsWrapper} from './styled';
import {ValidationBannerProps} from './types';

export function ValidationBanner({isCompleted, validationResultType}: ValidationBannerProps) {
    const intl = useIntl();

    if (!isCompleted) {
        const bannerTitle = intl.formatMessage(messages.validationTestRequestedTitle);
        const bannerContent = intl.formatMessage(messages.validationTestRequestedDescription);
        return <Banner title={bannerTitle}>{bannerContent}</Banner>;
    }

    if (validationResultType === VALIDATION_RESULT_TYPE.VALIDATED) {
        const bannerTitle = intl.formatMessage(messages.testIsValidatedTitle);
        const bannerContent = intl.formatMessage(messages.testIsValidatedDescription);
        return (
            <Banner type={BannerType.SUCCESS} title={bannerTitle}>
                {bannerContent}
            </Banner>
        );
    }

    if (
        [VALIDATION_RESULT_TYPE.LOWER, VALIDATION_RESULT_TYPE.HIGHER].includes(
            validationResultType ?? ''
        )
    ) {
        const bannerTitle = intl.formatMessage(messages.testIsInvalidTitle);
        const differenceText = intl.formatMessage(
            validationResultType === VALIDATION_RESULT_TYPE.LOWER
                ? validationResultTypeMessages.LOWER
                : validationResultTypeMessages.HIGHER
        );
        const bannerDescription = intl.formatMessage(messages.testIsInvalidDescription, {
            difference: <strong>{differenceText}</strong>
        });
        return (
            <Banner type={BannerType.ERROR} title={bannerTitle}>
                <div>{bannerDescription}</div>
                <ReasonsWrapper>
                    <FormattedHTMLMessage {...messages.testIsInvalidReasons} />
                </ReasonsWrapper>
            </Banner>
        );
    }

    return null;
}
