import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 140px;
`;

export const DateRangeWrapper = styled.div`
    display: flex;
    flex-basis: 0;
    flex-direction: row;
    gap: ${SpacingXxsmall};
    flex-grow: 1;
    flex-wrap: wrap;
`;
