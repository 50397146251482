import {useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_AUTHENTICATED_USER} from './queries';

export function useAuthenticatedUser() {
    const {error, loading, data, refetch} = useQuery(GET_AUTHENTICATED_USER);
    const user = useMemo(() => data?.authenticatedUser ?? null, [data]);
    return {error, loading, user, refetch};
}
