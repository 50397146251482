import {TRIGGER_LINKEDIN_USER_DATA_SYNC} from '@/api/jobRecommendations/mutations';
import {TriggerLinkedinUserDataSyncMutation} from '@/api/types/__generated__/graphql';
import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {getRedirectUri} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMutation} from '@apollo/client';
import {useCallback} from 'react';

type TriggerLinkedinUserDataSyncInput = {
    authCode: string;
};

export function useTriggerLinkedinUserDataSync(
    userId?: number | null
): MutationHookReturnType<
    TriggerLinkedinUserDataSyncMutation,
    TriggerLinkedinUserDataSyncInput
> {
    const [mutate, {error, loading}] = useMutation(TRIGGER_LINKEDIN_USER_DATA_SYNC);
    const redirectUri = getRedirectUri();

    const triggerLinkedinUserDataSync = useCallback(
        ({authCode}: TriggerLinkedinUserDataSyncInput) => {
            if (!isDefined(userId)) {
                return Promise.reject(new Error('User id is required'));
            }

            const variables = {data: {authCode, userId, redirectUri}};
            return mutate({variables});
        },
        [mutate, userId, redirectUri]
    );

    return [triggerLinkedinUserDataSync, {error, loading}];
}
