import {ParsedQs} from 'qs';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {LINKS} from '@/links';
import {userLinks} from '@/pages/User/links';
import {SsoProvider} from '@/services/auth/sso/constants';
import {SsoState} from '@/services/auth/sso/types';

type ErrorMessage = ParsedQs | ParsedQs[] | string[] | string | undefined;
type Params = {
    message?: ErrorMessage;
    redirect?: string;
    t?: string;
    additionalParams?: URLSearchParams;
};

export function useSsoRedirectionLogic(state?: SsoState) {
    const navigate = useNavigate();

    const getSearchString = useCallback(
        ({message, redirect, t, additionalParams}: Params): string => {
            let params = new URLSearchParams({
                ...(message && {message: message as string}),
                ...(redirect && {redirect}),
                ...(t && {t})
            });

            if (additionalParams) {
                params = new URLSearchParams({
                    ...Object.fromEntries(params),
                    ...Object.fromEntries(additionalParams)
                });
            }

            return params.toString();
        },
        []
    );

    const redirectToUrl = useCallback(
        (errorMessage?: ErrorMessage) => {
            if (!state?.redirectPath) {
                navigate(userLinks.myWorkspaces());
                return;
            }

            navigate({
                pathname: state?.redirectPath,
                search: getSearchString({message: errorMessage})
            });
        },
        [navigate, state?.redirectPath, getSearchString]
    );

    const redirectToLogin = useCallback(
        (errorMessage: ErrorMessage) => {
            const search = getSearchString({
                message: errorMessage,
                redirect: state?.redirectPath
            });

            const pathname =
                state?.ssoProvider === SsoProvider.OKTA ? LINKS.oktaLogin : LINKS.login;
            navigate({
                pathname: pathname,
                search
            });
        },
        [state?.redirectPath, state?.ssoProvider, navigate, getSearchString]
    );

    const redirectToSignup = useCallback(
        (errorMessage: ErrorMessage) => {
            const search = getSearchString({
                message: errorMessage,
                redirect: state?.redirectPath,
                t: state?.token
            });
            navigate({
                pathname: LINKS.signup,
                search
            });
        },
        [state?.token, state?.redirectPath, navigate, getSearchString]
    );

    const redirectToSignupV2 = useCallback(
        (errorMessage?: ErrorMessage, rawParams?: string) => {
            const additionalParams = rawParams ? new URLSearchParams(rawParams) : undefined;
            const search = getSearchString({
                message: errorMessage,
                additionalParams
            });

            navigate({
                pathname: LINKS.freeTrialSignup + '/sso/',
                search
            });
        },
        [navigate, getSearchString]
    );

    return {
        redirectToUrl,
        redirectToLogin,
        redirectToSignup,
        redirectToSignupV2
    };
}
