import styled from 'styled-components';

import {BodyP2} from '@/componentLibrary/tokens/typography';
import {ColorFgDefault, SpacingXsmall} from '@/componentLibrary/tokens/variables';

export const CheckBoxLabel = styled.label<{$color?: string; $disabled: boolean}>`
    ${BodyP2};
    margin-left: ${SpacingXsmall};
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
    color: ${({$color}) => $color ?? ColorFgDefault};
    cursor: ${({$disabled}) => ($disabled ? 'not-allowed' : 'pointer')};
`;
