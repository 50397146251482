import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {Section} from '@/componentLibrary/components/layout/Section';
import {H1, P1} from '@/componentLibrary/components/typography';
import {MarkdownWrapper} from '@/componentLibrary/components/typography/Markdown';
import {SpacingMedium} from '@/componentLibrary/tokens/variables';

import messages from './messages';

export function NotFound() {
    return (
        <Section
            className="alva-bg"
            style={{padding: SpacingMedium, minHeight: '100vh', display: 'flex'}}
            header={undefined}
            loading={undefined}
            error={undefined}
            emptyState={undefined}
        >
            <FlexLayout $alignItems="center">
                <MarkdownWrapper>
                    <H1>
                        <FormattedMessage {...messages.title} />
                    </H1>
                    <P1>
                        <FormattedMessage {...messages.description} />
                    </P1>
                    <P1>
                        <Link to="/o">
                            <FormattedMessage {...messages.link} />
                        </Link>
                    </P1>
                </MarkdownWrapper>
            </FlexLayout>
        </Section>
    );
}
