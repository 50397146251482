export const LINKS = {
    candidateServices: '/candidate-services',
    recruitmentServices: '/recruitment-services',
    terms: '/terms',
    termsOfService: '/terms-of-service',
    privacyPolicy: '/privacy-policy',
    dataProcessors: '/data-processors',
    security: '/security',
    login: '/login',
    oktaLogin: '/okta-login',
    home: 'https://www.alvalabs.io/',
    signup: '/signup',
    freeTrialSignup: '/free-trial-signup',
    upgradePlanHubspotPage: 'https://www.alvalabs.io/upgrades',
    readinessDataCollection: (organizationId: number) =>
        `/readiness-data-collection/${organizationId}`,
    logout: '/logout'
};
