import {P2, S2} from '@/componentLibrary/components/typography';
import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {
    BorderRadiusBaseLarge,
    ColorBaseGrey000,
    ColorBgBackdrop,
    SpacingLarge,
    SpacingSmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';
import {MODAL_WIDTH} from '../Modal/constants';

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100001;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${ColorBgBackdrop};
    cursor: default;
`;
type ModalProps = {
    $textAlignment: 'center' | 'left';
};
export const ModalBase = styled.div<ModalProps>`
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: ${({$textAlignment}) => $textAlignment};
    width: 416px;
    background-color: ${ColorBaseGrey000};
    border-radius: ${BorderRadiusBaseLarge};
    padding: ${SpacingLarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        width: 288px;
    }
`;
export const MediumModal = styled(ModalBase)`
    width: ${MODAL_WIDTH.MEDIUM};
`;
export const S2Wrapper = styled(S2)`
    margin-bottom: ${SpacingSmall};
    white-space: pre-wrap;
`;
export const P2Wrapper = styled(P2)`
    margin-bottom: ${SpacingLarge};
    white-space: pre-wrap;
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${SpacingSmall};
    width: 100%;

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        flex-direction: column-reverse;
    }
`;
