import styled from 'styled-components';

import {ColorVariables} from '@/componentLibrary/tokens/types';

type BaseProps = {
    $color?: ColorVariables;
};

export const Base = styled.span<BaseProps>`
    ${({$color}) => $color && `color: ${$color};`}
`;
