import {parse} from 'qs';
import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

export function useUserDemographicsLogic() {
    const navigate = useNavigate();
    const location = useLocation();

    const redirect = useCallback(() => {
        const redirectUrl = parse(location.search, {ignoreQueryPrefix: true})
            .redirect as string;

        if (!redirectUrl) {
            navigate('/');
            return;
        }

        const hasAdditionalParams = redirectUrl.includes('?');

        if (hasAdditionalParams) {
            navigate(redirectUrl + '&fillInLater=true');
        } else {
            navigate(redirectUrl + '?fillInLater=true');
        }
    }, [navigate, location.search]);

    return {redirect};
}
