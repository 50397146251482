import {useMemo} from 'react';

import {InPageHeader} from '@/componentLibrary/components/InPageHeader';
import {TITLE_SIZE} from '@/componentLibrary/components/InPageHeader/types';
import {SmallPrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {H4, P2, S1} from '@/componentLibrary/components/typography';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {LeadershipDimensions} from './components/LeadershipDimensions';
import messages from './messages';
import {LeadershipSection, LeadershipSectionsWrapper, Wrapper} from './styled';
import {PhraseKey, Props} from './types';

export function LeadershipReportOverview({
    leadershipProfile,
    onClickSeeReport,
    linkToOpen,
    forPrint = false
}: Props) {
    const phrases = useExtractPhraseConstants(messages);
    const {sections} = leadershipProfile;

    const leaderShipSections = sections.map(section => {
        const sectionTitlePhraseKey = section.name as PhraseKey;
        const sectionTitle = phrases[sectionTitlePhraseKey];
        return (
            <LeadershipSection key={section.name}>
                <S1>{sectionTitle}</S1>
                <LeadershipDimensions dimensions={section.dimensions} />
            </LeadershipSection>
        );
    });

    const action = useMemo(() => {
        if (isDefined(linkToOpen)) {
            return <ExternalLink url={linkToOpen}>{phrases.seeFullReport}</ExternalLink>;
        }
        if (isDefined(onClickSeeReport)) {
            return (
                <SmallPrimaryButton onClick={onClickSeeReport}>
                    {phrases.seeFullReport}
                </SmallPrimaryButton>
            );
        }
    }, [linkToOpen, onClickSeeReport, phrases.seeFullReport]);

    return (
        <Wrapper>
            {!forPrint && (
                <>
                    <InPageHeader
                        title={{content: phrases.leadershipTitle, size: TITLE_SIZE.SMALL}}
                        primaryAction={action}
                    />
                    <P2>{phrases.leadershipDescription}</P2>
                </>
            )}
            {forPrint && <H4>{phrases.leadershipTitleForPrint}</H4>}
            <LeadershipSectionsWrapper>{leaderShipSections}</LeadershipSectionsWrapper>
        </Wrapper>
    );
}
