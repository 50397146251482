import styled from 'styled-components';

import {SpacingXlarge} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {Icon} from '../../icons/Icon';
import {SecondaryButton} from '../SecondaryButton';
import {ButtonProps} from '../types';
import messages from './messages';
import {SsoButtonDefaultProps} from './types';
import {getIcon, getMessage} from './utils';

export function SsoButton({
    onClick,
    isLoading: isLoading,
    disabled,
    isSignup = false,
    isFullWidth: isFullWidth = true,
    ssoProvider,
    ...props
}: SsoButtonDefaultProps) {
    const phrases = useExtractPhraseConstants(messages);
    const message = getMessage(ssoProvider, isSignup, phrases);
    if (isSignup === undefined || ssoProvider === undefined) {
        throw Error('One of the required inputs is not given');
    }
    return (
        <StyledSsoButton
            onClick={onClick}
            isFullWidth={isFullWidth}
            isLoading={isLoading}
            disabled={disabled}
            {...props}
        >
            <Icon icon={getIcon(ssoProvider)} />
            {message}
        </StyledSsoButton>
    );
}

const StyledSsoButton = styled(SecondaryButton)<ButtonProps>`
    width: ${({isFullWidth: $isFullWidth}) => ($isFullWidth ? '100%' : 'auto')};
    display: grid;
    grid-template-columns: ${SpacingXlarge} 1fr ${({isFullWidth: $isFullWidth}) =>
        $isFullWidth ? SpacingXlarge : 0};
    align-items: center;
    text-align: center;
    padding-block: 10px;

    > * {
        visibility: ${({isLoading: $isLoading}) => ($isLoading ? 'hidden' : 'visible')};
    }
`;
