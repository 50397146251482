import {useCallback} from 'react';

import {DestinationType} from '@/componentLibrary/blocks/LandingPageLayout/components/LandingPageLayoutHeader/types';
import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';

import {useNavigate} from 'react-router-dom';
import blackAlvaLogo from './assets/blackAlvaLogo.svg';
import blackAlvaLogoWithText from './assets/blackAlvaLogoWithName.svg';
import whiteAlvaLogo from './assets/whiteAlvaLogo.svg';
import whiteAlvaLogoWithText from './assets/whiteAlvaLogoWithName.svg';
import {AlvaLogo, Wrapper, transparentLanguageDropdownCss} from './styled';

type LandingPageLayoutHeaderProps = {
    isWhite: boolean;
    withLogo?: boolean;
    withNameAndLogo?: boolean;
    marginBottom?: string | number;
    showLanguageDropdown?: boolean;
    recruiterLocalesOnly?: boolean;
    destination?: DestinationType;
};

export function LandingPageLayoutHeader({
    isWhite,
    withLogo = true,
    withNameAndLogo = false,
    showLanguageDropdown = true,
    recruiterLocalesOnly = false,
    destination = {url: '/'},
    marginBottom
}: LandingPageLayoutHeaderProps) {
    const navigate = useNavigate();
    const redirectToIndex = useCallback(() => {
        if (destination.url) {
            if (destination.url.startsWith('http')) {
                window.location.href = destination.url;
            } else {
                navigate(destination.url);
            }
        }
    }, [destination, navigate]);

    const logoImage = isWhite ? blackAlvaLogo : whiteAlvaLogo;
    const logoImageWithText = isWhite ? blackAlvaLogoWithText : whiteAlvaLogoWithText;
    let logo = null;
    let onClickProp = {};
    if (destination.url !== null) {
        onClickProp = {
            onClick: redirectToIndex
        };
    }
    if (withNameAndLogo) {
        logo = (
            <AlvaLogo
                data-testid="alva-logo-with-name"
                className="alva-logo-with-name"
                $width={'105px'}
                $backgroundImage={logoImageWithText}
                $clickable={destination.url !== null}
                {...onClickProp}
            />
        );
    } else if (withLogo) {
        logo = (
            <AlvaLogo
                data-testid="alva-logo"
                $width={'40px'}
                $backgroundImage={logoImage}
                $clickable={!destination?.url}
                {...onClickProp}
            />
        );
    }
    return (
        <Wrapper $withLogo={withLogo} $marginBottom={marginBottom}>
            {logo}
            {showLanguageDropdown && (
                <LanguageToggle
                    recruiterLocalesOnly={recruiterLocalesOnly}
                    inputCss={!isWhite ? transparentLanguageDropdownCss : undefined}
                />
            )}
        </Wrapper>
    );
}
