import {PhraseConstants, useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useMemo} from 'react';
import messages from './messages';
import {LogicProps, Option} from './types';
import {currentYear, toDate} from './utils';

const toOption = (name: string | null | undefined) => {
    if (!isDefined(name)) {
        return null;
    }

    return {
        id: name,
        name
    } as Option;
};

export const useDateDropdownLogic = ({
    selectedDate,
    onUpdate,
    includePresent = false
}: LogicProps) => {
    const phrases = useExtractPhraseConstants(messages);
    const isPresent = selectedDate === null;
    const monthsOptions = useMonthOptions(phrases, includePresent);
    const yearsOptions = useYearsOptions();
    const selectedYear = useSelectedYear(selectedDate);
    const selectedMonth = useSelectedMonth(
        phrases,
        monthsOptions,
        includePresent,
        selectedDate
    );

    const onUpdateMonth = (option: Option | null) => {
        if (!isDefined(option)) {
            return;
        }

        if (option.id === phrases.present) {
            onUpdate(null);
            return;
        }

        const month = option.id;
        const year = selectedYear ?? currentYear();
        const date = toDate(month, year.toString());
        onUpdate(date);
    };

    const onUpdateYear = (option: Option | null) => {
        if (!isDefined(option) || !isDefined(selectedMonth)) {
            return;
        }

        const year = option.id;
        const date = toDate(selectedMonth, year);
        onUpdate(date);
    };

    return {
        toOption,
        monthsOptions,
        yearsOptions,
        selectedMonth,
        selectedYear,
        onUpdateMonth,
        onUpdateYear,
        isPresent,
        phrases
    };
};

const useMonthOptions = (phrases: PhraseConstants, includePresent: boolean) => {
    return useMemo(() => {
        const months = [
            phrases.january,
            phrases.february,
            phrases.march,
            phrases.april,
            phrases.may,
            phrases.june,
            phrases.july,
            phrases.august,
            phrases.september,
            phrases.october,
            phrases.november,
            phrases.december
        ];
        return includePresent ? [phrases.present, ...months] : months;
    }, [phrases, includePresent]);
};

const useYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    const NUMBER_OF_YEARS = 50;
    return [...Array(NUMBER_OF_YEARS).keys()].map(index => currentYear - index).map(String);
};

const useSelectedMonth = (
    phrases: PhraseConstants,
    monthsOptions: string[],
    includePresent: boolean,
    selectedDate?: Date | null
) => {
    return useMemo(() => {
        const isPresent = selectedDate === null;
        if (isPresent) {
            return phrases.present;
        }

        if (selectedDate === undefined) {
            return undefined;
        }

        const selectedMonthRaw = selectedDate?.getMonth();
        return includePresent
            ? monthsOptions[selectedMonthRaw + 1]
            : monthsOptions[selectedMonthRaw];
    }, [selectedDate, phrases.present, monthsOptions, includePresent]);
};

const useSelectedYear = (selectedDate?: Date | null) => {
    return useMemo(() => {
        if (!isDefined(selectedDate)) {
            return null;
        }

        return selectedDate.getFullYear();
    }, [selectedDate]);
};
