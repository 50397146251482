export const BASE_URL = '/personality-test-irt';

type Props = {
    testId: string;
    organizationId?: string;
    jobPositionId?: string;
};

const getLink = (testId: string, organizationId?: string, jobPositionId?: string) => {
    if (jobPositionId) {
        return `${BASE_URL}/${testId}/job-position-id/${jobPositionId}`;
    }
    if (organizationId) {
        return `${BASE_URL}/${testId}/organization-id/${organizationId}`;
    }

    return `${BASE_URL}/${testId}`;
};

const getLinkToTest = ({testId, organizationId, jobPositionId}: Props) =>
    `${getLink(testId, organizationId, jobPositionId)}/test`;

const getTestCompletedLink = ({testId, organizationId, jobPositionId}: Props) =>
    `${getLink(testId, organizationId, jobPositionId)}/completed`;

const getTestResultLink = ({testId, organizationId, jobPositionId}: Props) =>
    `${getLink(testId, organizationId, jobPositionId)}/report`;

export const PERSONALITY_IRT_LINKS = {
    getStartTestLink: () => `${BASE_URL}/start`,
    getLinkToTest,
    getTestCompletedLink,
    getTestResultLink
};
