import styled, {css} from 'styled-components';

import {
    SpacingMedium,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

import {ColumnProps} from './types';

export const Column = styled.div.attrs<ColumnProps>(p => ({
    ...p,
    $shrink: p.$shrink || true,
    $grow: p.$grow || true,
    $keepGrid: p.$keepGrid || false,
    $offsetPercentage: p.$offsetPercentage || 0,
    $offsetRightPercentage: p.$offsetRightPercentage || 0
}))<ColumnProps>`
    flex: ${p => (p.$grow ? 1 : 0)} ${p => (p.$shrink ? 1 : 0)} ${p => p.$percentage}%;
    margin: 0
        ${p =>
            p.$noMargin
                ? `${p.$offsetRightPercentage}%`
                : `calc(${SpacingXxsmall} + ${p.$offsetRightPercentage}%)`}
        0
        ${p => (p.$noMargin ? `${p.$offsetPercentage}%` : `calc(8px + ${p.$offsetPercentage}%)`)};

    &:first-child {
        margin-left: ${p => p.$offsetPercentage}%;
    }

    &:last-child {
        margin-right: ${p => p.$offsetRightPercentage}%;
    }

    @media (max-width: 768px) {
        ${p =>
            !p.$keepGrid &&
            css`
                margin-top: 0;
                margin-right: 0;
                margin-left: 0;
                padding-bottom: ${SpacingMedium};

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            `};
    }

    @media print {
        display: inline;
        margin-right: ${SpacingXsmall};
    }

    ${({$customCss}) => $customCss}
`;

export const Wrapper = styled.div<{
    $keepGridOnMobile?: boolean;
    $css?: ReturnType<typeof css>;
}>`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        display: ${p => (p.$keepGridOnMobile ? 'flex' : 'block')};
    }

    @media print {
        display: flex;
    }

    ${p => p.$css}
`;
