import {LanguageToggle} from '@/componentLibrary/components/Dropdowns/LanguageToggle';
import {LogoWithText} from '@/componentLibrary/components/Logo/LogoWithText';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {LINKS} from '@/links';
import {HighlightedJobs} from '@/pages/ReadinessDataCollection/components/HighlightedJobs';
import {useReadinessDataCollectionLogic} from '@/pages/ReadinessDataCollection/logic';
import {START_INDEX_URL, userLinks} from '@/pages/User/links';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {Navigate, useParams} from 'react-router-dom';
import {FindMoreJobOpportunities} from './components/FindMoreJobOpportunities';
import {SuccessPage} from './components/SuccessPage';
import {ContentWrapper, HeaderWrapper, LeftColumn, RightColumn} from './styled';

export function ReadinessDataCollection() {
    const {organizationId} = useParams();
    const organizationIdNumber = Number(organizationId);

    const {
        user,
        privateUserFeatureFlags,
        loading,
        onSuccess,
        showSuccessView,
        collectedFromLinkedin
    } = useReadinessDataCollectionLogic();
    if (loading) {
        return <PageCenteredSpinner />;
    }

    if (!isDefined(user)) {
        return <Navigate to={START_INDEX_URL} />;
    }

    if (!privateUserFeatureFlags.READINESS_DATA_COLLECTION) {
        return <Navigate to={userLinks.jobApplication(organizationIdNumber)} />;
    }

    if (showSuccessView) {
        return (
            <SuccessPage
                organizationId={organizationIdNumber}
                collectedFromLinkedin={collectedFromLinkedin}
            />
        );
    }

    return (
        <>
            <HeaderWrapper>
                <LogoWithText />
                <LanguageToggle userId={user.id} />
            </HeaderWrapper>
            <ContentWrapper>
                <LeftColumn>
                    <div>
                        <FindMoreJobOpportunities
                            userId={user.id}
                            organizationId={organizationIdNumber}
                            onSuccess={onSuccess}
                            redirectTo={LINKS.readinessDataCollection(organizationIdNumber)}
                        />
                    </div>
                </LeftColumn>
                <RightColumn>
                    <HighlightedJobs />
                </RightColumn>
            </ContentWrapper>
        </>
    );
}
