import {MutationHookReturnType} from '@/api/types/genericApi/types';
import {ApolloCache, FetchResult, useMutation} from '@apollo/client';
import {useCallback} from 'react';

import {GET_USER_RELEVANCE_INFORMATION} from '@/api/users/queries';
import {DeepPick} from '@/types/generic.types';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {
    GetUserRelevanceInformationQuery,
    UpdateUserRelevanceInformationInput,
    UpdateUserRelevanceInformationMutation
} from '../types/__generated__/graphql';
import {UPDATE_USER_RELEVANCE_INFORMATION} from './mutations';

type UserRelevanceInformation = DeepPick<
    GetUserRelevanceInformationQuery,
    'user.userRelevanceInformation'
>;

const updateCache = (
    cache: ApolloCache<UpdateUserRelevanceInformationMutation>,
    response: FetchResult<UpdateUserRelevanceInformationMutation>
) => {
    const {ok, userRelevanceInformation} = response.data?.updateUserRelevanceInformation || {};

    if (!ok || !isDefined(userRelevanceInformation)) {
        return;
    }

    const {userId} = userRelevanceInformation;
    const cachedData = cache.readQuery<GetUserRelevanceInformationQuery>({
        query: GET_USER_RELEVANCE_INFORMATION,
        variables: {userId}
    });

    if (!isDefined(cachedData)) {
        return;
    }

    const updatedUser = toUpdatedUser(cachedData.user, userRelevanceInformation);

    cache.writeQuery({
        query: GET_USER_RELEVANCE_INFORMATION,
        variables: {userId},
        data: {
            __typename: 'Query',
            user: updatedUser
        }
    });
};

const toUpdatedUser = (
    cachedData: GetUserRelevanceInformationQuery['user'],
    userRelevanceInformation: UserRelevanceInformation
) => {
    return {
        __typename: 'User' as const,
        id: userRelevanceInformation.userId,
        ...cachedData,
        userRelevanceInformation: {
            ...userRelevanceInformation
        }
    };
};

export function useUpdateUserRelevanceInformation(): MutationHookReturnType<
    UpdateUserRelevanceInformationMutation,
    UpdateUserRelevanceInformationInput
> {
    const [mutate, {error, loading}] = useMutation(UPDATE_USER_RELEVANCE_INFORMATION, {
        update: updateCache
    });

    const updateUserRelevanceInformation = useCallback(
        data => mutate({variables: {data}}),
        [mutate]
    );
    return [updateUserRelevanceInformation, {error, loading}];
}
