import styled from 'styled-components';

import {
    LabelLineProps,
    LabelWrapperProps,
    LabelsWrapperProps,
    SvgWrapperProps
} from '@/componentLibrary/components/charts/StandardTenChartRange/types';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {OtherCaption} from '@/componentLibrary/tokens/typography';
import {
    BorderRadiusBaseXsmall,
    BorderWidthBase,
    ColorBaseGrey600,
    SpacingXsmall,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;
export const ScoresWrapper = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    flex-basis: 0;
    width: 100%;
    height: calc(100% - 15px);
    flex-direction: row;
    text-align: center;

    & > * {
        width: 100%;
    }
`;
export const SvgWrapper = styled.div<SvgWrapperProps>`
    width: ${props => props.$width}px;
    height: ${props => props.$height}px;
`;
export const BadgeWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 100%;
`;
export const LabelsWrapper = styled(FlexLayout)<LabelsWrapperProps>`
    width: ${props => props.$width}px;
    height: ${SpacingXsmall};
`;
export const LabelWrapper = styled.span<LabelWrapperProps>`
    ${OtherCaption};
    display: flex;
    width: ${p => p.$blockCount * p.$blockWidth + (p.$blockCount - 1) * p.$blockPadding}px;
    margin-right: ${props => props.$blockPadding}px;
    color: ${ColorBaseGrey600};
    font-size: ${SpacingXxsmall};
    text-align: center;
`;
export const PercentageText = styled.span`
    padding: 0 ${BorderRadiusBaseXsmall};
`;
export const LabelLine = styled.div<LabelLineProps>`
    height: ${BorderWidthBase};
    width: 100%;
    margin: auto;
    background-color: ${ColorBaseGrey600};
    visibility: ${props => (props.$show ? 'visible' : 'hidden')};
`;
