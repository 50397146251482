import styled from 'styled-components';

import {
    BorderRadiusBaseMedium,
    ColorBgSubtle,
    SpacingMedium,
    SpacingXxsmall
} from '@/componentLibrary/tokens/variables';

export const SeparatorWrapper = styled.div`
    height: ${SpacingMedium};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: ${SpacingXxsmall};
    background-color: ${ColorBgSubtle};
    border-radius: ${BorderRadiusBaseMedium} ${BorderRadiusBaseMedium} 0 0;
    padding: ${SpacingXxsmall};
    overflow: auto;
    cursor: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: sticky;
    top: 0;
    z-index: 1;
    min-height: 38px;


    ::-webkit-scrollbar {
        display: none;
    }
`;
