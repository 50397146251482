import {SsoProvider} from '@/services/auth/sso/constants';

const LOGOUT_USER_REQUEST = 'app/services/auth/LOGOUT_USER_REQUEST';

const CANDIDATE_PERMISSIONS = {
    // CANDIDATE MANAGEMENT
    MANAGE_CANDIDATE_SETTINGS: 'MANAGE_CANDIDATE_SETTINGS',
    MANAGE_CANDIDATE_ROLE_FIT_RESULTS: 'MANAGE_CANDIDATE_ROLE_FIT_RESULTS',
    MANAGE_CANDIDATE_CODING_TEST: 'MANAGE_CANDIDATE_CODING_TEST',
    MANAGE_CANDIDATE_INTERVIEWS: 'MANAGE_CANDIDATE_INTERVIEWS',
    MANAGE_CANDIDATE_PSYCHOMETRICS_RESULT: 'MANAGE_CANDIDATE_PSYCHOMETRICS_RESULT'
} as const;

const PERMISSIONS: {[key: string]: string} = {
    // GENERAL
    VIEW_TEAMS_IN_ORGANIZATION: 'VIEW_TEAMS_IN_ORGANIZATION',
    VIEW_OWN_TEAMS: 'VIEW_OWN_TEAMS',
    MANAGE_ORGANIZATION_MEMBERS: 'MANAGE_ORGANIZATION_MEMBERS',
    MANAGE_ORGANIZATION_CONFIGURATION: 'MANAGE_ORGANIZATION_CONFIGURATION',
    MANAGE_ORGANIZATION_ACCOUNT: 'MANAGE_ORGANIZATION_ACCOUNT',
    VIEW_ORGANIZATION_ANALYTICS: 'VIEW_ORGANIZATION_ANALYTICS',
    // ASSESSMENT
    CREATE_JOB_POSITIONS: 'CREATE_JOB_POSITIONS',
    MANAGE_JOB_POSITIONS_FOR_ORGANIZATION: 'MANAGE_JOB_POSITIONS_FOR_ORGANIZATION',
    MANAGE_JOB_POSITIONS_FOR_TEAM_WHERE_MEMBER: 'MANAGE_JOB_POSITIONS_FOR_TEAM_WHERE_MEMBER',
    MANAGE_JOB_POSITIONS_WHEN_INVOLVED_IN_POSITION:
        'MANAGE_JOB_POSITIONS_WHEN_INVOLVED_IN_POSITION',
    MANAGE_TEST_PROFILES: 'MANAGE_TEST_PROFILES',
    // TEAM INSIGHTS
    VIEW_PERSONALITY_TEST_RESULTS_AS_TEAM_ADMINISTRATOR:
        'VIEW_PERSONALITY_TEST_RESULTS_AS_TEAM_ADMINISTRATOR',
    VIEW_PERSONALITY_TEST_RESULTS_FOR_ORGANIZATION:
        'VIEW_PERSONALITY_TEST_RESULTS_FOR_ORGANIZATION',
    VIEW_PERSONALITY_TEST_RESULTS_FOR_OWN_TEAMS: 'VIEW_PERSONALITY_TEST_RESULTS_FOR_OWN_TEAMS',
    VIEW_LOGIC_TEST_RESULTS_FOR_ORGANIZATION: 'VIEW_LOGIC_TEST_RESULTS_FOR_ORGANIZATION',
    VIEW_LOGIC_TEST_RESULTS_FOR_OWN_TEAMS: 'VIEW_LOGIC_TEST_RESULTS_FOR_OWN_TEAMS',
    VIEW_LOGIC_TEST_RESULTS_AS_TEAM_ADMINISTRATOR:
        'VIEW_LOGIC_TEST_RESULTS_AS_TEAM_ADMINISTRATOR',
    VIEW_TEAM_INSIGHTS_FOR_ORGANIZATION: 'VIEW_TEAM_INSIGHTS_FOR_ORGANIZATION',
    VIEW_TEAM_INSIGHTS_FOR_TEAM_WHERE_MEMBER: 'VIEW_TEAM_INSIGHTS_FOR_TEAM_WHERE_MEMBER',
    ...CANDIDATE_PERMISSIONS
};

const DEFAULT_REDIRECT = '/login';

const ALVA_TOKEN_KEY = 'alva:token';

const ALVA_SIGNUP_KEY = 'alva:signup';
const ALVA_SELECTED_ORGANIZATION_KEY = 'alva:selected_organization';

const ALVA_CANDIDATE_ORGANIZATION_KEY = 'alva:candidate_organization';
const ALVA_IMPERSONATION_TOKEN_KEY = 'alva:impersonation_token';
const ALVA_EXPERIMENTAL_FEATURES_KEY = 'alva:experimental_features';
const AuthenticationMethod = {...{EMAIL_PASSWORD: 'EMAIL_PASSWORD'}, ...SsoProvider};

export {
    LOGOUT_USER_REQUEST,
    PERMISSIONS,
    CANDIDATE_PERMISSIONS,
    DEFAULT_REDIRECT,
    ALVA_TOKEN_KEY,
    ALVA_SIGNUP_KEY,
    ALVA_SELECTED_ORGANIZATION_KEY,
    ALVA_CANDIDATE_ORGANIZATION_KEY,
    ALVA_IMPERSONATION_TOKEN_KEY,
    ALVA_EXPERIMENTAL_FEATURES_KEY,
    AuthenticationMethod
};
