import {userLinks} from '@/pages/User/links';
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePollOnSuccessfulLinkedinSync} from '../../hooks/usePollOnSuccessfulLinkedinSync';

export function useSuccessPageLogic(organizationId: number) {
    usePollOnSuccessfulLinkedinSync();
    const navigate = useNavigate();
    return useCallback(() => {
        const url = userLinks.jobApplication(organizationId);
        navigate(url);
    }, [organizationId, navigate]);
}
