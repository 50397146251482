import {SpacingLarge} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    max-width: 640px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${SpacingLarge};
    align-self: stretch;
`;
