import {BodyP1} from '@/componentLibrary/tokens/typography';
import {SpacingLarge, SpacingSmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 35%;
    max-width: 520px;
    min-width: 420px;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: flex-start;
    flex-direction: column;

    @media only screen and (max-width: 760px) {
        width: 100%;
        padding: 36px ${SpacingSmall};
        min-width: 0;
    }

    @media only screen and (min-width: 761px) and (max-width: 800px) {
        width: 100%;
        padding: 36px 80px;
        min-width: 0;
    }
`;

export const DescriptionWrapper = styled.div`
    ${BodyP1};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingLarge};
`;
