import styled, {css} from 'styled-components';

import {
    ColorBgDefault,
    ColorBgSubtle,
    FontSizeSmall,
    ShadowElevationMedium,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

import {DraggableItemWrapperProps} from './types';

export const DraggableItemWrapper = styled.div<DraggableItemWrapperProps>`
    margin-top: -1px;
    font-size: ${FontSizeSmall};
    background-color: ${ColorBgDefault};
    margin-bottom: ${SpacingXsmall};

    ${({$isDragging}) =>
        $isDragging &&
        css`
            background-color: ${ColorBgSubtle};
            box-shadow: ${ShadowElevationMedium};
            left: auto !important;
        `}
`;
