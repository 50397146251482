import {v4 as uuid} from 'uuid';

import {UserCellUserShape} from '@/componentLibrary/components/Table/components/UserCell/types';
import {decodeNodeId} from '@/utils/encoders';

export type User = {
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
};

const parseValue = (value: string | number) => {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    if (value === 'None' || value === '') {
        return null;
    }

    if (Number(value)) {
        return Number(value);
    }

    return value;
};

export function formToJSON(form: HTMLFormElement): object | null {
    // Convert a form to json
    // Only include fields with explicit 'name'-property!

    if (!form) {
        // Todo: Maybe throw error?
        return null;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const json: {[key: string]: any} = {};

    // TODO: Add support for more types (radio buttons etc)
    const inputElements = form.querySelectorAll('input,textarea,select,button');

    let n = 0,
        element;
    const nn = inputElements.length;

    for (n; n < nn; n++) {
        element = inputElements[n] as HTMLInputElement;
        // Check type
        if (element.name && element.name !== '') {
            if (element.type === 'checkbox') {
                json[element.name] = !!element.checked; // Force true or false
            } else {
                json[element.name] = parseValue(element.value);
            }
        }
    }

    return json;
}

export function isEmptyObject(obj: object) {
    if (!obj || Object.keys(obj).length === 0) {
        return true;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

type UserNameOptions = {
    fallbackOnEmail?: boolean;
    defaultIfEmptyUser?: string;
    guessFromEmail?: boolean;
    firstNameOnly?: boolean;
};

export const getUserName = (
    user?: Partial<UserCellUserShape> | null,
    {
        fallbackOnEmail = true,
        defaultIfEmptyUser = '',
        guessFromEmail = false,
        firstNameOnly = false
    }: UserNameOptions = {}
): string => {
    if (!user || isEmptyObject(user)) {
        return defaultIfEmptyUser || '';
    }

    if (user.firstName) {
        return `${user.firstName.trim()}${
            user.lastName && !firstNameOnly ? ' ' + user.lastName.trim() : ''
        }`;
    }

    if (user.lastName) {
        return user.lastName.trim();
    }

    if (fallbackOnEmail && user.email) {
        return user.email;
    }

    if (guessFromEmail === true && user.email) {
        return capitalizeFirstLetter(user.email.split('@')[0]);
    }

    if (user?.id) {
        return String(user.id);
    }

    return defaultIfEmptyUser || '';
};

export const getUserInitials = (user: User) => {
    if (!user || isEmptyObject(user) || !user.firstName || !user.lastName) {
        return '';
    }

    return `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
};

export function capitalizeFirstLetter(str: string) {
    return `${str[0].toLocaleUpperCase()}${str.slice(1).toLocaleLowerCase()}`;
}

export const roundValue = (value: number, decimals = 0) =>
    Math.round(Math.pow(10, decimals) * value) / Math.pow(10, decimals);

function scaleValue(value: number, from: number[], to: number[]) {
    // Stolen from https://gist.github.com/fpillet/993002
    const scale = (to[1] - to[0]) / (from[1] - from[0]);
    const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
    return capped * scale + to[0];
}

export const onBaseFive = (value: number) => {
    let scaledValue: string | number = scaleValue(value, [0, 1], [1, 5]);
    const valueWithTwoDecimals = scaledValue.toFixed(2).toString();
    // Basically we want one decimal if even or if only one decimal is relevant, otherwise two
    scaledValue =
        valueWithTwoDecimals.charAt(valueWithTwoDecimals.length - 1) === '0'
            ? parseFloat(valueWithTwoDecimals).toFixed(1)
            : valueWithTwoDecimals;

    return scaledValue;
};

export const capValue = (value: number, minValue: number, maxValue: number) =>
    Math.max(Math.min(value, maxValue), minValue);

export const capAndRoundStenValue = (value: number, minimumValue = 1, maximumValue = 10) => {
    if (value < minimumValue) {
        return minimumValue;
    }

    if (value > maximumValue) {
        return maximumValue;
    }

    return Math.round(value);
};

const daysBetweenDates = (first: Date, second: Date) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.getTime() - first.getTime()) / (1000 * 60 * 60 * 24));
};

export const daysFromToday = (dateString: string) => {
    const first = new Date(dateString);
    const second = new Date();

    return daysBetweenDates(first, second);
};

export const generateId = () => uuid();

export const randomString = (length = 8) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let result = '';

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export function parseGlobalId(inputId: string | undefined): number | null {
    if (!inputId) {
        return null;
    }
    const idAsInt = window.parseInt(inputId, 10);

    if (window.isNaN(idAsInt)) {
        return decodeNodeId(inputId);
    }
    return idAsInt;
}

export function uniqueArray<T>(array: T[], getKey: (item: T) => string): T[] {
    if (!Array.isArray(array)) {
        throw new Error('Given data argument is not an array');
    }

    if (typeof getKey !== 'function') {
        throw new Error('getKey argument is not a function');
    }

    const seen: {[key: string]: boolean} = {};
    return array.filter(item => {
        const key = getKey(item);

        return Object.prototype.hasOwnProperty.call(seen, key) ? false : (seen[key] = true);
    });
}

export function isEnumValue<T extends Record<string, string | number>>(
    value: unknown,
    enumObject: T
): value is T[keyof T] {
    return Object.values(enumObject).includes(value as T[keyof T]);
}

export enum Status {
    Enabled,
    Disabled,
    Unknown
}

export const toInt = (tokenString: string) => parseInt(tokenString, 10);

export const isNotNull = <T>(value: T | null): value is T => value !== null;
export const isNotUndefined = <T>(value: T | undefined): value is T => value !== undefined;

export function excerpt(text: string, maxLength: number = 40) {
    if (text.length <= maxLength) {
        return text;
    }

    return `${text.substring(0, maxLength - 3)}...`;
}

export function getRepositoryUrlWithBranch(url: string, branch?: string): string {
    if (!branch) {
        return url;
    }
    return url.includes('/tree/') ? url : `${url}/tree/${branch}`;
}

export function convertIntToUuid(int: number) {
    const hex = int.toString(16).padStart(32, '0');

    return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
}
