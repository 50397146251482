import {Row, flexRender} from '@tanstack/react-table';

import {StyledExtendedTr, StyledTd} from './styled';
import {TableBodyProps} from './types';

export function TableBody<T>({table, loading, rowHover, onRowClick}: TableBodyProps<T>) {
    const getHasRowClick = (row: Row<T>) =>
        !!onRowClick &&
        (table.options.meta?.getCanClickRow === undefined
            ? true
            : table.options.meta?.getCanClickRow(row));

    return (
        <tbody>
            {table.getRowModel().rows.map(row => (
                <StyledExtendedTr
                    key={row.id}
                    $rowHover={!loading && (rowHover || getHasRowClick(row))}
                    onClick={
                        !loading && getHasRowClick(row)
                            ? e => onRowClick?.(row.original, e.ctrlKey || e.metaKey)
                            : undefined
                    }
                >
                    {row.getVisibleCells().map(cell => (
                        <StyledTd
                            key={cell.id}
                            $width={cell.column.columnDef.meta?.width}
                            $height={table.options.meta?.rowHeight}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </StyledTd>
                    ))}
                </StyledExtendedTr>
            ))}
        </tbody>
    );
}
