import {Tooltip} from '@/componentLibrary/components/Tooltips/Tooltip';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {S1} from '@/componentLibrary/components/typography';
import {ConditionalWrapper} from '@/componentLibrary/misc/ConditionalWrapper';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useMemo, useRef} from 'react';
import {Accordion} from './components/Accordion';
import {CardTypes} from './constants';
import {useInteractableCardLogic} from './logic';
import {
    BorderStripe,
    Container,
    HeaderWrapper,
    LeadingContentWrapper,
    TrailingContent
} from './styled';
import {Props} from './types';

export const InteractableCard = (props: Props) => {
    const {
        cardType,
        iconTooltip,
        onSelect,
        title,
        customLeadingContent,
        children,
        dismissActionButton,
        accordion = false,
        accordionDefaultExpanded = false,
        accordionExpanded,
        onAccordionToggle
    } = props;
    const {color, hasHoverableIcon, icon} = useInteractableCardLogic(props);

    const leadingContent = useCallback(() => {
        if (customLeadingContent) {
            return customLeadingContent;
        }

        if (cardType === CardTypes.NO_ICON) {
            return null;
        }

        return (
            <ConditionalWrapper
                wrapper={children => (
                    <Tooltip
                        content={<div>{iconTooltip}</div>}
                        placement="left"
                        trigger={children}
                    />
                )}
                condition={hasHoverableIcon && !!iconTooltip}
            >
                <Icon
                    icon={icon}
                    fill={color}
                    hoverFill={color}
                    onClick={onSelect}
                    isHoverable
                />
            </ConditionalWrapper>
        );
    }, [customLeadingContent, cardType, color, hasHoverableIcon, icon, iconTooltip, onSelect]);

    const contentRef = useRef<HTMLDivElement>(null);

    const content = useMemo(() => {
        if (isDefined(children) && !accordion) {
            return children;
        }

        return <div ref={contentRef}>{children}</div>;
    }, [accordion, children]);

    return (
        <Container>
            <HeaderWrapper>
                <LeadingContentWrapper>
                    <BorderStripe $color={color} />
                    {leadingContent()}
                </LeadingContentWrapper>
                <S1 style={{userSelect: 'none'}}>{title}</S1>
                <TrailingContent>
                    {dismissActionButton}
                    {accordion && (
                        <Accordion
                            contentRef={contentRef}
                            defaultExpanded={accordionDefaultExpanded}
                            expanded={accordionExpanded}
                            onToggle={onAccordionToggle}
                        />
                    )}
                </TrailingContent>
            </HeaderWrapper>
            {content}
        </Container>
    );
};
