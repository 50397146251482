import {useTriggerLinkedinUserDataSync} from '@/api/jobRecommendations/useTriggerLinkedinUserDataSync';
import {useLoggedInUser} from '@/api/users/useLoggedInUser';
import {redirectTo} from '@/utils/redirectTo';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useCallback, useEffect} from 'react';
import {DEFAULT_REDIRECT, LINKEDIN_SYNC_STATUS} from './constants';
import {useAuthCode, useError, useStateParam} from './useParams';
import {toStatus, toWithSyncStatus} from './utils';

export const useTriggerSyncData = () => {
    const {user} = useLoggedInUser();
    const [triggerLinkedinUserDataSync] = useTriggerLinkedinUserDataSync(user?.id);
    const authCode = useAuthCode();
    const redirectToState = useStateParam();
    const error = useError();

    const doSync = useCallback(async () => {
        const redirect = redirectToState ?? DEFAULT_REDIRECT;

        if (isDefined(error)) {
            const status = toStatus(error);
            redirectTo(toWithSyncStatus(redirect, status));
            return;
        }

        if (!isDefined(authCode)) {
            redirectTo(toWithSyncStatus(redirect, LINKEDIN_SYNC_STATUS.FAILED));
            return;
        }

        const {data} = await triggerLinkedinUserDataSync({authCode});
        const mutationResponse = data?.triggerLinkedinUserDataSync;
        const {ok} = mutationResponse || {};
        if (!ok || !isDefined(mutationResponse)) {
            redirectTo(toWithSyncStatus(redirect, LINKEDIN_SYNC_STATUS.FAILED));
            return;
        }

        redirectTo(toWithSyncStatus(redirect, LINKEDIN_SYNC_STATUS.SUCCESS));
    }, [triggerLinkedinUserDataSync, authCode, redirectToState, error]);

    useEffect(() => {
        isDefined(user) && doSync();
    }, [user, doSync]);
};
