import {hexToRGBA} from '@/utils/hexToRGBA';

export const greyColorTokens = {
    white000: (opacity = 1) => hexToRGBA('#FFFFFF', opacity),
    white100: (opacity = 1) => hexToRGBA('#F4F4F4', opacity),
    grey100: (opacity = 1) => hexToRGBA('#535353', opacity),
    grey200: (opacity = 1) => hexToRGBA('#737373', opacity),
    grey300: (opacity = 1) => hexToRGBA('#898989', opacity),
    grey400: (opacity = 1) => hexToRGBA('#9E9E9E', opacity),
    grey500: (opacity = 1) => hexToRGBA('#B4B4B4', opacity),
    grey600: (opacity = 1) => hexToRGBA('#C4C4C4', opacity),
    grey800: (opacity = 1) => hexToRGBA('#DFDFDF', opacity),
    grey900: (opacity = 1) => hexToRGBA('#F0F0F0', opacity)
};

export const secondaryColorTokensHex = {
    brown000: '#8F5D4D',
    blue000: '#3B4157',
    purple000: '#554F5B',
    warmGrey000: '#7F7470',
    green000: '#506966'
};

export const secondaryColorTokens = {
    brown000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.brown000, opacity),
    blue000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.blue000, opacity),
    green000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.green000, opacity),
    purple000: (opacity = 1) => hexToRGBA(secondaryColorTokensHex.purple000, opacity)
};
