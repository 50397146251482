import {
    SpacingMedium,
    SpacingSmall,
    SpacingXxlarge
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';

export const ContentWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.small}) {
        padding: ${SpacingMedium};
    }
`;

export const LeftColumn = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: ${SpacingXxlarge};

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        padding: ${SpacingSmall};
    }
`;

export const RightColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;

    @media only screen and (max-width: ${deviceBreakPointTokens.DESKTOP.min}) {
        width: 340px;
    }

    @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
        display: none;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${SpacingSmall};
    padding: ${SpacingSmall} ${SpacingMedium};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;
