import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'app.readinessDataCollectionCTA.title'
    },
    description: {
        id: 'app.readinessDataCollectionCTA.description'
    }
});
