import {Route, Routes, useParams} from 'react-router-dom';

import {urlRelative} from '@/utils/url';
import {NotFound} from '../NotFound';
import {PERSONALITY_DATA_COLLECTION_BASE_URL, personalityDataCollectionLinks} from './links';
import {CompletedDataCollectionTest} from './pages/CompletedDataCollectionTest/index';
import {DataCollectionForm} from './pages/DataCollectionForm';

export function PersonalityDataCollection() {
    return (
        <Routes>
            <Route
                path={urlRelative(
                    personalityDataCollectionLinks.form(':localeName'),
                    PERSONALITY_DATA_COLLECTION_BASE_URL
                )}
                element={<DataCollectionForm />}
            />
            <Route
                path={urlRelative(
                    personalityDataCollectionLinks.completed(':personalityTestId'),
                    PERSONALITY_DATA_COLLECTION_BASE_URL
                )}
                element={<CompletedDataCollectionTestWrapper />}
            />
            <Route path={'*'} element={<NotFound />} />
        </Routes>
    );
}

function CompletedDataCollectionTestWrapper() {
    const {personalityTestId} = useParams();

    if (!personalityTestId) {
        return <NotFound />;
    }

    return <CompletedDataCollectionTest personalityTestId={personalityTestId} />;
}
