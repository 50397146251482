import {ChangeEvent, MouseEvent, useCallback, useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {PlanKey} from '@/api/organizations/types';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {PageCenteredSpinner} from '@/componentLibrary/components/spinners/PageCenteredSpinner';
import {S2} from '@/componentLibrary/components/typography';
import {SpacingXxsmall} from '@/componentLibrary/tokens/variables';
import {LINKS} from '@/links';
import '@/pages/SignupV2/recaptcha.css';
import {PaymentMethodSelector} from '@/pages/UpgradePlan/components/PaymentMethodSelector';
import {SendingToPaymentProvider} from '@/pages/UpgradePlan/components/SendingToPaymentProvider';
import {UpgradeHeader} from '@/pages/UpgradePlan/components/UpgradeHeader';
import {useUpgradePlan} from '@/pages/UpgradePlan/components/UpgradePlan/useUpgradePlanLogic';
import {TrackPageView} from '@/services/segment';
import {PAGE_CATEGORIES} from '@/services/segment/constants';

import messages from './messages';
import {
    CenteredPageWrap,
    Footer,
    Main,
    PageWrap,
    PlanBox,
    PositionsWrap,
    WhiteBox
} from './styled';

export function UpgradePlan() {
    const intl = useIntl();
    const {
        user,
        planKey,
        checkoutUrl,
        returnUrl,
        fetchCheckoutSession,
        error,
        orgNotSelected,
        unauthorized,
        loading,
        errorGeneratingLink,
        doRequestUpgrade,
        errorRequesting,
        loadingRequest,
        upgradeRequested
    } = useUpgradePlan();
    const [numPositions, setNumPositions] = useState(1);
    const [valid, setValid] = useState(true);

    useEffect(() => {
        if (!error && !loading && !user && planKey) {
            window.location.href = LINKS.login + '?redirect=' + location.pathname;
        }
    }, [error, loading, planKey, user]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const result = urlParams.get('result');
        if (orgNotSelected) {
            console.log('orgNotSelected');
        } else if (result) {
            if (result === 'success') {
                sessionStorage.setItem('PLAN_UPGRADE_RESULT', 'paygo');
            }
            window.location.href = returnUrl;
        } else if (checkoutUrl) {
            window.location.href = checkoutUrl;
        } else if (planKey === PlanKey.PAYGOv2 && !loading && !error) {
            fetchCheckoutSession();
        }
    }, [
        checkoutUrl,
        error,
        fetchCheckoutSession,
        loading,
        orgNotSelected,
        planKey,
        returnUrl
    ]);

    useEffect(() => {
        if (upgradeRequested) {
            sessionStorage.setItem('PLAN_UPGRADE_RESULT', planKey);
            window.location.href = returnUrl;
        }
    }, [planKey, returnUrl, upgradeRequested]);

    const handleSubmit = () => {
        if (isNaN(numPositions) || numPositions < 1) {
            setValid(false);
            return;
        }
        doRequestUpgrade(numPositions);
    };

    const handleCancel = () => {
        window.location.href = returnUrl;
    };

    const handleChangePlan = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        window.location.replace(LINKS.upgradePlanHubspotPage);
    };

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValid(true);
        const {value} = e.currentTarget;
        const num = parseInt(value, 10);
        setNumPositions(num);
    };

    const getErrorBanner = useCallback((message, button = null) => {
        return (
            <CenteredPageWrap>
                <Banner type={BannerType.INFO} noFullwidth>
                    <FormattedMessage {...message} />
                    {button}
                </Banner>
            </CenteredPageWrap>
        );
    }, []);

    if (!planKey) {
        return getErrorBanner(messages.unknownPlanError);
    }

    if (unauthorized) {
        return getErrorBanner(messages.unauthorizedError);
    }

    if (error || errorGeneratingLink) {
        return getErrorBanner(messages.genericError);
    }

    if (loading || !user) {
        return <PageCenteredSpinner />;
    }

    if (orgNotSelected) {
        const goHome = (
            <SecondaryButton onClick={() => (window.location.href = LINKS.login)}>
                <FormattedMessage {...messages.selectOrg} />
            </SecondaryButton>
        );
        return getErrorBanner(messages.orgNotSelectedError, goHome);
    }

    if (planKey === PlanKey.PAYGOv2) {
        return (
            <CenteredPageWrap>
                <SendingToPaymentProvider />
            </CenteredPageWrap>
        );
    }

    return (
        <TrackPageView name={'Upgrade to ' + planKey} category={PAGE_CATEGORIES.UPGRADE_PLAN}>
            <PageWrap>
                <Main>
                    <UpgradeHeader email={user.email ?? ''} />
                    <WhiteBox>
                        <form
                            id="upgrade-plan-form"
                            onSubmit={handleSubmit}
                            style={{width: '100%'}}
                        >
                            <S2 style={{paddingBottom: SpacingXxsmall}}>
                                <FormattedMessage {...messages.selectedPlan} />
                            </S2>
                            <PlanBox>
                                <FormattedMessage {...messages[planKey]} />
                                <PlainButton
                                    data-testid="change-plan-button"
                                    onClick={handleChangePlan}
                                >
                                    <FormattedMessage {...messages.changePlan} />
                                </PlainButton>
                            </PlanBox>
                            <PositionsWrap>
                                <FormField>
                                    <TextField
                                        type="number"
                                        min={1}
                                        isRequired
                                        $fullWidth
                                        name="numPositions"
                                        value={numPositions}
                                        $isClearable
                                        onChange={handleFieldChange}
                                        errorMessage={intl.formatMessage(
                                            messages.numPositionsError
                                        )}
                                        hasError={!valid}
                                        label={
                                            <FormattedMessage
                                                {...messages.numPositionsTitle}
                                            />
                                        }
                                        placeholder={' '}
                                        helpText={
                                            <FormattedMessage {...messages.numPositionsDesc} />
                                        }
                                    />
                                </FormField>
                            </PositionsWrap>
                            <PaymentMethodSelector />
                        </form>
                    </WhiteBox>
                    {errorRequesting && (
                        <Banner type={BannerType.ERROR}>
                            <FormattedMessage {...messages.genericError} />
                        </Banner>
                    )}
                    <Footer>
                        <SecondaryButton onClick={handleCancel}>
                            <FormattedMessage {...messages.cancelButton} />
                        </SecondaryButton>
                        <PrimaryButton
                            data-testid={'send-req-btn'}
                            onClick={handleSubmit}
                            isLoading={loadingRequest}
                        >
                            <FormattedMessage {...messages.sendRequestButton} />
                        </PrimaryButton>
                    </Footer>
                </Main>
            </PageWrap>
        </TrackPageView>
    );
}
