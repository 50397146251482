import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useUserWithRecommendations} from '@/api/users/useRecommendationsUser';
import {usePollUntilChange} from '@/hooks/usePollUntilChange';
import {LINKEDIN_SYNC_STATUS} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/constants';
import {useLinkedinSyncStatus} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/useParams';

import {useEffect} from 'react';

const INTERVAL = 750;

export const usePollOnSuccessfulLinkedinSync = () => {
    const linkedinSyncStatus = useLinkedinSyncStatus();
    const {
        user,
        startPolling: startPollingUserRecommendationsSettings,
        stopPolling: stopPollingUserRecommendationsSettings
    } = useUserWithRecommendations();

    const {
        userRelevanceInformation,
        startPolling: startPollingUserRelevanceInformation,
        stopPolling: stopPollingUserRelevanceInformation
    } = useGetRelevanceInformation(user?.id);

    const {startPollingUntilChange: pollForUserRecommendationsSettings} = usePollUntilChange({
        start: startPollingUserRecommendationsSettings,
        stop: stopPollingUserRecommendationsSettings,
        dataToChange: user,
        interval: INTERVAL
    });

    const {startPollingUntilChange: pollForUserRelevanceInformation} = usePollUntilChange({
        start: startPollingUserRelevanceInformation,
        stop: stopPollingUserRelevanceInformation,
        dataToChange: userRelevanceInformation,
        interval: INTERVAL
    });

    useEffect(() => {
        if (linkedinSyncStatus === LINKEDIN_SYNC_STATUS.SUCCESS) {
            pollForUserRecommendationsSettings();
            pollForUserRelevanceInformation();
        }
    }, [
        linkedinSyncStatus,
        pollForUserRecommendationsSettings,
        pollForUserRelevanceInformation
    ]);
};
