import {AuthenticationFormBanner} from 'pages/common/authentication/AuthenticationFormBanner';
import {AuthenticationFormHeader} from 'pages/common/authentication/AuthenticationFormHeader';
import {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {Separator} from '@/componentLibrary/components/Separator';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SsoButton} from '@/componentLibrary/components/buttons/SsoButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {Link} from '@/componentLibrary/components/links/Link';
import {P2} from '@/componentLibrary/components/typography';
import {
    AuthenticationErrorPlaceholder,
    AuthenticationFormWrapper
} from '@/pages/common/authentication/styled';
import {SsoError, SsoProvider} from '@/services/auth/sso/constants';
import {useGoToOktaLoginPage} from '@/services/auth/sso/okta/useGoToOktaLoginPage';
import {capitalizeFirstLetter} from '@/utils/misc';

import {RECOVER_PASSWORD_LINK, typedErrorMessages} from './constants';
import {useLoginFormLogic} from './logic';
import messages, {errorMessages} from './messages';
import {RecoverPasswordWrapper, SeparatorWrapper} from './styled';
import {Props} from './types';

export function LoginForm({login, loginResult, preFilledEmail, message}: Props) {
    const intl = useIntl();
    const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);
    const goToOktaLoginPage = useGoToOktaLoginPage();

    const {
        emailFieldRef,
        passwordFieldRef,
        handleSubmit,
        emailError,
        passwordError,
        userAlreadyActiveError,
        ssoLoading,
        loadingGoogleSso,
        loadingMicrosoftSso,
        loadingOktaSso,
        handleLoginWithSso,
        loginMethod
    } = useLoginFormLogic(login);

    const isSSOError = useMemo(() => {
        return message ? Object.keys(SsoError).includes(message) : false;
    }, [message]);

    // We have this linting disabled for next line and do not include `failedLoginAttempts` as a dependency otherwise
    // when entering wrong credentials we'll get an error about `Maximum update depth exceeded.`
    // @TODO: Test by replace useState with useRef
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            !loginResult ||
            !loginResult.data ||
            !loginResult.data.login ||
            loginResult.data.login.ok
        ) {
            return;
        }
        const {errorMessage} = loginResult.data.login;

        if (errorMessage === 'INVALID_CREDENTIALS') {
            setFailedLoginAttempts(failedLoginAttempts + 1);
        }
    }, [loginResult]);

    const errorMessageFromResult = useMemo(() => {
        if (!loginResult.called || loginResult.loading) {
            return null;
        }

        if (loginResult.error) {
            return intl.formatMessage(errorMessages.somethingWentWrong);
        }

        if (!loginResult?.data?.login?.ok) {
            const errorMessage = loginResult?.data?.login?.errorMessage;
            if (!errorMessage || !typedErrorMessages[errorMessage]) {
                return intl.formatMessage(errorMessages.UNKNOWN);
            }

            return intl.formatMessage(typedErrorMessages[errorMessage]);
        }
    }, [loginResult, intl]);

    const getDisplayedError = useCallback(() => {
        if (userAlreadyActiveError && message) {
            return <FormattedMessage {...typedErrorMessages[message]} />;
        }

        if (failedLoginAttempts >= 3) {
            return (
                <div>
                    <FormattedMessage {...errorMessages.havingTroubleLoggingIn} />
                    &nbsp;
                    <Link url={RECOVER_PASSWORD_LINK}>
                        <FormattedMessage {...errorMessages.clickToResetPassword} />
                    </Link>
                </div>
            );
        }

        if (message && loginMethod && !ssoLoading) {
            return typedErrorMessages[message] && loginMethod ? (
                <FormattedMessage
                    {...typedErrorMessages[message]}
                    values={{ssoProvider: capitalizeFirstLetter(loginMethod)}}
                />
            ) : (
                <FormattedMessage {...errorMessages.somethingWentWrong} />
            );
        }

        if (errorMessageFromResult) {
            return errorMessageFromResult;
        }

        return null;
    }, [
        userAlreadyActiveError,
        failedLoginAttempts,
        message,
        loginMethod,
        ssoLoading,
        errorMessageFromResult
    ]);

    const ErrorDisplay = () => {
        const errorMessage = getDisplayedError();
        if (!errorMessage) {
            return <AuthenticationErrorPlaceholder />;
        }

        return (
            <AuthenticationFormBanner
                type={userAlreadyActiveError ? BannerType.INFO : BannerType.ERROR}
                text={errorMessage}
            />
        );
    };

    return (
        <Fragment>
            <AuthenticationFormWrapper>
                <AuthenticationFormHeader title={intl.formatMessage(messages.title)} />
                {!isSSOError && <ErrorDisplay />}
                <form onSubmit={handleSubmit} style={{width: '100%'}}>
                    <FormField>
                        <TextField
                            hasError={!!emailError}
                            errorMessage={emailError}
                            data-testid="email"
                            $fullWidth
                            defaultValue={preFilledEmail}
                            placeholder={intl.formatMessage(messages.emailPlaceholder)}
                            ref={emailFieldRef}
                            label={<FormattedMessage {...messages.email} />}
                            autoComplete={'off'}
                        />
                    </FormField>
                    <FormField>
                        <TextField
                            hasError={!!passwordError}
                            errorMessage={passwordError}
                            data-testid="password"
                            type="password"
                            $fullWidth
                            placeholder={intl.formatMessage(messages.password)}
                            ref={passwordFieldRef}
                            label={<FormattedMessage {...messages.password} />}
                        />
                    </FormField>
                    <FormField>
                        <PrimaryButton
                            data-testid="button"
                            type="submit"
                            isLoading={loginResult.loading}
                            isFullWidth
                        >
                            <FormattedMessage {...messages.login} />
                        </PrimaryButton>
                    </FormField>
                </form>

                <RecoverPasswordWrapper>
                    <Link url={RECOVER_PASSWORD_LINK}>
                        <PlainButton>
                            <FormattedMessage {...messages.recover} />
                        </PlainButton>
                    </Link>
                </RecoverPasswordWrapper>

                <SeparatorWrapper>
                    <Separator>
                        <P2>
                            <FormattedMessage {...messages.or} />
                        </P2>
                    </Separator>
                </SeparatorWrapper>
                {isSSOError && <ErrorDisplay />}
                <FormField>
                    <SsoButton
                        isLoading={loadingGoogleSso}
                        ssoProvider={SsoProvider.GOOGLE}
                        onClick={handleLoginWithSso.bind(null, SsoProvider.GOOGLE)}
                    />
                </FormField>
                <FormField>
                    <SsoButton
                        isLoading={loadingMicrosoftSso}
                        ssoProvider={SsoProvider.MICROSOFT}
                        onClick={handleLoginWithSso.bind(null, SsoProvider.MICROSOFT)}
                    />
                </FormField>
                <FormField>
                    <SsoButton
                        isLoading={loadingOktaSso}
                        ssoProvider={SsoProvider.OKTA}
                        onClick={goToOktaLoginPage}
                    />
                </FormField>
            </AuthenticationFormWrapper>
        </Fragment>
    );
}
