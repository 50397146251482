import {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {FormattedMessage} from 'react-intl';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';

import messages from './messages';

type RecoverPasswordFormProps = {
    submit: (email: string) => void;
    loading: boolean;
};

export function RecoverPasswordForm({submit, loading}: RecoverPasswordFormProps) {
    const [email, setEmail] = useState('');

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setEmail(value);
    }, []);

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            if (e) {
                e.preventDefault();
            }

            if (!email) {
                return;
            }

            submit(email);
        },
        [email, submit]
    );

    return (
        <form onSubmit={handleSubmit}>
            <FormField>
                <TextField
                    type="email"
                    onChange={handleInputChange}
                    label={<FormattedMessage {...messages.email} />}
                    $fullWidth
                />
            </FormField>

            <FormField>
                <PrimaryButton
                    type="submit"
                    isLoading={loading}
                    disabled={!email || loading}
                    isFullWidth
                >
                    <FormattedMessage {...messages.submit} />
                </PrimaryButton>
            </FormField>
        </form>
    );
}
