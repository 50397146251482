import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Caption, Markdown, P2, S1, S2} from '@/componentLibrary/components/typography';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {LogicPercentile} from '../LogicPercentile';
import {usePhrases} from './hooks';
import {
    AdditionalInfoWrapper,
    ChartWrapper,
    DescriptionWrapper,
    LeftColumnWrapper,
    RightColumnWrapper,
    Wrapper
} from './styled';
import {ScoreOverviewProps} from './types';

export function ScoreOverview({
    standardScore,
    showAdminContent = false,
    showAdditionalInfo = false,
    shortReport = false
}: ScoreOverviewProps) {
    const phrases = usePhrases({standardScore, showAdminContent});

    if (shortReport) {
        return (
            <>
                <S2>{phrases.title}</S2>
                <LogicPercentile standardScore={standardScore} />
                <ChartWrapper>
                    <StandardTenChart showBadge selectedValue={standardScore} />
                </ChartWrapper>
            </>
        );
    }

    return (
        <Wrapper $showAdditionalInfo={showAdditionalInfo}>
            <LeftColumnWrapper>
                <S1>{phrases.title}</S1>
                <LogicPercentile standardScore={standardScore} />
                <DescriptionWrapper>{phrases.description}</DescriptionWrapper>
            </LeftColumnWrapper>
            <RightColumnWrapper>
                <StandardTenChart showBadge selectedValue={standardScore} />
            </RightColumnWrapper>
            {showAdditionalInfo && (
                <LeftColumnWrapper>
                    {!showAdminContent && (
                        <>
                            <P2>
                                <Markdown>{phrases.scoreDescription}</Markdown>
                            </P2>
                            <Caption $color={ColorFgSubtle}>
                                <Markdown>{phrases.disclaimer}</Markdown>
                            </Caption>
                        </>
                    )}
                    <AdditionalInfoWrapper $color={ColorFgSubtle}>
                        * {phrases.commonScore}
                    </AdditionalInfoWrapper>
                </LeftColumnWrapper>
            )}
        </Wrapper>
    );
}
