import {Separator} from '@/componentLibrary/components/Separator';
import {ProminentBanner} from '@/componentLibrary/components/banners/ProminentBanner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {P2} from '@/componentLibrary/components/typography';
import {SpacingXxlarge} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useQuery} from '@/hooks/useQuery';
import {useContext} from 'react';

import {SharedResultsContext} from '@/pages/SharedResults/components/UserResults/hooks/SharedResultsContext';
import {Modal} from '../../Modals/Modal';
import {MODAL_SIZE} from '../../Modals/Modal/constants';
import {LeadershipReportOverview} from '../PersonalityTest/LeadershipReportOverview';
import {
    FactorsOverviewWrapper,
    LeadershipDevelopmentPlanBannerWrapper
} from '../PersonalityTest/PersonalityTestReport/styled';
import {ReadMoreContent} from '../PersonalityTest/components/ReadMoreContent';
import {WithTestReportLocale} from '../WithTestReportLocale';
import {PrintHeader} from '../components/PrintHeader';
import {ReportHeader} from '../components/ReportHeader';
import {TestFooter} from '../components/TestFooter';
import {REPORT_TYPES} from '../constants';
import {ContentWrapperWithDefaultBg} from '../styled';
import {Anchors} from './components/Anchors';
import {ReportIntro} from './components/ReportIntro';
import {SectionOverview} from './components/SectionOverview';
import {SectionWrapper} from './components/SectionOverview/styled';
import {useLeadershipReportLogic} from './logic';
import messages from './messages';
import {ExtendedWrapper, OverviewWrapper, TranslationsMissingBanner} from './styled';
import {LeadershipReportProps} from './types';

export function LeadershipReport({
    close,
    leadershipProfile,
    user,
    personalityTestId,
    jobPositionId,
    organizationId
}: LeadershipReportProps) {
    const phrases = useExtractPhraseConstants(messages);
    const isSharedResultsView = useContext(SharedResultsContext);
    const queryParams = useQuery();

    const {
        handleAnchorClick,
        reportLocale,
        changeReportLocale,
        shouldShowTranslationsMissingBanner,
        leadershipDevelopmentPlanUrl
    } = useLeadershipReportLogic();

    const modalBack = queryParams.get('showLeadershipReport') ? () => window.close() : close;

    return (
        <WithTestReportLocale locale={reportLocale}>
            <Modal
                back={modalBack}
                size={MODAL_SIZE.FULL_PAGE}
                headerCustomRightContent={
                    <ReportHeader
                        reportType={REPORT_TYPES.LEADERSHIP_REPORT}
                        reportUserId={user.id}
                        reportLocale={reportLocale}
                        changeReportLocale={changeReportLocale}
                        printTitle={phrases.leadershipReportTitle}
                        user={user}
                        testId={personalityTestId}
                        jobPositionId={jobPositionId}
                        organizationId={organizationId}
                    />
                }
            >
                <ContentWrapperWithDefaultBg>
                    <ExtendedWrapper>
                        {shouldShowTranslationsMissingBanner && (
                            <TranslationsMissingBanner
                                title={phrases.translationsMissingTitle}
                            >
                                <P2>{phrases.translationsMissingDescription}</P2>
                            </TranslationsMissingBanner>
                        )}
                        <PrintHeader user={user} />
                        <ReportIntro user={user} />
                        <Anchors onClick={handleAnchorClick} />
                        <OverviewWrapper>
                            <LeadershipReportOverview
                                leadershipProfile={leadershipProfile}
                                forPrint
                            />
                        </OverviewWrapper>
                        <FactorsOverviewWrapper>
                            {leadershipProfile.sections.map((section, index) => (
                                <SectionWrapper id={section.name} key={section.name}>
                                    {index !== 0 && (
                                        <Separator padding={SpacingXxlarge} padded />
                                    )}
                                    <SectionOverview key={section.name} section={section} />
                                </SectionWrapper>
                            ))}
                        </FactorsOverviewWrapper>
                        {!isSharedResultsView && (
                            <LeadershipDevelopmentPlanBannerWrapper>
                                <Separator padding={SpacingXxlarge} padded />
                                <ProminentBanner
                                    type={BannerType.PLAIN}
                                    title={phrases.developmentPlanTitle}
                                    customAction={
                                        <form
                                            method="get"
                                            action={leadershipDevelopmentPlanUrl}
                                        >
                                            <PrimaryButton type="submit">
                                                {phrases.developmentPlanAction}
                                            </PrimaryButton>
                                        </form>
                                    }
                                >
                                    {phrases.developmentPlanDescription}
                                </ProminentBanner>
                            </LeadershipDevelopmentPlanBannerWrapper>
                        )}
                        <TestFooter>
                            <ReadMoreContent />
                        </TestFooter>
                    </ExtendedWrapper>
                </ContentWrapperWithDefaultBg>
            </Modal>
        </WithTestReportLocale>
    );
}
