import {ApolloError} from '@apollo/client';
import {FormattedMessage} from 'react-intl';

import {Icons} from '@/componentLibrary/components/icons/constants';
import {S2} from '@/componentLibrary/components/typography';

import {useErrorStateLogic} from './logic';
import messages from './messages';
import {
    ErrorDescription,
    ErrorIcon,
    InnerErrorWrapper,
    OuterErrorWrapper,
    RefreshButton
} from './styled';

type ErrorStateProps = {
    refetch?: () => void;
    error?: ApolloError | Error;
};

export const graphqlError = (error?: ApolloError) => <ErrorState error={error} />;

export function ErrorState({refetch, error}: ErrorStateProps) {
    const {reloadData} = useErrorStateLogic(refetch, error);

    return (
        <OuterErrorWrapper $justifyContent="center">
            <InnerErrorWrapper $direction="column" $alignItems="center">
                <ErrorIcon icon={Icons.ERROR_CIRCLE_ROUNDED} />
                <S2>
                    <FormattedMessage {...messages.title} />
                </S2>
                <ErrorDescription>
                    <FormattedMessage {...messages.descriptionOne} />
                </ErrorDescription>
                <ErrorDescription>
                    <FormattedMessage {...messages.descriptionTwo} />
                </ErrorDescription>
                {!!refetch && (
                    <RefreshButton onClick={reloadData} icon={Icons.AUTORENEW}>
                        <FormattedMessage {...messages.refresh} />
                    </RefreshButton>
                )}
            </InnerErrorWrapper>
        </OuterErrorWrapper>
    );
}
