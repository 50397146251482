import styled from 'styled-components';

import {TABLE_ROW_HEIGHT} from '@/componentLibrary/components/Table/constants';
import {OtherOverline} from '@/componentLibrary/tokens/typography';
import {ColorFgSubtle, SpacingXxsmall} from '@/componentLibrary/tokens/variables';

export const StyledTh = styled.th<{$height?: string}>`
    ${OtherOverline}
    color: ${ColorFgSubtle};
    height: ${({$height}) => $height ?? TABLE_ROW_HEIGHT.NORMAL};
    padding-inline: ${SpacingXxsmall};
    vertical-align: middle;

    ${({onClick}) => onClick && 'cursor: pointer;'}

    > div {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: ${SpacingXxsmall};
    }
`;
