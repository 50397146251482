import styled, {css} from 'styled-components';

import {
    BorderRadiusBaseMedium,
    ColorBgActionSecondaryDisabled,
    ColorBorderActionSecondaryDefault,
    ColorBorderActionSecondaryDisabled,
    ColorBorderFocus,
    ColorFgActionPrimarySecondaryDisabled,
    ColorFgError,
    SpacingSmall,
    SpacingXsmall
} from '@/componentLibrary/tokens/variables';

export const Wrapper = styled.div<{$fullWidth: boolean; $resizable: boolean}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: ${p => (p.$fullWidth ? '100%' : '280px')};

    ${({$resizable}) =>
        $resizable &&
        css`
            overflow: auto;
            resize: both;
        `};
`;

export const EditorWrapper = styled.div<{
    $focused: boolean;
    $error: boolean;
    $disabled: boolean;
    $minHeight?: number;
    $height?: number;
}>`
    display: flex;
    flex-direction: column;
    border-radius: ${BorderRadiusBaseMedium};
    border: 1px solid ${ColorBorderActionSecondaryDefault};
    width: 100%;
    cursor: text;
    ${({$minHeight}) => $minHeight && `min-height: ${$minHeight}px;`}
    ${({$height}) => ($height && `height: ${$height}px;`) ?? '100%'}
    overflow-y: scroll;

    ${({$focused}) =>
        $focused &&
        css`
            border-color: ${ColorBorderFocus};
        `}

    ${({$error}) =>
        $error &&
        css`
            border-color: ${ColorFgError};
        `}

    ${({$disabled}) =>
        $disabled &&
        css`
            pointer-events: none;
            cursor: not-allowed;
            color: ${ColorFgActionPrimarySecondaryDisabled};
            background-color: ${ColorBgActionSecondaryDisabled};
            border-color: ${ColorBorderActionSecondaryDisabled};
        `}
`;

const SharedCodeStyle = css`
    font-family: SFMono-Regular, Menlo, Consolas, 'PT Mono', 'Liberation Mono', Courier,
        monospace;
    background: rgb(135 131 120 / 15%);
    color: rgb(235 87 87);
    border-radius: 4px;
    font-size: 85%;
`;

export const ContentWrapper = styled.div<{$showContentOnly?: boolean}>`
    padding: ${SpacingSmall} ${SpacingXsmall};

    ${({$showContentOnly}) => !!$showContentOnly && 'padding: 0;'}

    .tiptap {
        u {
            text-decoration: underline;
        }

        ul, ol {
            margin-left: ${SpacingSmall};
            margin-bottom: ${SpacingSmall};
            list-style-type: disc;

            
            ul, ol {
                margin-bottom: 0;
                margin-top: 2px;
            }
        }

        ol {
            list-style-type: decimal;
        }

        code {
            ${SharedCodeStyle}
            padding: 0.2em 0.4em;
        }

        pre {
            ${SharedCodeStyle}
            padding: 0.5em 0.75em;

            > code {
                color: rgb(55 53 47);
                background: unset;
                padding: unset;
                font-size: unset;
            }
        }

        a {
            cursor: pointer;
        }
    }
`;
