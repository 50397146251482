import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {CheckBox} from '@/componentLibrary/components/controls/CheckBox';
import {TextFieldErrorMessage} from '@/componentLibrary/components/inputs/styled';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {ExternalLink} from '@/componentLibrary/components/links/ExternalLink';
import {Caption} from '@/componentLibrary/components/typography';
import {ColorBaseGrey700} from '@/componentLibrary/tokens/variables';
import {useConsentMessages} from '@/services/consent/useConsentMessages';

import {useConsentCheckboxChangeLogic} from './hooks';
import messages from './messages';
import {Props} from './types';

export function ConsentCheckbox({
    linkToFull,
    onChange,
    type,
    showRequiredText,
    customLabel,
    labelDescription
}: Props) {
    const {formatMessage} = useIntl();
    const consentMessages = useConsentMessages();

    const renderLink = useCallback(() => {
        return (
            <ExternalLink url={linkToFull}>
                {formatMessage(consentMessages[type].title)}
            </ExternalLink>
        );
    }, [formatMessage, linkToFull, type, consentMessages]);

    const renderRequiredText = useCallback(() => {
        if (showRequiredText) {
            return (
                <TextFieldErrorMessage>
                    {formatMessage(messages.errorText)}
                </TextFieldErrorMessage>
            );
        }

        return null;
    }, [showRequiredText, formatMessage]);

    const {handleChange} = useConsentCheckboxChangeLogic({type, onChange});
    const label = customLabel
        ? customLabel
        : formatMessage(consentMessages[type].shortDescription, {
              link: renderLink()
          });

    return (
        <FlexLayout $direction="column" $justifyContent="flex-start">
            <CheckBox
                label={
                    <>
                        {label}
                        <Caption $color={ColorBaseGrey700}>{labelDescription}</Caption>
                    </>
                }
                onChange={handleChange}
            />
            {renderRequiredText()}
        </FlexLayout>
    );
}
