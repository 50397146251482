import {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Caption, P2, S1} from '@/componentLibrary/components/typography';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {PERSONALITY_FACTOR_MESSAGES} from '../../constants';
import {personalityTestReportMessages} from '../../messages';
import {PersonalityPercentile} from '../PersonalityPercentile';
import {
    ChartWrapper,
    DescriptionWrapper,
    HeaderWrapper,
    LabelsWrapper,
    LowerLabelWrapper,
    ResultWrapper,
    StenChartWrapper,
    Wrapper
} from './styled';
import {DetailedFactorResultProps} from './types';

export function DetailedFactorResult({
    personalityTestResult,
    factor
}: DetailedFactorResultProps) {
    const intl = useIntl();

    const factorMessages = useMemo(() => {
        return PERSONALITY_FACTOR_MESSAGES[factor];
    }, [factor]);

    const scoreValue = personalityTestResult[factor.toLowerCase()];

    const labelLowerText = intl.formatMessage(factorMessages.labelLower);
    const labelUpperText = intl.formatMessage(factorMessages.labelUpper);

    const factorContentBasedOnResult = useMemo(() => {
        if (scoreValue <= 2) {
            return {
                title: intl.formatMessage(personalityTestReportMessages.highDeviation, {
                    label: labelLowerText
                }),
                description: intl.formatMessage(factorMessages.resultVeryLow)
            };
        }

        if (scoreValue <= 4) {
            return {
                title: labelLowerText,
                description: intl.formatMessage(factorMessages.resultLow)
            };
        }

        if (scoreValue <= 6) {
            return {
                title: intl.formatMessage(personalityTestReportMessages.inBetween, {
                    labelLower: labelLowerText,
                    labelUpper: labelUpperText
                }),
                description: intl.formatMessage(factorMessages.resultAverage)
            };
        }

        if (scoreValue <= 8) {
            return {
                title: labelUpperText,
                description: intl.formatMessage(factorMessages.resultHigh)
            };
        }

        return {
            title: intl.formatMessage(personalityTestReportMessages.highDeviation, {
                label: labelUpperText
            }),
            description: intl.formatMessage(factorMessages.resultVeryHigh)
        };
    }, [labelLowerText, labelUpperText, scoreValue, intl, factorMessages]);

    return (
        <Wrapper>
            <HeaderWrapper>
                <FormattedMessage {...factorMessages.name} />
            </HeaderWrapper>
            <ResultWrapper>
                <DescriptionWrapper>
                    <S1>{factorContentBasedOnResult.title}</S1>
                    <PersonalityPercentile scoreValue={scoreValue} />
                    <P2>{factorContentBasedOnResult.description}</P2>
                </DescriptionWrapper>
                <ChartWrapper>
                    <StenChartWrapper>
                        <StandardTenChart showBadge selectedValue={scoreValue} />
                    </StenChartWrapper>
                    <LabelsWrapper>
                        <div>
                            <P2 color={ColorFgMuted}>{labelLowerText}</P2>
                            <Caption $color={ColorFgSubtle}>
                                <FormattedMessage {...factorMessages.descriptionLower} />
                            </Caption>
                        </div>
                        <LowerLabelWrapper>
                            <P2 color={ColorFgMuted}>{labelUpperText}</P2>
                            <Caption $color={ColorFgSubtle}>
                                <FormattedMessage {...factorMessages.descriptionUpper} />
                            </Caption>
                        </LowerLabelWrapper>
                    </LabelsWrapper>
                </ChartWrapper>
            </ResultWrapper>
        </Wrapper>
    );
}
