import {deviceBreakPointTokens} from '@/componentLibrary/tokens/deviceBreakpoints';
import {SpacingSmall} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SpacingSmall};
    padding-top: ${SpacingSmall};
    padding-bottom: ${SpacingSmall};
`;

export const DatesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${SpacingSmall};

    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.min}) {
        flex-direction: column;
    }
`;
