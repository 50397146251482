import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {useAccordionLogic} from './logic';
import {Props} from './types';

export const Accordion = (props: Props) => {
    const {toggle, iconStyle} = useAccordionLogic(props);

    return (
        <Icon
            icon={Icons.KEYBOARD_ARROW_DOWN}
            onClick={toggle}
            isHoverable
            style={iconStyle}
        />
    );
};
