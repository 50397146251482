import styled, {css} from 'styled-components';

import {ButtonBase} from '@/componentLibrary/components/buttons/ButtonBase';
import {
    ColorBgActionDestructiveActive,
    ColorBgActionDestructiveDefault,
    ColorBgActionDestructiveDisabled,
    ColorBgActionDestructiveHover,
    ColorBorderActionDestructiveActive,
    ColorBorderActionDestructiveDefault,
    ColorBorderActionDestructiveDisabled,
    ColorBorderActionDestructiveHover,
    ColorFgActionDestructiveActive,
    ColorFgActionDestructiveDefault,
    ColorFgActionDestructiveDisabled,
    ColorFgActionDestructiveHover
} from '@/componentLibrary/tokens/variables';

import {ElementType, forwardRef} from 'react';
import {BUTTON_VARIANTS} from './constants';
import {
    AnchorButtonProps,
    ButtonBaseProps,
    ButtonProps,
    LinkButtonProps,
    NavLinkButtonProps
} from './types';

const destructiveButtonStyles = css<ButtonBaseProps>`
    background-color: ${ColorBgActionDestructiveDefault};
    border-color: ${ColorBorderActionDestructiveDefault};
    color: ${ColorFgActionDestructiveDefault};

    ${({isLoading: $isLoading}) =>
        $isLoading &&
        css`
            &::after {
                border-color: ${ColorFgActionDestructiveDefault};
                border-radius: 290486px;
                border-right-color: transparent;
                border-top-color: transparent;
            }
        `}

    &:hover {
        background-color: ${ColorBgActionDestructiveHover};
        border-color: ${ColorBorderActionDestructiveHover};
        color: ${ColorFgActionDestructiveHover};
    }

    &.active,
    &:active {
        background-color: ${ColorBgActionDestructiveActive};
        border-color: ${ColorBorderActionDestructiveActive};
        color: ${ColorFgActionDestructiveActive};
    }

    ${({isSelected: $isSelected}) =>
        $isSelected &&
        css`
            &,
            &:hover,
            &:active {
                background-color: ${ColorBgActionDestructiveActive};
                border-color: ${ColorBorderActionDestructiveActive};
                color: ${ColorFgActionDestructiveActive};
            }
        `};

    &:disabled {
        background-color: ${ColorBgActionDestructiveDisabled};
        border-color: ${ColorBorderActionDestructiveDisabled};
        color: ${ColorFgActionDestructiveDisabled};
    }

    &:focus:not(:focus-visible) {
        /* Remove focus indication when a mouse is used */
        outline: none;
    }

    &:focus-visible {
        outline: 2px solid ${ColorBorderActionDestructiveHover};
        outline-offset: 2px;
    }
`;

const StyledDestructiveButton = styled(ButtonBase)<ButtonBaseProps>` 
    ${destructiveButtonStyles}
`;

const {NAVLINK, LINK, ANCHOR} = BUTTON_VARIANTS;

export const DestructiveButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledDestructiveButton {...props} ref={ref} />
));

export const DestructiveNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledDestructiveButton {...props} ref={ref} variant={NAVLINK} />
);

export const DestructiveLinkButton = forwardRef<ElementType, LinkButtonProps>((props, ref) => (
    <StyledDestructiveButton {...props} ref={ref} variant={LINK} />
));

export const DestructiveAnchorButton = forwardRef<ElementType, AnchorButtonProps>(
    (props, ref) => <StyledDestructiveButton {...props} ref={ref} variant={ANCHOR} />
);

export const SmallDestructiveButton = forwardRef<ElementType, ButtonProps>((props, ref) => (
    <StyledDestructiveButton {...props} ref={ref} small />
));

export const SmallDestructiveNavLinkButton = forwardRef<ElementType, NavLinkButtonProps>(
    (props, ref) => <StyledDestructiveButton {...props} ref={ref} small variant={NAVLINK} />
);

export const SmallDestructiveLinkButton = forwardRef<ElementType, LinkButtonProps>(
    (props, ref) => <StyledDestructiveButton {...props} ref={ref} small variant={LINK} />
);

export const SmallDestructiveAnchorButton = forwardRef<ElementType, AnchorButtonProps>(
    (props, ref) => <StyledDestructiveButton {...props} ref={ref} small variant={ANCHOR} />
);
