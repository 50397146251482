import {useRelevanceParametersOptions} from '@/api/jobRecommendations/useRelevanceParametersOptions';
import {useGetRelevanceInformation} from '@/api/users/useGetRelevanceInformation';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {useCallback} from 'react';
import messages from './messages';
import {Props} from './types';

export function useDataCollectionNonEuLogic({userId, onSuccess}: Props) {
    const phrases = useExtractPhraseConstants(messages);
    const relevanceFormModalLogic = useSimpleModalLogic();
    const {relevanceParametersOptions} = useRelevanceParametersOptions();
    const {userRelevanceInformation} = useGetRelevanceInformation(userId);

    const onRelevanceFormSubmitSuccess = useCallback(() => {
        relevanceFormModalLogic.closeModal();
        onSuccess?.();
    }, [onSuccess, relevanceFormModalLogic]);

    return {
        phrases,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation,
        onRelevanceFormSubmitSuccess
    };
}
