import {defineMessages} from 'react-intl';

import {LINKEDIN_SYNC_STATUS} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/constants';

export default defineMessages({
    linkedInCaption: {
        id: 'app.readinessDataCollectionCTA.linkedInCaption'
    },
    seeDetailedInformation: {
        id: 'app.readinessDataCollectionCTA.linkedInCaption.seeDetailedInformation'
    },
    retrieveLinkedInData: {
        id: 'app.readinessDataCollectionCTA.action.retrieveLI'
    },
    addManually: {
        id: 'app.readinessDataCollectionCTA.action.addManually'
    },
    or: {
        id: 'app.general.or'
    },
    maybeLater: {
        id: 'app.general.maybeLater'
    }
});

export const linkedinSyncErrorMessages = defineMessages({
    [`${LINKEDIN_SYNC_STATUS.CANCELLED}_description`]: {
        id: 'app.readinessDataCollectionCTA.error.LinkedInCancelled'
    },
    [`${LINKEDIN_SYNC_STATUS.ACCESS_DENIED}_title`]: {
        id: 'app.readinessDataCollectionCTA.error.NoAccessToLIAPI.title'
    },
    [`${LINKEDIN_SYNC_STATUS.ACCESS_DENIED}_description`]: {
        id: 'app.readinessDataCollectionCTA.error.NoAccessToLIAPI.description'
    },
    [`${LINKEDIN_SYNC_STATUS.FAILED}_title`]: {
        id: 'app.errorState.title'
    },
    [`${LINKEDIN_SYNC_STATUS.FAILED}_description`]: {
        id: 'app.errorState.descriptionThree'
    }
});
