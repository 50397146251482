import {useMemo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {StandardTenChart} from '@/componentLibrary/components/charts/StandardTenChart';
import {Caption, P2, S2} from '@/componentLibrary/components/typography';
import {ColorFgMuted, ColorFgSubtle} from '@/componentLibrary/tokens/variables';

import {personalityTestReportMessages} from '../../../../messages';
import {BulletList} from '../../../BulletList';
import {StenChartWrapper} from '../../../DetailedFactorResult/styled';
import {PersonalityPercentile} from '../../../PersonalityPercentile';
import {
    ChartWrapper,
    DescriptionWrapper,
    FacetResultWrapper,
    LabelsWrapper,
    LowerLabelWrapper,
    UpperLabelWrapper
} from './styled';

type FacetResultProps = {
    facetMessages: {
        title: {[key: string]: string};
        labelLower: {[key: string]: string};
        labelUpper: {[key: string]: string};
        descriptionLower: {[key: string]: string};
        descriptionUpper: {[key: string]: string};
        bulletsVeryLow: {[key: string]: string};
        bulletsLow: {[key: string]: string};
        bulletsAverage: {[key: string]: string};
        bulletsHigh: {[key: string]: string};
        bulletsVeryHigh: {[key: string]: string};
    };
    scoreValue: number;
};

export function FacetResult({facetMessages, scoreValue}: FacetResultProps): JSX.Element {
    const intl = useIntl();

    const labelLowerText = intl.formatMessage(facetMessages.labelLower);
    const labelUpperText = intl.formatMessage(facetMessages.labelUpper);

    const textBasedOnResultValue = useMemo(() => {
        if (scoreValue <= 2) {
            return {
                title: intl.formatMessage(personalityTestReportMessages.highDeviation, {
                    label: labelLowerText
                }),
                bullets: intl.formatMessage(facetMessages.bulletsVeryLow).split('. ')
            };
        }

        if (scoreValue <= 4) {
            return {
                title: labelLowerText,
                bullets: intl.formatMessage(facetMessages.bulletsLow).split('. ')
            };
        }

        if (scoreValue <= 6) {
            return {
                title: intl.formatMessage(personalityTestReportMessages.inBetween, {
                    labelLower: labelLowerText,
                    labelUpper: labelUpperText
                }),
                bullets: intl.formatMessage(facetMessages.bulletsAverage).split('. ')
            };
        }

        if (scoreValue <= 8) {
            return {
                title: labelUpperText,
                bullets: intl.formatMessage(facetMessages.bulletsHigh).split('. ')
            };
        }

        return {
            title: intl.formatMessage(personalityTestReportMessages.highDeviation, {
                label: labelUpperText
            }),
            bullets: intl.formatMessage(facetMessages.bulletsVeryHigh).split('. ')
        };
    }, [facetMessages, scoreValue, labelLowerText, labelUpperText, intl]);

    return (
        <FacetResultWrapper>
            <DescriptionWrapper>
                <div>
                    <S2 $color={ColorFgSubtle}>
                        {intl.formatMessage(facetMessages.title)}
                        :&nbsp;
                    </S2>
                    <S2>{textBasedOnResultValue.title}</S2>
                </div>
                <PersonalityPercentile scoreValue={scoreValue} isSmall />
                <BulletList items={textBasedOnResultValue.bullets} />
            </DescriptionWrapper>
            <ChartWrapper>
                <StenChartWrapper>
                    <StandardTenChart selectedValue={scoreValue} showBadge />
                </StenChartWrapper>
                <LabelsWrapper>
                    <UpperLabelWrapper>
                        <P2 color={ColorFgMuted}>{labelLowerText}</P2>
                        <Caption $color={ColorFgSubtle}>
                            <FormattedMessage {...facetMessages.descriptionLower} />
                        </Caption>
                    </UpperLabelWrapper>
                    <LowerLabelWrapper>
                        <P2 color={ColorFgMuted}>{labelUpperText}</P2>
                        <Caption $color={ColorFgSubtle}>
                            <FormattedMessage {...facetMessages.descriptionUpper} />
                        </Caption>
                    </LowerLabelWrapper>
                </LabelsWrapper>
            </ChartWrapper>
        </FacetResultWrapper>
    );
}
