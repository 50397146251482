import {getConfig} from '@/config';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {LINKEDIN_ERRORS, LINKEDIN_SYNC_STATUS} from './constants';

export const toWithSyncStatus = (state: string, status: LINKEDIN_SYNC_STATUS) => {
    const [path, params] = state.split('?');
    const searchParam = new URLSearchParams(params);
    searchParam.set('linkedin_sync_status', status);
    return `${path}?${searchParam.toString()}`;
};

export const toErrorType = (error: string | null) => {
    if (!isDefined(error)) {
        return null;
    }

    const isError = (enumValue: string) => enumValue === error;
    return Object.values(LINKEDIN_ERRORS).find(isError);
};

export const toStatus = (error: LINKEDIN_ERRORS | null) => {
    if (!isDefined(error)) {
        return LINKEDIN_SYNC_STATUS.FAILED;
    }

    const map = {
        [LINKEDIN_ERRORS.USER_CANCELLED_LOGIN]: LINKEDIN_SYNC_STATUS.CANCELLED,
        [LINKEDIN_ERRORS.USER_CANCELLED_AUTHORIZE]: LINKEDIN_SYNC_STATUS.CANCELLED,
        [LINKEDIN_ERRORS.MEMBER_ACCESS_DENIED]: LINKEDIN_SYNC_STATUS.ACCESS_DENIED
    };

    return map[error];
};

export function getRedirectUri() {
    const appConfig = getConfig();
    return `${appConfig.APP}/verify-linkedin-authentication`;
}
