/**
 * Warning: Do not export anything from this file,
 * because that will cause errors with Jest testing-framework
 * https://stackoverflow.com/questions/39986178/testing-react-target-container-is-not-a-dom-element
 */
import {ApolloProvider} from '@apollo/client';
import {render} from 'react-dom';
import {Provider} from 'react-redux';

import {client} from './apolloClient';
import {ToastsList} from './componentLibrary/components/Toast/components/ToastsList';
import {LanguageProvider} from './containers/LanguageProvider';
import {GlobalStyle} from './globalStyled';
import {translationMessages} from './i18n';
import '../resources/fonts.css';
import './print.css';
import {IncompatibleBrowserBanner} from './services/IncompatibleBrowserBanner';
import './services/logrocket/setup';
import {App} from './App';
import store from './store';

render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <LanguageProvider messages={translationMessages}>
                <>
                    <GlobalStyle />
                    <App />
                </>
            </LanguageProvider>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
);

render(
    <Provider store={store}>
        <LanguageProvider messages={translationMessages}>
            <ToastsList />
        </LanguageProvider>
    </Provider>,
    document.getElementById('toasts')
);

render(
    <Provider store={store}>
        <LanguageProvider messages={translationMessages}>
            <IncompatibleBrowserBanner />
        </LanguageProvider>
    </Provider>,
    document.getElementById('browser-prompt')
);
