import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {UserExperience} from '@/pages/User/components/RecommendationRelevanceForm/utils';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided
} from 'react-beautiful-dnd';
import {ERROR_TYPES} from '../../constants';
import {Section} from '../Section';
import {EmptyWorkExperience} from './components/EmptyWorkExperience';
import {WorkExperienceItem} from './components/WorkExperienceItem';
import {useWorkExperienceLogic} from './logic';
import {DraggableItemWrapper} from './styled';
import {Props} from './types';

export const WorkExperience = ({
    workExperience,
    setWorkExperience,
    invalidWorkExperienceIds,
    workExperienceHasFieldError,
    submitTimestamp
}: Props) => {
    const {phrases, hasWorkExperience, addWorkExperience, getRemoveWorkExperience, onDragEnd} =
        useWorkExperienceLogic({
            setWorkExperience,
            workExperience
        });

    const AddButton = (
        <PlainButton style={{padding: '0px'}} icon={Icons.ADD} onClick={addWorkExperience}>
            {phrases.addButtonText}
        </PlainButton>
    );

    const toWorkExperience = (workExperience: UserExperience, index: number) => {
        const handleWorkExperienceChange = <K extends keyof UserExperience>(
            key: K,
            value: UserExperience[K]
        ) => {
            const toUpdatedExperience = (experience: UserExperience) =>
                experience.id === workExperience.id
                    ? {...experience, [key]: value}
                    : experience;

            const toUpdatedState = (prevState: UserExperience[]) =>
                prevState.map(toUpdatedExperience);

            setWorkExperience(toUpdatedState);
        };

        const removeWorkExperience = getRemoveWorkExperience(workExperience.id);
        const isFirstWorkExperienceWithError =
            invalidWorkExperienceIds.findIndex(id => id === workExperience.id) === 0;

        return (
            <Draggable draggableId={workExperience.id} index={index} key={workExperience.id}>
                {(
                    providedDraggable: DraggableProvided,
                    snapshotDraggable: DraggableStateSnapshot
                ) => (
                    <DraggableItemWrapper
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        $isDragging={snapshotDraggable.isDragging}
                    >
                        <WorkExperienceItem
                            key={workExperience.id}
                            workExperience={workExperience}
                            onUpdateField={handleWorkExperienceChange}
                            removeWorkExperience={removeWorkExperience}
                            workExperienceHasFieldError={workExperienceHasFieldError}
                            invalid={invalidWorkExperienceIds.includes(workExperience.id)}
                            focusOnErrors={isFirstWorkExperienceWithError}
                            submitTimestamp={submitTimestamp}
                        />
                    </DraggableItemWrapper>
                )}
            </Draggable>
        );
    };

    const renderExperience = () => {
        if (!hasWorkExperience) {
            return <EmptyWorkExperience addAction={AddButton} />;
        }

        return <>{workExperience.map(toWorkExperience)}</>;
    };

    return (
        <Section title={phrases.title} id={ERROR_TYPES.EXPERIENCE}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="work-experience">
                    {(provided: DroppableProvided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {renderExperience()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div>{hasWorkExperience && AddButton}</div>
        </Section>
    );
};
