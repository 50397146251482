import {greyColorTokens} from '@/componentLibrary/tokens/legacyColors';
import {
    BorderRadiusBaseXsmall,
    ColorBaseGrey000,
    ColorFgSubtle,
    SpacingXsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled, {css} from 'styled-components';
import {DropdownMenuItemWrapper} from './DropdownMenuItem/styled';

export const MenuItemWrapper = styled(DropdownMenuItemWrapper)<{
    $isHighlighted: boolean;
    $showCheckboxes: boolean;
}>`
    ${({$isHighlighted}) =>
        $isHighlighted
            ? css`
                  background-color: ${greyColorTokens.grey900(0.7)};

                  &:hover {
                      background-color: ${greyColorTokens.grey900(0.7)};
                  }
              `
            : css`
                  &:hover {
                      background-color: ${ColorBaseGrey000};
                  }
              `}
    ${({$showCheckboxes}) =>
        $showCheckboxes
            ? css`
                  display: flex;
                  align-items: center;
                  gap: ${SpacingXsmall};
              `
            : ''}
`;

export const LoadingWrapper = styled.div`
    padding: ${SpacingXsmall} 0;
`;

const spacingStyles = `
    grid-area: 1 / 2;
    min-width: ${BorderRadiusBaseXsmall};
    border: 0;
    margin: 0;
    outline: 0;
`;

export const FluidTextInputWrapper = styled.div`
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1 / 1 / 2 / 3;
    grid-template-columns: 0 min-content;
    padding-left: ${SpacingXxxsmall};

    &::after {
        content: attr(data-value) '';
        visibility: hidden;
        white-space: pre;
        padding-left: ${SpacingXxxsmall};
        ${spacingStyles}
    }
`;

export const FluidInput = styled.input`
    background: 0;
    width: 100%;
    padding: 0;
    ${spacingStyles}
`;

export const FluidTextPlaceholder = styled.div`
    color: ${ColorFgSubtle};
    position: absolute;
    padding-left: ${SpacingXxxsmall};
    pointer-events: none;
`;
