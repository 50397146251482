import {WatchQueryFetchPolicy, useQuery} from '@apollo/client';
import {useMemo} from 'react';

import {GET_LOGIC_TEST_STATUS} from './queries';

export function useLogicTestStatus(logicTestId: string) {
    const options = useMemo(() => {
        return {
            variables: {id: logicTestId},
            fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
            nextFetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
            skip: !logicTestId
        };
    }, [logicTestId]);
    const {data, error, loading, refetch, called} = useQuery(GET_LOGIC_TEST_STATUS, options);

    const logicTestStatus = useMemo(() => data?.logicTestStatus ?? null, [data]);

    return {
        logicTestStatus,
        loading,
        error,
        called,
        refetch
    };
}
