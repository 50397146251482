import styled, {css} from 'styled-components';

import {SpacingMedium} from '@/componentLibrary/tokens/variables';
import {isDefined} from '@/utils/typeGuards/isDefined';

type TextBlockProps = {
    title: JSX.Element;
    content?: JSX.Element;
    actionZone?: JSX.Element;
    isSmall?: boolean;
    isLarge?: boolean;
    isCentered?: boolean;
};

type WrapperProps = {
    $isSmall: boolean;
    $isLarge: boolean;
    $isCentered: boolean;
};

export function TextBlock({
    title,
    content,
    actionZone,
    isSmall = false,
    isLarge = false,
    isCentered = false
}: TextBlockProps) {
    return (
        <Wrapper $isSmall={isSmall} $isLarge={isLarge} $isCentered={isCentered}>
            {title}
            {isDefined(content) && content}
            {isDefined(actionZone) && actionZone}
        </Wrapper>
    );
}

const Wrapper = styled.div<WrapperProps>`
    width: 100%;
    max-width: 410px;
    ${({$isSmall}) => ($isSmall ? 'max-width: 320px;' : '')}
    ${({$isLarge}) => ($isLarge ? 'max-width: 500px;' : '')}
    display: flex;
    flex-direction: column;
    gap: ${SpacingMedium};
    ${({$isCentered}) =>
        $isCentered &&
        css`
            align-items: center;
            justify-content: center;
            text-align: center;
        `}
`;
