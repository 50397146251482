import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorBaseBlue800} from '@/componentLibrary/tokens/variables';
import {DropdownMenuItemWrapper} from './styled';
import {DropdownMenuItemProps} from './types';

export const DropdownMenuItem = ({
    isDisabled,
    isSelected,
    showCheckboxes,
    children,
    components = {Wrapper: DropdownMenuItemWrapper},
    ...innerProps
}: DropdownMenuItemProps) => (
    <components.Wrapper
        $isSelected={isSelected}
        $showCheckboxes={showCheckboxes}
        $isDisabled={isDisabled}
        {...innerProps}
    >
        {children}
        {isSelected && !showCheckboxes ? (
            <Icon icon={Icons.CHECK} fill={ColorBaseBlue800} />
        ) : null}
    </components.Wrapper>
);
