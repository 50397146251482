export const TABLE_ROW_HEIGHT = {
    NORMAL: '56px',
    MINIMAL: '40px'
} as const;

export const TABLE_CELL_WIDTH = {
    MINIMAL: '0.1%',
    NORMAL: '2%',
    EXTRA: '4%',
    GROW: '10%'
} as const;
