import {InteractableCard} from './InteractableCard';
import {CardTypes} from './constants';
import {AliasProps} from './types';

const DraggableCard = (props: AliasProps) => {
    return <InteractableCard cardType={CardTypes.DRAGGABLE} {...props} />;
};

const SelectableCard = (props: AliasProps) => {
    return <InteractableCard cardType={CardTypes.SELECTABLE} {...props} />;
};

export {InteractableCard, DraggableCard, SelectableCard};
