import qs from 'qs';
import {useEffect, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {LINKS} from '@/links';
import {clearSelectedOrganization} from '@/services/auth/utils';

export function useSignupPageLogic() {
    const location = useLocation();
    const navigate = useNavigate();

    const token = useMemo(() => {
        const params = qs.parse(location.search, {ignoreQueryPrefix: true});
        return params.t && typeof params.t === 'string'
            ? window.decodeURIComponent(params.t)
            : '';
    }, [location.search]);

    const onSuccess = (
        registrationType: string | null,
        readinessDataCollectionEnabled: boolean
    ) => {
        const isCandidate = registrationType !== 'EMPLOYEE';
        const redirectLink =
            isCandidate && readinessDataCollectionEnabled
                ? LINKS.readinessDataCollection(0)
                : LINKS.login;
        navigate(redirectLink);
    };

    useEffect(() => {
        clearSelectedOrganization();
    }, []);

    return {token, onSuccess};
}
