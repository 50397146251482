import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {LOGIC_TEST_IRT_LINKS} from '../links';

export function useGoToLogicTest(goBackUrl?: string) {
    const navigate = useNavigate();
    return useCallback(
        personalityTestId => {
            const testUrl = LOGIC_TEST_IRT_LINKS.test(personalityTestId);
            const url = goBackUrl ? `${testUrl}?redirect=${goBackUrl}` : testUrl;
            navigate(url);
        },
        [navigate, goBackUrl]
    );
}
