import {Route, Routes} from 'react-router-dom';

import {urlRelative} from '@/utils/url';
import {NotFound} from '../NotFound';
import {LOGIC_TEST_DATA_COLLECTION_BASE_URL, logicTestDataCollectionLinks} from './links';
import {CompletedDataCollectionTest} from './pages/CompletedDataCollectionTest';
import {DataCollectionForm} from './pages/DataCollectionForm';

export function LogicTestDataCollection() {
    return (
        <Routes>
            <Route
                path={urlRelative(
                    logicTestDataCollectionLinks.form(':studyId'),
                    LOGIC_TEST_DATA_COLLECTION_BASE_URL
                )}
                element={<DataCollectionForm />}
            />
            <Route
                path={urlRelative(
                    logicTestDataCollectionLinks.completed(':logicTestId'),
                    LOGIC_TEST_DATA_COLLECTION_BASE_URL
                )}
                element={<CompletedDataCollectionTest />}
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}
