import {useSimpleModalLogic} from '@/hooks/useSimpleModalLogic';
import {UserExperience} from '@/pages/User/components/RecommendationRelevanceForm/utils';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {
    ChangeEvent,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {UserExperienceKey} from '../../../../hooks/useWorkExperienceFormValidation/types';
import {LogicProps} from './types';
import {isEmpty} from './utils';

export const useWorkExperienceItemLogic = ({
    workExperience,
    onUpdateField,
    removeWorkExperience,
    workExperienceHasFieldError,
    invalid,
    focusOnErrors,
    submitTimestamp
}: LogicProps) => {
    const jobTitleRef = useRef<HTMLInputElement>(null);
    const companyNameRef = useRef<HTMLInputElement>(null);

    const handleTextFieldChange = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const fieldKey = key as keyof UserExperience;
        const value = event.target.value;
        onUpdateField(fieldKey, value);
    };

    const handleDescriptionChange = (value: string) => {
        const fieldKey = 'description' as keyof UserExperience;
        onUpdateField(fieldKey, value);
    };

    const handleDateChange = (key: string) => (value: Date | null) => {
        const fieldKey = key as keyof UserExperience;
        onUpdateField(fieldKey, value);
    };

    const {
        modalIsOpen: removeModalIsOpen,
        openModal: openRemoveModal,
        closeModal: closeRemoveModal
    } = useSimpleModalLogic();

    const onRemove = useCallback(() => {
        if (isEmpty(workExperience)) {
            removeWorkExperience();
            return;
        }

        openRemoveModal();
    }, [workExperience, removeWorkExperience, openRemoveModal]);

    useFocusTitleUponAdd(workExperience, jobTitleRef);

    const {expanded: accordionExpanded, toggle: onAccordionToggle} = useAccordionLogic(
        workExperience,
        invalid,
        submitTimestamp
    );

    const indicateErrors = useIndicateErrors(invalid, submitTimestamp);

    const {focusStartDate: focusOnErrorStartDate, focusEndDate: focusOnErrorEndDate} =
        useFocusOnErrors({
            disable: !focusOnErrors,
            id: workExperience.id,
            indicateErrors,
            workExperienceHasFieldError,
            jobTitleRef,
            companyNameRef,
            submitTimestamp
        });

    return {
        jobTitleRef,
        companyNameRef,
        handleJobTitleChange: handleTextFieldChange('jobTitle'),
        handleCompanyNameChange: handleTextFieldChange('companyName'),
        handleDescriptionChange,
        handleStartDateChange: handleDateChange('startDate'),
        handleEndDateChange: handleDateChange('endDate'),
        removeModalIsOpen,
        onRemove,
        closeRemoveModal,
        accordionExpanded,
        onAccordionToggle,
        indicateErrors,
        focusOnErrorStartDate,
        focusOnErrorEndDate
    };
};

const useFocusTitleUponAdd = (
    workExperience: UserExperience,
    jobTitleRef: RefObject<HTMLInputElement>
) => {
    /*
     biome-ignore lint/correctness/useExhaustiveDependencies:
     Only want to run this effect when the experience is just added.
    */
    useEffect(() => {
        if (!isEmpty(workExperience) || !isDefined(jobTitleRef.current)) {
            return;
        }

        jobTitleRef.current.focus();
    }, [jobTitleRef]);
};

const useAccordionLogic = (
    workExperience: UserExperience,
    invalid: boolean,
    submitTimestamp?: number
) => {
    const defaultExpanded = isEmpty(workExperience);
    const [expanded, setExpanded] = useState(defaultExpanded);
    const toggle = useCallback(() => setExpanded(!expanded), [expanded]);

    /*
     biome-ignore lint/correctness/useExhaustiveDependencies:
     Only want to run this effect on submissions.
    */
    useEffect(() => {
        if (invalid) {
            setExpanded(true);
        }
    }, [submitTimestamp]);

    return {
        expanded,
        toggle
    };
};

const useIndicateErrors = (invalid: boolean, submitTimestamp?: number) => {
    const creationTimestamp = useCreationTimestamp();

    return useMemo(() => {
        if (!invalid) {
            return false;
        }

        if (!isDefined(submitTimestamp)) {
            return false;
        }

        return creationTimestamp < submitTimestamp;
    }, [invalid, submitTimestamp, creationTimestamp]);
};

const useFocusOnErrors = ({
    disable,
    id,
    indicateErrors,
    workExperienceHasFieldError,
    jobTitleRef,
    companyNameRef,
    submitTimestamp
}: {
    disable: boolean;
    id: string;
    indicateErrors: boolean;
    workExperienceHasFieldError: (id: string, field: UserExperienceKey) => boolean;
    jobTitleRef: RefObject<HTMLInputElement>;
    companyNameRef: RefObject<HTMLInputElement>;
    submitTimestamp?: number;
}) => {
    const [focusStartDate, setFocusStartDate] = useState(false);
    const [focusEndDate, setFocusEndDate] = useState(false);

    /*
     biome-ignore lint/correctness/useExhaustiveDependencies:
     Only want to run this effect on submissions.
    */
    useEffect(() => {
        if (!indicateErrors || disable) {
            return;
        }

        const errorsWithEffectInOrder = [
            {
                hasError: workExperienceHasFieldError(id, 'jobTitle'),
                onError: () => jobTitleRef.current?.focus()
            },
            {
                hasError: workExperienceHasFieldError(id, 'companyName'),
                onError: () => companyNameRef.current?.focus()
            },
            {
                hasError: workExperienceHasFieldError(id, 'startDate'),
                onError: () => setFocusStartDate(true)
            },
            {
                hasError: workExperienceHasFieldError(id, 'endDate'),
                onError: () => setFocusEndDate(true)
            }
        ];

        const errorsWithEffect = errorsWithEffectInOrder.filter(item => item.hasError);
        const firstErrorWithEffect = errorsWithEffect[0];
        firstErrorWithEffect?.onError();
    }, [jobTitleRef, companyNameRef, submitTimestamp]);

    return {
        focusStartDate,
        focusEndDate
    };
};

const useCreationTimestamp = () => {
    const [timestamp, setTimestamp] = useState(Date.now());

    useEffect(() => {
        setTimestamp(Date.now());
    }, []);

    return timestamp;
};
