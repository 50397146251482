import {useWorkExperienceFormValidation} from '@/pages/User/components/RecommendationRelevanceForm/hooks/useWorkExperienceFormValidation/useWorkExperienceFormValidation';
import {UserExperience} from '@/pages/User/components/RecommendationRelevanceForm/utils';
import {useState} from 'react';

export const useWorkExperienceLogic = () => {
    const defaultWorkExperience: UserExperience[] = [];
    const [workExperience, setWorkExperience] =
        useState<UserExperience[]>(defaultWorkExperience);

    const {isValid, invalidIds, hasFieldError} =
        useWorkExperienceFormValidation(workExperience);

    return {
        workExperience,
        setWorkExperience,
        isValid,
        invalidIds,
        hasFieldError
    };
};
