import {CenteredSpinner} from '@/componentLibrary/components/spinners';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';

import {NoItems} from '../NoItems';
import {LoadingWrapper} from '../styled';
import {useScrollLogic} from './hooks';
import messages from './messages';
import {DropdownMenu} from './styled';
import {MenuProps} from './types';

export function Menu(props: MenuProps) {
    const phrases = useExtractPhraseConstants(messages);
    const {
        children,
        isLoading = false,
        noItems = false,
        getMenuProps,
        onScrollToBottom,
        highlightedIndex,
        noItemsMessage,
        setHighlightedIndex,
        itemsCount,
        fullWidth,
        width,
        disableScroll
    } = props;

    const {onScroll} = useScrollLogic({
        onScrollToBottom,
        highlightedIndex,
        setHighlightedIndex,
        itemsCount
    });

    const menuProps = getMenuProps?.({onScroll}) ?? {};

    return (
        <DropdownMenu
            $fullWidth={fullWidth}
            $width={width}
            $disableScroll={disableScroll}
            {...menuProps}
        >
            {isLoading && (
                <LoadingWrapper>
                    <CenteredSpinner />
                </LoadingWrapper>
            )}

            {!isLoading && noItems && <NoItems>{noItemsMessage ?? phrases.noItems}</NoItems>}
            {!isLoading && children}
        </DropdownMenu>
    );
}
