import {CardTypes} from '@/componentLibrary/components/cards/AssessmentCard/constants';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorFgInfo, ColorFgSuccess} from '@/componentLibrary/tokens/variables';
import {useMemo} from 'react';
import {Props} from './types';

export const useInteractableCardLogic = ({cardType, isSelected}: Props) => {
    const isDraggable = useMemo(() => cardType === CardTypes.DRAGGABLE, [cardType]);
    const isSelectedSelectable = useMemo(
        () => cardType === CardTypes.SELECTABLE && isSelected,
        [cardType, isSelected]
    );

    const color = useMemo(
        () => (isSelectedSelectable ? ColorFgSuccess : ColorFgInfo),
        [isSelectedSelectable]
    );

    const icon = useMemo(() => {
        if (isDraggable) {
            return Icons.DRAG_INDICATOR;
        }

        if (isSelectedSelectable) {
            return Icons.CHECK_CIRCLE;
        }

        return Icons.ADD_CIRCLE;
    }, [isDraggable, isSelectedSelectable]);

    const hasHoverableIcon = useMemo(() => !isDraggable, [isDraggable]);

    return {
        color,
        hasHoverableIcon,
        icon
    };
};
