import {Ref, forwardRef, useMemo} from 'react';

import {Icon} from '@/componentLibrary/components/icons/Icon';
import {P2} from '@/componentLibrary/components/typography';
import {ColorBaseGrey600} from '@/componentLibrary/tokens/variables';

import {getSelectedValueDisplay, hasSelectedValue} from '../utils';
import {useInteractFieldStyleLogic} from './hooks';
import {InteractFieldText, InteractFieldWrapper} from './styled';
import {InteractFieldTypes} from './types';

const InteractFieldComponent = <T,>(
    {
        isActive,
        disabled = false,
        placeholder,
        displayValue,
        size,
        selectedItem,
        openMenu,
        itemToString
    }: InteractFieldTypes<T>,
    ref: Ref<HTMLDivElement>
): JSX.Element => {
    const {padding, iconFill, icon} = useInteractFieldStyleLogic({
        size,
        isActive
    });

    const selectedItemString = useMemo(
        () => getSelectedValueDisplay(selectedItem, itemToString, displayValue),
        [displayValue, itemToString, selectedItem]
    );

    return (
        <InteractFieldWrapper
            ref={ref}
            tabIndex={1}
            $padding={padding}
            $isActive={isActive}
            $isDisabled={disabled}
            onClick={openMenu}
        >
            {placeholder && !hasSelectedValue(selectedItem) ? (
                <P2 color={ColorBaseGrey600}>{placeholder}</P2>
            ) : (
                <InteractFieldText $isDisabled={disabled}>
                    {displayValue ? displayValue : selectedItemString}
                </InteractFieldText>
            )}
            <Icon icon={icon} fill={iconFill} />
        </InteractFieldWrapper>
    );
};

export const InteractField = forwardRef(InteractFieldComponent) as <T>(
    p: InteractFieldTypes<T> & {ref?: Ref<HTMLDivElement>}
) => JSX.Element;
