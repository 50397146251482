import {useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {LINKS} from '@/links';

export function useGoToOktaLoginPage() {
    const navigate = useNavigate();
    const location = useLocation();

    return useCallback(() => {
        navigate(LINKS.oktaLogin, {replace: true, state: {search: location.search}});
    }, [location.search, navigate]);
}
