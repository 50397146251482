import {ComponentType, LazyExoticComponent, lazy} from 'react';

type ComponentPromise<T = unknown> = Promise<{default: ComponentType<T>} | ComponentType<T>>;

export function lazyRetry(
    componentImport: () => ComponentPromise,
    componentName: string
): LazyExoticComponent<ComponentType<unknown>> {
    return lazy(
        () =>
            retry(componentImport, componentName) as Promise<{default: ComponentType<unknown>}>
    );
}

async function retry(componentImport: () => ComponentPromise, componentName: string) {
    const key = `page-has-been-force-refreshed-${componentName}`;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
        window.localStorage.getItem(key) || 'false'
    );

    try {
        const component = await componentImport();
        window.localStorage.removeItem(key);
        return component;
    } catch (error) {
        if (!pageHasAlreadyBeenForceRefreshed) {
            // Assuming that the user is not on the latest version of the application.
            // Let's refresh the page immediately.
            window.localStorage.setItem(key, 'true');
            window.location.reload();
        } else {
            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Clear localStorage until next time
            window.localStorage.removeItem(key);
            // Let the application crash and raise the error.
            throw error;
        }
    }
}
