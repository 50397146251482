import styled from 'styled-components';

import {ColorBgDefault} from '@/componentLibrary/tokens/variables';

export const DropdownMenu = styled.div<{
    $fullWidth?: boolean;
    $width?: string | number;
    $disableScroll?: boolean;
}>`
    background-color: ${ColorBgDefault};
    max-height: 336px;
    overflow: ${({$disableScroll}) => ($disableScroll ? 'hidden' : 'auto')};
    width: ${p => (p.$fullWidth ? '100%' : p.$width)};
`;
