import ReactMarkdown, {PluggableList, ReactMarkdownOptions} from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BodyP1,
    BodyP2,
    HeadingH1,
    HeadingH2,
    HeadingH3,
    HeadingH4,
    OtherCaption
} from '@/componentLibrary/tokens/typography';
import {
    ColorBaseBlue700,
    ColorBaseBlue800,
    ColorBaseBlue900,
    ColorBaseGrey000,
    ColorBaseGrey100,
    ColorBaseGrey200,
    ColorBaseGrey900,
    FontSizeDefault,
    SpacingSmall,
    SpacingXlarge,
    SpacingXsmall,
    SpacingXxlarge,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import DOMPurify from 'dompurify';
import {Icons} from '../icons/constants';
import {buildIconSrc} from '../icons/utils';

const EXTERNAL_LINK_ICON_SIZE = '14px';

export const MarkdownSupportedBodyTypes = {
    P1: BodyP1,
    P2: BodyP2,
    CAPTION: OtherCaption
};

export const Markdown = ({
    children,
    body = MarkdownSupportedBodyTypes.P1,
    withHtmlSupport = false,
    ...props
}: ReactMarkdownOptions & {body?: string; withHtmlSupport?: boolean}) => (
    <MarkdownWrapper $body={body}>
        <ReactMarkdown
            {...props}
            remarkPlugins={[remarkGfm] as PluggableList}
            rehypePlugins={withHtmlSupport ? ([rehypeRaw] as unknown as PluggableList) : []}
        >
            {withHtmlSupport ? DOMPurify.sanitize(children) : children}
        </ReactMarkdown>
    </MarkdownWrapper>
);

export const MarkdownWrapper = styled.div<{$body?: string}>`
    ${({$body}) => ($body ? $body : BodyP1)};

    h1,
    h2,
    h3 {
        margin-bottom: ${SpacingXsmall};
    }

    h1 {
        ${HeadingH1}
        &:not(:nth-of-type(1)) {
            margin-top: ${SpacingXxlarge};
        }
    }

    h2 {
        ${HeadingH2}
    }

    h3 {
        ${HeadingH3}
    }

    h4 {
        ${HeadingH4};
        margin-bottom: ${SpacingXxsmall};
    }

    h5,
    h6 {
        ${HeadingH4};
        font-size: ${FontSizeDefault};
    }

    h5 {
        margin-bottom: ${SpacingXxxsmall};
    }

    p {
        ${({$body}) => $body ?? ''};
        margin: 0;

        &:not(:last-child) {
            margin-bottom: ${SpacingSmall};
        }
    }

    ul {
        list-style-type: disc;
    }

    ul,
    ol {
        margin-left: 18px;
        margin-bottom: ${SpacingSmall};
    }

    li {
        margin-bottom: ${SpacingXxxsmall};
    }

    ol {
        counter-reset: item;
        list-style-type: none;
        margin: 0 0 ${SpacingSmall} 0;
        padding: 0;

        li {
            clear: left;
            padding-left: 1em;
            position: relative;

            &::before {
                content: counter(item) '. ';
                counter-increment: item;
                left: 0;
                position: absolute;
                width: 10%;
            }
        }
    }

    a {
        align-items: center;
        color: ${ColorBaseBlue800};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            color: ${ColorBaseBlue700};
            outline: none;
        }

        &.selected:focus,
        &.selected,
        &:active:focus:hover,
        &.active,
        &:active {
            color: ${ColorBaseBlue900};
        }

        &:disabled:hover,
        &:disabled {
            color: ${ColorBaseBlue700};
            cursor: not-allowed;
        }

        ::after {
            content: '';
            background-image: url("${buildIconSrc(Icons.OPEN_IN_NEW)}");
            background-position: 0 2px;
            background-repeat: no-repeat;
            background-size: ${EXTERNAL_LINK_ICON_SIZE} ${EXTERNAL_LINK_ICON_SIZE};
            display: inline-block;
            height: ${EXTERNAL_LINK_ICON_SIZE};
            width: ${EXTERNAL_LINK_ICON_SIZE};
            margin-left: ${SpacingXxxsmall};
        }
    }

    table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: ${SpacingXlarge};
    }

    table th {
        padding-top: ${SpacingXsmall};
        padding-bottom: ${SpacingXsmall};
        text-align: left;
        background-color: ${ColorBaseGrey900};
        color: ${ColorBaseGrey000};
    }

    table td,
    table th {
        ${BorderBaseDefaultRule};
        padding: ${SpacingXxsmall};
    }

    table tr:nth-child(even) {
        background-color: ${ColorBaseGrey100};
    }

    table tr:hover {
        background-color: ${ColorBaseGrey200};
    }

    details {
        summary {
            list-style: none;

            ::before {
                content: '▶';
                margin-right: ${SpacingXxxsmall};
                display: inline-block;
                transition: transform 0.2s ease-in-out;
            }
        }
        
        margin-bottom: ${SpacingSmall};

        &[open] ::before {
            transform: rotate(90deg);
        }
    }
`;
