import {useMemo} from 'react';

import {Separator} from '@/componentLibrary/components/Separator';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {ColorFgSubtle} from '@/componentLibrary/tokens/variables';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {Stepper} from '../../Stepper';
import {PlainButton} from '../../buttons/PlainButton';
import {Caption, S2} from '../../typography';
import messages from './messages';
import {HeaderWrapper, StepperWrapper, Title, TitleWrapper, Wrapper} from './styled';
import {ModalHeaderProps} from './types';

export function ModalHeader({
    title,
    subTitle,
    closeButtonText,
    close,
    back,
    icon,
    stepper,
    hideClose,
    customRightContent
}: ModalHeaderProps) {
    const phrases = useExtractPhraseConstants(messages);

    const backButton = useMemo(() => {
        if (back) {
            return (
                <PlainButton
                    data-testid="back-button-modal"
                    onClick={back}
                    icon={Icons.ARROW_BACK}
                >
                    {phrases.back}
                </PlainButton>
            );
        }
        return null;
    }, [back, phrases.back]);

    const closeButton = useMemo(() => {
        if (!hideClose) {
            return (
                <PlainButton data-testid="icon-close" onClick={close}>
                    {closeButtonText ?? phrases.cancel}
                </PlainButton>
            );
        }
        return null;
    }, [close, closeButtonText, hideClose, phrases.cancel]);

    return (
        <Wrapper $alignItems="center" $justifyContent="space-between">
            <HeaderWrapper>
                {backButton}
                <TitleWrapper>
                    {icon}
                    <Title>
                        <S2>{title}</S2>
                        {isDefined(subTitle) && !isDefined(icon) && (
                            <Caption $color={ColorFgSubtle}>{subTitle}</Caption>
                        )}
                    </Title>
                </TitleWrapper>
                {!hideClose && !isDefined(customRightContent) && closeButton}
                {isDefined(customRightContent) && customRightContent}
            </HeaderWrapper>
            {isDefined(stepper) ? (
                <StepperWrapper>
                    <Stepper noOfSteps={stepper.noOfSteps} activeStep={stepper.activeStep} />
                </StepperWrapper>
            ) : (
                <Separator />
            )}
        </Wrapper>
    );
}
