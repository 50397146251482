import {BorderStripeProps} from '@/componentLibrary/components/cards/AssessmentCard/types';
import {BorderBaseDefaultRule} from '@/componentLibrary/tokens/customVariables';
import {
    BorderRadiusBaseSmall,
    BorderWidthEmphasis,
    SpacingMedium,
    SpacingSmall,
    SpacingXlarge,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SpacingSmall} ${SpacingMedium};
    ${BorderBaseDefaultRule};
    border-radius: ${BorderRadiusBaseSmall};
`;

export const LeadingContentWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${SpacingSmall};
    gap: ${SpacingXxxsmall};
    margin-left: -${SpacingMedium};
`;

export const BorderStripe = styled.div<BorderStripeProps>`
    width: ${BorderWidthEmphasis};
    height: ${SpacingXlarge};
    border-radius: 0 ${BorderRadiusBaseSmall} ${BorderRadiusBaseSmall} 0;
    background: ${({$color}) => $color};
`;

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const TrailingContent = styled.div`
    display: flex;
    align-items: center;
    gap: ${SpacingSmall};
    margin-left: auto;
`;
