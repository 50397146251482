import {Dropdown} from '@/componentLibrary/components/Dropdowns/Dropdown';
import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useDateDropdownLogic} from './logic';
import {DateRangeWrapper, DropdownWrapper} from './styled';
import {Props} from './types';

export const DateDropdowns = ({
    title,
    selectedDate,
    onUpdate,
    includePresent,
    focusOnErrors,
    hasError
}: Props) => {
    const {
        toOption,
        monthsOptions,
        yearsOptions,
        selectedMonth,
        selectedYear,
        onUpdateMonth,
        onUpdateYear,
        isPresent,
        phrases
    } = useDateDropdownLogic({includePresent, onUpdate, selectedDate});

    return (
        <DateRangeWrapper>
            <DropdownWrapper>
                <TextFieldLabel $isRequired>{title}</TextFieldLabel>
                <Dropdown
                    $fullWidth
                    items={monthsOptions.map(toOption).filter(isDefined)}
                    selectedItem={toOption(selectedMonth)}
                    onSelect={onUpdateMonth}
                    placeholder={phrases.month}
                    hasError={hasError}
                    triggerFocus={hasError && focusOnErrors}
                />
            </DropdownWrapper>

            <DropdownWrapper>
                {!isPresent && (
                    <Dropdown
                        $fullWidth
                        items={yearsOptions.map(toOption).filter(isDefined)}
                        selectedItem={toOption(selectedYear?.toString())}
                        placeholder={phrases.year}
                        onSelect={onUpdateYear}
                        hasError={hasError}
                    />
                )}
            </DropdownWrapper>
        </DateRangeWrapper>
    );
};
