import {Popover} from '@/componentLibrary/components/Tooltips/Popover';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {FlexLayout} from '@/componentLibrary/components/layout/FlexLayout';
import {P2, S2} from '@/componentLibrary/components/typography';
import {Wrapper} from './styled';

type Props = {
    title: string;
    tooltipTitle: string;
    tooltipDescription: string;
};

export function TooltipTitle({title, tooltipTitle, tooltipDescription}: Props) {
    const Content = () => (
        <FlexLayout $direction="column">
            <S2>{tooltipTitle}</S2>
            <P2>{tooltipDescription}</P2>
        </FlexLayout>
    );

    return (
        <Wrapper>
            <S2>{title}</S2>
            <Popover
                openOnHover
                content={<Content />}
                trigger={<Icon icon={Icons.INFO} size={16} />}
            />
        </Wrapper>
    );
}
