import {
    FloatingArrow,
    FloatingFocusManager,
    FloatingPortal,
    useMergeRefs
} from '@floating-ui/react';
import {HTMLProps, forwardRef} from 'react';

import {ColorBgDefault, ColorBorderDefault} from '@/componentLibrary/tokens/variables';

import {ContentWrapper} from '../../Tooltips/Tooltip/components/styled';
import {useActionOverlayContext} from '../hooks';
import {ExtendedContent} from './styled';

export const ActionOverlayContent = forwardRef<
    HTMLDivElement,
    {
        noPadding: boolean;
        noFocusTrap: boolean;
        returnFocus: boolean;
    } & HTMLProps<HTMLDivElement>
>(function ActionOverlayContent(
    {style, noPadding, noFocusTrap, returnFocus, ...props},
    propRef
) {
    const {
        context: floatingContext,
        matchTriggerWidth,
        ...context
    } = useActionOverlayContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!floatingContext.open) {
        return null;
    }

    const {children, ...propsWithoutChildren} = props;

    let zIndex;
    let otherStyles = style || {};
    const floatingStyles = context.floatingStyles;
    if (style?.zIndex) {
        ({zIndex, ...otherStyles} = style);
        floatingStyles.zIndex = zIndex;
    }

    if (matchTriggerWidth) {
        otherStyles.maxWidth = '100%';
    }

    return (
        <FloatingPortal>
            <FloatingFocusManager
                context={floatingContext}
                initialFocus={noFocusTrap ? -1 : 0}
                returnFocus={returnFocus}
            >
                <ContentWrapper
                    ref={ref}
                    style={{...context.floatingStyles}}
                    {...context.getFloatingProps(propsWithoutChildren)}
                >
                    <ExtendedContent
                        $noPadding={noPadding}
                        style={{...otherStyles}}
                        aria-labelledby={context.labelId}
                        aria-describedby={context.descriptionId}
                    >
                        {children}
                    </ExtendedContent>

                    {context.hasArrow && (
                        <FloatingArrow
                            ref={context.arrowRef}
                            context={floatingContext}
                            fill={ColorBgDefault}
                            stroke={ColorBorderDefault}
                            strokeWidth={1}
                        />
                    )}
                </ContentWrapper>
            </FloatingFocusManager>
        </FloatingPortal>
    );
});
