import {Suspense} from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from './Routes';
import {ErrorBoundary} from './componentLibrary/components/ErrorBoundary';
import {LoadingScreen} from './componentLibrary/components/LoadingScreen';
import {CookiebotBanner} from './services/cookiebot';
import {Intercom} from './services/intercom';
import {LogRocketWrapper} from './services/logrocket';
import {Satismeter} from './services/satismeter';

export const App = () => {
    return (
        <>
            <div id="full-screen-modal" key={'full-screen-modal'} />
            <Suspense fallback={<LoadingScreen />}>
                <RouterProvider router={router} />
                <CookiebotBanner />
                <ErrorBoundary wrapperStyle={{display: 'none'}}>
                    <Intercom />
                </ErrorBoundary>
                <ErrorBoundary wrapperStyle={{display: 'none'}}>
                    <Satismeter />
                </ErrorBoundary>
                <LogRocketWrapper />
            </Suspense>
        </>
    );
};
