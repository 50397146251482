import {ErrorBoundary} from '@/componentLibrary/components/ErrorBoundary';
import {LoginPage} from '@/pages/Login';
import {OktaLoginPage} from '@/pages/OktaLogin';
import {NoAccessFallback} from '@/pages/Organization/pages/Settings/components/NoAccessFallback';
import {StripeLoadingPage} from '@/pages/Organization/pages/StripeCheckout/pages/StripeLoadingPage';
import {ResetPasswordPage} from '@/pages/ResetPassword';
import {UpgradePlanPage} from '@/pages/UpgradePlan';
import {UserDemographics} from '@/pages/UserDemographics';
import {RequireScope} from '@/services/auth/RequireScope';
import {ComponentType} from 'react';
import {Navigate, RouteObject, createBrowserRouter} from 'react-router-dom';

import {VerifyLinkedinAuthentication} from '@/pages/User/pages/VerifyLinkedinAuthentication';
import {LINKS} from './links';
import {ConsentPage} from './pages/ConsentPage';
import {Impersonation} from './pages/Impersonation';
import {InvalidLink} from './pages/InvalidLink';
import {LogicTestDataCollection} from './pages/LogicTestDataCollection';
import {LogicTestValidationPage} from './pages/LogicTestValidation';
import {VerifyOidcAuthentication} from './pages/Login/VerifyOidcAuthentication';
import {LogoutPage} from './pages/Logout';
import {NotFound} from './pages/NotFound';
import {PersonalityDataCollection} from './pages/PersonalityDataCollection';
import {ReadinessDataCollection} from './pages/ReadinessDataCollection';
import {RecoverPasswordPage} from './pages/RecoverPassword';
import {SignupPage} from './pages/Signup';
import {SignupPage as SignupPageV2} from './pages/SignupV2';
import {ImpersonationBanner} from './services/ImpersonationBanner';
import {requireAuthentication} from './services/auth/RequireAuthentication';
import {DEFAULT_REDIRECT, PERMISSIONS} from './services/auth/constants';
import {CONSENT_TYPES} from './services/consent/consentTypes';
import {lazyRetry} from './utils/lazyRetry';
import {urlWildcard} from './utils/url';

// Lazy loaded pages
const StartPage = lazyRetry(
    () => import('./pages/User').then(module => ({default: module.StartPage})),
    'StartPage'
);

const PublicJobPosition = lazyRetry(
    () =>
        import('./pages/PublicJobPosition').then(module => ({
            default: module.PublicJobPosition as ComponentType<unknown>
        })),
    'PublicJobPosition'
);

const AuthenticateCandidate = lazyRetry(
    () =>
        import('./pages/AuthenticateCandidate').then(module => ({
            default: module.AuthenticateCandidate
        })),
    'AuthenticateCandidate'
);

const LogicTestIrt = lazyRetry(
    () => import('./pages/LogicTestIrt').then(module => ({default: module.LogicTestIrt})),
    'LogicTestIrt'
);

const PersonalityTestIrt = lazyRetry(
    () =>
        import('./pages/PersonalityTestIrt').then(module => ({
            default: module.PersonalityTestIrt
        })),
    'PersonalityTestIrt'
);

const Organization = lazyRetry(() => import('./pages/Organization'), 'Organization');
const SharedResults = lazyRetry(() => import('./pages/SharedResults'), 'SharedResults');

// Wrap pages that require Auth
const WrappedLogicTestIrt = requireAuthentication(LogicTestIrt);
const WrappedOrganization = requireAuthentication(Organization);
const WrappedPersonalityTestIrt = requireAuthentication(PersonalityTestIrt);
const WrappedUser = requireAuthentication(StartPage);

const routes: RouteObject[] = [
    {
        path: urlWildcard('/o'),
        element: <WrappedOrganization />
    },
    {
        path: urlWildcard('/start'),
        element: <WrappedUser />
    },
    {
        path: '/apply/:jobPositionId',
        element: <PublicJobPosition />
    },
    {
        path: urlWildcard(LINKS.freeTrialSignup),
        element: <SignupPageV2 />
    },
    {
        path: urlWildcard('/logic-test-irt'),
        element: <WrappedLogicTestIrt />
    },
    {
        path: urlWildcard('/logic-test-validation'),
        element: <LogicTestValidationPage />
    },
    {
        path: urlWildcard('/personality-test-irt'),
        element: <WrappedPersonalityTestIrt />
    },
    {
        path: urlWildcard('/personality-test-data-collection'),
        element: <PersonalityDataCollection />
    },
    {
        path: urlWildcard('/logic-test-data-collection'),
        element: <LogicTestDataCollection />
    },
    {
        path: urlWildcard('/shared-results'),
        element: <SharedResults />
    },
    {
        path: urlWildcard('/auth/candidate'),
        element: <AuthenticateCandidate />
    },
    {
        path: urlWildcard('/login'),
        element: <LoginPage />
    },
    {
        path: LINKS.oktaLogin,
        element: <OktaLoginPage />
    },
    {
        path: '/verify-oidc-authentication',
        element: <VerifyOidcAuthentication />
    },
    {
        path: '/verify-linkedin-authentication',
        element: <VerifyLinkedinAuthentication />
    },
    {
        path: '/readiness-data-collection/:organizationId',
        element: <ReadinessDataCollection />
    },
    {
        path: '/logout',
        element: <LogoutPage />
    },
    {
        path: '/login/recover',
        element: <RecoverPasswordPage />
    },
    {
        path: urlWildcard('/signup'),
        element: <SignupPage />
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />
    },
    {
        path: urlWildcard('/upgrade/:plan'),
        element: <UpgradePlanPage />
    },
    {
        path: LINKS.candidateServices,
        element: <ConsentPage type={CONSENT_TYPES.PRIVACY_POLICY_CANDIDATE_SERVICES} />
    },
    {
        path: LINKS.privacyPolicy,
        element: <ConsentPage type={CONSENT_TYPES.PRIVACY_POLICY} />
    },
    {
        path: LINKS.recruitmentServices,
        element: <ConsentPage type={CONSENT_TYPES.PRIVACY_POLICY} />
    },
    {
        path: LINKS.termsOfService,
        element: <ConsentPage type={CONSENT_TYPES.TERMS_OF_SERVICE} />
    },
    {
        path: LINKS.terms,
        element: <ConsentPage type={CONSENT_TYPES.TERMS_OF_SERVICE} />
    },
    {
        path: LINKS.security,
        element: <ConsentPage type={CONSENT_TYPES.SECURITY} />
    },
    {
        path: LINKS.dataProcessors,
        element: <ConsentPage type={CONSENT_TYPES.SUB_PROCESSORS} />
    },
    {
        path: '/sub-processors',
        element: <Navigate to={LINKS.dataProcessors} />
    },
    {
        path: '/invalid-link',
        element: <InvalidLink />
    },
    {
        path: urlWildcard('/impersonation/:token'),
        element: (
            <>
                <Impersonation />
                <ImpersonationBanner />
            </>
        )
    },
    {
        path: '/demographics-form',
        element: <UserDemographics />
    },
    {
        path: '/stripe-checkout-loading',
        element: (
            <RequireScope
                fallback={() => <NoAccessFallback />}
                permissions={[PERMISSIONS.MANAGE_ORGANIZATION_ACCOUNT]}
            >
                {() => (
                    <ErrorBoundary>
                        <StripeLoadingPage />
                    </ErrorBoundary>
                )}
            </RequireScope>
        )
    },
    {
        path: '/not-found',
        element: <NotFound />
    },
    {
        path: '/home',
        element: <Navigate to="/o" />
    },
    {
        path: '/',
        element: <Navigate to={DEFAULT_REDIRECT} />
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export const router = createBrowserRouter(routes);
