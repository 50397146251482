import {useApolloClient} from '@apollo/client';
import qs from 'qs';
import {useLocation, useNavigate} from 'react-router-dom';

import {useLogout} from '@/services/auth/useLogout';
import {usePusherClient} from '@/services/pusher';

const LOGIN_URL = '/login';
export function useLogoutLogic() {
    const client = useApolloClient();
    const {logout} = useLogout();
    const navigate = useNavigate();
    const location = useLocation();
    const {resetPusherClient} = usePusherClient();

    const getRedirectUrl = (locationSearch: string) => {
        const params = qs.parse(locationSearch, {ignoreQueryPrefix: true});
        if (params.redirect) {
            return `${LOGIN_URL}?redirect=${params.redirect}`;
        }
        return LOGIN_URL;
    };

    const doLogout = () => {
        resetPusherClient();
        client.cache.reset().then(() => {
            logout();
            const redirectUrl = getRedirectUrl(location.search);
            navigate(redirectUrl);
        });
    };

    return {doLogout};
}
