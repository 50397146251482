import {ErrorState} from '@/componentLibrary/blocks/ErrorState';
import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {Spinner} from '@/componentLibrary/components/spinners';
import {H2, P1} from '@/componentLibrary/components/typography';
import {LINKEDIN_SYNC_STATUS} from '@/pages/User/pages/VerifyLinkedinAuthentication/hooks/constants';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {DataCollectionEu} from '../../components/DataCollectionEu';
import {DataCollectionNonEu} from '../../components/DataCollectionNonEu';
import {useFindMoreJobOpportunitiesLogic} from './logic';
import {Wrapper} from './styled';
import {Props} from './types';

export const FindMoreJobOpportunities = ({
    userId,
    organizationId,
    onSuccess,
    redirectTo
}: Props) => {
    const {
        phrases,
        loadingIsEligibleForEuDataCollection,
        isEligibleForEuDataCollection,
        isEligibleForEuDataCollectionError,
        reloadIsEligibleForEuDataCollection,
        errorTitle,
        errorDescription,
        errorStatus,
        onError
    } = useFindMoreJobOpportunitiesLogic();

    const accessDenied = errorStatus === LINKEDIN_SYNC_STATUS.ACCESS_DENIED;
    const ErrorBanner = (
        <Banner
            type={accessDenied ? BannerType.ATTENTION : BannerType.ERROR}
            title={errorTitle}
        >
            {errorDescription}
        </Banner>
    );

    if (loadingIsEligibleForEuDataCollection) {
        return <Spinner />;
    }

    if (isEligibleForEuDataCollectionError) {
        return <ErrorState refetch={reloadIsEligibleForEuDataCollection} />;
    }

    const DataCollection =
        isEligibleForEuDataCollection && !accessDenied
            ? DataCollectionEu
            : DataCollectionNonEu;

    return (
        <Wrapper>
            <H2>{phrases.title}</H2>
            <P1>{phrases.description}</P1>
            {isDefined(errorDescription) && ErrorBanner}
            <DataCollection
                userId={userId}
                organizationId={organizationId}
                onSuccess={onSuccess}
                onError={onError}
                redirectTo={redirectTo}
            />
        </Wrapper>
    );
};
