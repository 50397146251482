import styled from 'styled-components';

import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {useResetPasswordFormLogic} from '@/pages/ResetPassword/components/ResetPasswordForm/useResetPasswordFormLogic.js';
import {
    HelpText,
    Progress
} from '@/pages/Signup/pages/RegisterUser/components/CreateAccountForm/components/EmailPasswordForm/components/PasswordInput/styled';
import {getProgressColor} from '@/pages/Signup/pages/RegisterUser/components/CreateAccountForm/components/EmailPasswordForm/components/PasswordInput/utils';

export function ResetPasswordForm() {
    const {
        loading,
        isPasswordValid,
        handleChange,
        passwordInfo,
        handleSubmit,
        handleConfirmPassword,
        showFailedConfirmation,
        phrases
    } = useResetPasswordFormLogic();

    return (
        <form className="form" onSubmit={handleSubmit}>
            <FormField>
                <TextField
                    type="password"
                    name="password"
                    onChange={handleChange}
                    autoComplete="off"
                    label={phrases.password}
                    $fullWidth
                />
                <Progress
                    $backgroundColor={getProgressColor(passwordInfo.strength)}
                    value={passwordInfo.strength + 1}
                    max={5}
                />
                <HelpText>{passwordInfo.feedback}</HelpText>
            </FormField>

            <FormField>
                <TextField
                    type="password"
                    name="validate_password"
                    onChange={handleConfirmPassword}
                    autoComplete="off"
                    label={phrases.repeatPassword}
                    $fullWidth
                />
                <HelpText>{showFailedConfirmation ? phrases.passwordMismatch : ''}</HelpText>
            </FormField>

            <FormField>
                <PrimaryButton
                    type="submit"
                    isLoading={loading}
                    disabled={!isPasswordValid}
                    isFullWidth
                >
                    {phrases.submit}
                </PrimaryButton>
            </FormField>
        </form>
    );
}

const FormField = styled.div`
    width: 100%;
    margin-bottom: 24px;

    &:first-child {
        margin-bottom: 10px;
    }
`;
