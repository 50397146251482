import {Separator} from '@/componentLibrary/components/Separator';
import {
    PlainButton,
    PlainNavLinkButton
} from '@/componentLibrary/components/buttons/PlainButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {Icon} from '@/componentLibrary/components/icons/Icon';
import {Icons} from '@/componentLibrary/components/icons/constants';
import {Caption, P2} from '@/componentLibrary/components/typography';
import {LINKS} from '@/links';
import {LinkedInDataCollectionDetailedInformation} from '@/pages/ReadinessDataCollection/components/LinkedinDataCollectionDetailedInformation';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';
import {userLinks} from '@/pages/User/links';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useDataCollectionLogic} from './logic';
import {ActionRow, Actions} from './styled';
import {Props} from './types';

export function DataCollectionEu({
    userId,
    organizationId,
    redirectTo,
    onError,
    onSuccess
}: Props) {
    const {
        phrases,
        linkedInCaption,
        detailedInformationModalLogic,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation,
        isRetrieveLinkedinDataLoading,
        onClickRetrieveLinkedinData,
        onRelevanceFormSubmitSuccess
    } = useDataCollectionLogic({
        userId,
        redirectTo,
        onSuccess,
        onError
    });

    if (relevanceFormModalLogic.modalIsOpen) {
        return (
            <RecommendationRelevanceForm
                close={relevanceFormModalLogic.closeModal}
                userId={userId}
                relevanceParametersOptions={relevanceParametersOptions}
                userRelevanceInformation={userRelevanceInformation}
                onSubmit={onRelevanceFormSubmitSuccess}
            />
        );
    }

    const sharedActionRow = (
        <ActionRow>
            <SecondaryButton
                onClick={onClickRetrieveLinkedinData}
                isLoading={isRetrieveLinkedinDataLoading}
            >
                <Icon icon={Icons.LINKEDIN_LOGO} />
                {phrases.retrieveLinkedInData}
            </SecondaryButton>
            <PlainButton onClick={relevanceFormModalLogic.openModal}>
                {phrases.addManually}
            </PlainButton>
        </ActionRow>
    );

    const dataCollectionPageActionRow = isDefined(organizationId) && (
        <>
            <Separator>
                <P2>{phrases.or}</P2>
            </Separator>
            <ActionRow>
                <PlainNavLinkButton to={userLinks.jobApplication(organizationId)}>
                    {phrases.maybeLater}
                </PlainNavLinkButton>
            </ActionRow>
        </>
    );

    const forDataCollectionPage =
        isDefined(organizationId) &&
        redirectTo === LINKS.readinessDataCollection(organizationId);

    const actionsSection = (
        <Actions>
            {sharedActionRow}
            {forDataCollectionPage && dataCollectionPageActionRow}
        </Actions>
    );

    const detailedInformationModalSection = detailedInformationModalLogic.modalIsOpen && (
        <LinkedInDataCollectionDetailedInformation
            close={detailedInformationModalLogic.closeModal}
        />
    );

    return (
        <>
            <Caption>{linkedInCaption}</Caption>
            {actionsSection}
            {detailedInformationModalSection}
        </>
    );
}
