import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {generateId} from '@/utils/misc';
import {useMemo} from 'react';
import {DragUpdate} from 'react-beautiful-dnd';
import {UserExperience} from '../../utils';
import messages from './messages';
import {LogicProps} from './types';

export const useWorkExperienceLogic = ({setWorkExperience, workExperience}: LogicProps) => {
    const phrases = useExtractPhraseConstants(messages);

    const addWorkExperience = () => {
        const newWorkExperience: UserExperience = {
            id: generateId(),
            jobTitle: '',
            companyName: '',
            description: '',
            startDate: undefined,
            endDate: undefined
        };

        setWorkExperience(prevWorkExperience => [...prevWorkExperience, newWorkExperience]);
    };

    const getRemoveWorkExperience = (id: string) => () => {
        const isNotExperienceToRemove = (experience: UserExperience) => experience.id !== id;
        const toUpdatedState = (prevState: UserExperience[]) =>
            prevState.filter(isNotExperienceToRemove);
        setWorkExperience(toUpdatedState);
    };

    const onDragEnd = (result: DragUpdate) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const reorderedItems = reorderList({
            list: workExperience,
            start: result.source.index,
            end: result.destination.index
        });

        setWorkExperience(reorderedItems);
    };

    const hasWorkExperience = useMemo(() => workExperience.length > 0, [workExperience]);

    return {
        phrases,
        hasWorkExperience,
        addWorkExperience,
        getRemoveWorkExperience,
        onDragEnd
    };
};

const reorderList = ({
    list,
    start,
    end
}: {list: UserExperience[]; start: number; end: number}) => {
    const result = [...list];
    const [removed] = result.splice(start, 1);
    result.splice(end, 0, removed);
    return result;
};
