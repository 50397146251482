import {parse} from 'qs';
import {useCallback, useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';

import {usePersonalityTestLocaleV2} from '@/api/personalityTests/usePersonalityTestLocaleV2';
import {useChangeLocaleForUser} from '@/api/users/useChangeLocaleForUser';
import {useCreateAnonymousUser} from '@/api/users/useCreateAnonymousUser';
import {useChangeLocale} from '@/containers/LanguageProvider/useChangeLocale';
import {
    RouteParams,
    UseDataCollectionLogicProps
} from '@/pages/PersonalityDataCollection/pages/DataCollectionForm/types';
import {logger} from '@/services/logrocket';

export function useDataCollectionLogic({showErrorToast}: UseDataCollectionLogicProps) {
    const {localeName} = useParams<RouteParams>();
    const location = useLocation();
    const localeToUse = localeName || 'en';

    const {
        error: errorLoadingLocale,
        loading: loadingLocale,
        personalityTestLocaleV2
    } = usePersonalityTestLocaleV2(localeToUse);
    const [doCreateAnonymousUser, {loading: creatingUser}] = useCreateAnonymousUser();
    const [isUserCreated, setIsUserCreated] = useState(false);
    const [externalId, setExternalId] = useState(null);
    const [doChangeLocaleForUser] = useChangeLocaleForUser();
    const {changeLocale} = useChangeLocale();

    const isDataCollectionAllowed = personalityTestLocaleV2?.isDataCollectionEnabled;

    useEffect(() => {
        changeLocale(localeName);
    }, [changeLocale, localeName]);

    const createUser = useCallback(
        externalUserId => {
            doCreateAnonymousUser().then(({ok, userId}) => {
                if (!ok) {
                    showErrorToast();
                    if (userId) {
                        doChangeLocaleForUser(userId, localeToUse).catch(e => {
                            // Use localeToUse here
                            logger.error(e);
                        });
                    }
                } else {
                    setExternalId(externalUserId);
                    setIsUserCreated(true);
                }
            });
        },
        [doCreateAnonymousUser, showErrorToast, localeToUse, doChangeLocaleForUser] // Update dependencies
    );

    return {
        localeName: localeToUse,
        loading: loadingLocale,
        errorLoadingLocale,
        isDataCollectionAllowed,
        contentType: parse(location.search, {ignoreQueryPrefix: true}).source,
        creatingUser,
        doCreateAnonymousUser: createUser,
        externalId,
        isUserCreated
    };
}
