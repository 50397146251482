import {DiscardAlert} from '@/componentLibrary/blocks/Modals/Alert/DiscardAlert';
import {DestructiveButton} from '@/componentLibrary/components/buttons/DestructiveButton';
import {DraggableCard} from '@/componentLibrary/components/cards/InteractableCard';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextEditor} from '@/componentLibrary/components/inputs/TextEditor';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {TextFieldLabel} from '@/componentLibrary/components/inputs/styled';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {toHtml} from '@/pages/Organization/utils';
import {useIntl} from 'react-intl';
import {DateDropdowns} from './DateDropdowns';
import {useWorkExperienceItemLogic} from './logic';
import messages from './messages';
import {Content, DatesWrapper} from './styled';
import {Props} from './types';

export const WorkExperienceItem = ({
    workExperience,
    onUpdateField,
    removeWorkExperience,
    workExperienceHasFieldError,
    invalid,
    focusOnErrors,
    submitTimestamp
}: Props) => {
    const phrases = useExtractPhraseConstants(messages);
    const intl = useIntl();
    const {
        jobTitleRef,
        companyNameRef,
        focusOnErrorStartDate,
        focusOnErrorEndDate,
        handleJobTitleChange,
        handleCompanyNameChange,
        handleDescriptionChange,
        handleStartDateChange,
        handleEndDateChange,
        removeModalIsOpen,
        closeRemoveModal,
        onRemove,
        accordionExpanded,
        onAccordionToggle,
        indicateErrors
    } = useWorkExperienceItemLogic({
        onUpdateField,
        workExperience,
        removeWorkExperience,
        workExperienceHasFieldError,
        invalid,
        focusOnErrors,
        submitTimestamp
    });

    const RemoveButton = (
        <DestructiveButton onClick={onRemove} small>
            {phrases.removeButtonText}
        </DestructiveButton>
    );

    const RemoveModal = (
        <DiscardAlert
            title={phrases.removeExperienceTitle}
            subtitle={intl.formatMessage(messages.removeExperienceDescription, {
                workExperienceJobTitle: workExperience.jobTitle
            })}
            leftButton={phrases.cancel}
            rightButton={phrases.removeExperienceTitle}
            onLeftAction={closeRemoveModal}
            onRightAction={removeWorkExperience}
        />
    );

    return (
        <DraggableCard
            title={workExperience.jobTitle}
            dismissActionButton={RemoveButton}
            accordion
            accordionExpanded={accordionExpanded}
            onAccordionToggle={onAccordionToggle}
        >
            {removeModalIsOpen && RemoveModal}
            <Content>
                <FormField smallMargin>
                    <TextField
                        $fullWidth
                        type="text"
                        value={workExperience.jobTitle}
                        label={phrases.jobTitle}
                        onChange={handleJobTitleChange}
                        isRequired
                        hasError={
                            indicateErrors &&
                            workExperienceHasFieldError(workExperience.id, 'jobTitle')
                        }
                        placeholder={''}
                        ref={jobTitleRef}
                    />
                </FormField>

                <FormField smallMargin>
                    <TextField
                        $fullWidth
                        type="text"
                        value={workExperience.companyName}
                        label={phrases.companyName}
                        onChange={handleCompanyNameChange}
                        isRequired
                        hasError={
                            indicateErrors &&
                            workExperienceHasFieldError(workExperience.id, 'companyName')
                        }
                        placeholder={''}
                        ref={companyNameRef}
                    />
                </FormField>

                <FormField smallMargin>
                    <TextFieldLabel>{phrases.description}</TextFieldLabel>
                    <TextEditor
                        height={200}
                        fullWidth
                        content={toHtml(workExperience.description)}
                        onChange={handleDescriptionChange}
                    />
                </FormField>
                <DatesWrapper>
                    <DateDropdowns
                        title={phrases.startDate}
                        selectedDate={workExperience.startDate}
                        onUpdate={handleStartDateChange}
                        hasError={
                            indicateErrors &&
                            workExperienceHasFieldError(workExperience.id, 'startDate')
                        }
                        focusOnErrors={focusOnErrorStartDate}
                    />
                    <DateDropdowns
                        title={phrases.endDate}
                        includePresent
                        selectedDate={workExperience.endDate}
                        onUpdate={handleEndDateChange}
                        hasError={
                            indicateErrors &&
                            workExperienceHasFieldError(workExperience.id, 'endDate')
                        }
                        focusOnErrors={focusOnErrorEndDate}
                    />
                </DatesWrapper>
            </Content>
        </DraggableCard>
    );
};
