import styled from 'styled-components';

export type FlexLayoutProps = {
    $direction?: string;
    $wrap?: string;
    $alignItems?: string;
    $justifyContent?: string;
    $gap?: string;
    $fullWidth?: boolean;
};

export const FlexLayout = styled.div<FlexLayoutProps>`
    display: flex;
    flex-flow: ${p => p.$direction ?? 'row'} ${p => p.$wrap ?? 'nowrap'};
    align-items: ${p => (p.$alignItems ? p.$alignItems : 'normal')};
    justify-content: ${p => (p.$justifyContent ? p.$justifyContent : 'normal')};
    ${({$gap}) => $gap && `gap: ${$gap};`}
    ${({$fullWidth}) => $fullWidth && `width: 100%;`}
`;
