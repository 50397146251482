import {FormEvent, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {Banner} from '@/componentLibrary/components/banners/Banner';
import {BannerType} from '@/componentLibrary/components/banners/constants';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {FormField} from '@/componentLibrary/components/inputs/FormField';
import {TextField} from '@/componentLibrary/components/inputs/TextField';
import {Spinner} from '@/componentLibrary/components/spinners';
import {H4} from '@/componentLibrary/components/typography';
import {getConfig} from '@/config';
import {useOrgFormValidator} from '@/pages/SignupV2/components/SSO/CreateOrganizationForm/logic';
import {FieldState, SsoToken} from '@/pages/SignupV2/components/types';
import {
    CreationResult,
    useCreateFreeTrialLogic
} from '@/pages/SignupV2/hooks/useCreateFreeTrialLogic';
import {WhiteBox} from '@/pages/SignupV2/styled';
import {SsoAuthData} from '@/pages/SignupV2/types';
import {useTermsOfServiceVersion} from '@/services/consent/useTermsOfServiceVersion';

import messages from './messages';

declare const grecaptcha: {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options: {action: string}) => Promise<string>;
};

export function CreateOrganizationForm({ssoData}: {ssoData: SsoAuthData}) {
    const intl = useIntl();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [referralSource, setReferralSource] = useState('');
    const ssoToken: SsoToken = useMemo(() => {
        return {
            ssoToken: ssoData.ssoToken,
            ssoProvider: ssoData.ssoProvider
        };
    }, [ssoData.ssoProvider, ssoData.ssoToken]);

    const {termsOfServiceVersion, loadingTermsOfServiceVersion} = useTermsOfServiceVersion();
    const {createFreeTrial, result, loading, doSsoSignin} = useCreateFreeTrialLogic(
        {
            email: ssoData.email,
            firstName: ssoData.firstName,
            lastName: ssoData.lastName,
            phoneNumber: phone,
            organizationName: name,
            referralSource: referralSource,
            termsOfServiceVersion
        },
        ssoToken
    );

    const {stopFormSubmit, fieldStates, handleInvalidSubmit} = useOrgFormValidator(
        name,
        phone,
        referralSource
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (result === CreationResult.OK) {
            navigate('/login');
        } else if (result === CreationResult.USER_ALREADY_ADMIN_IN_ORGANIZATION) {
            doSsoSignin();
        }
    }, [result, navigate, ssoToken, doSsoSignin]);

    if (
        result === CreationResult.OK ||
        result === CreationResult.USER_ALREADY_ADMIN_IN_ORGANIZATION
    ) {
        return <Spinner />;
    }

    if (!ssoData || Object.keys(ssoData).length === 0 || !ssoData.email) {
        return (
            <WhiteBox>
                <Banner type={BannerType.ERROR}>
                    <FormattedMessage {...messages.error} />
                </Banner>
            </WhiteBox>
        );
    }

    const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
        const {value} = e.currentTarget;
        setName(value);
    };

    const handlePhoneChange = (e: FormEvent<HTMLInputElement>) => {
        const {value} = e.currentTarget;
        setPhone(value);
    };

    const handleReferralSourceChange = (e: FormEvent<HTMLInputElement>) => {
        const {value} = e.currentTarget;
        setReferralSource(value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (stopFormSubmit) {
            handleInvalidSubmit();
            return;
        }

        typeof grecaptcha !== 'undefined' &&
            grecaptcha.ready(function () {
                grecaptcha
                    .execute(getConfig().RECAPTCHA_SITE_KEY, {action: 'submit'})
                    .then(function (token: string) {
                        createFreeTrial(token);
                    });
            });
    };

    return (
        <WhiteBox>
            <H4>
                <FormattedMessage {...messages.title} />
            </H4>
            <form id="create-organization-from-sso" onSubmit={handleSubmit}>
                <FormField>
                    <TextField
                        $fullWidth
                        type="text"
                        isRequired
                        name="createOrgName"
                        data-testid="createOrgName"
                        value={name}
                        $isClearable={false}
                        onChange={handleNameChange}
                        errorMessage={intl.formatMessage(messages.orgNameError)}
                        hasError={fieldStates['organizationName'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.orgNameLabel} />}
                        helpText={<FormattedMessage {...messages.orgNameDisclaimer} />}
                        placeholder={' '}
                    />
                </FormField>
                <FormField>
                    <TextField
                        $fullWidth
                        type="tel"
                        isRequired
                        name="createOrgPhone"
                        data-testid="createOrgPhone"
                        value={phone}
                        $isClearable={false}
                        onChange={handlePhoneChange}
                        errorMessage={intl.formatMessage(messages.phoneError)}
                        hasError={fieldStates['phoneNumber'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.phoneLabel} />}
                        helpText={<FormattedMessage {...messages.phoneDisclaimer} />}
                        placeholder={' '}
                    />
                </FormField>
                <FormField>
                    <TextField
                        $fullWidth
                        type="text"
                        isRequired
                        name="createOrgReferralSource"
                        data-testid="createOrgReferralSource"
                        value={referralSource}
                        $isClearable={false}
                        onChange={handleReferralSourceChange}
                        errorMessage={intl.formatMessage(messages.referralSourceError)}
                        hasError={fieldStates['referralSource'] === FieldState.INVALID}
                        label={<FormattedMessage {...messages.referralSourceLabel} />}
                        placeholder={' '}
                    />
                </FormField>
                {result === CreationResult.RECAPTCHA_TOKEN_ERROR && (
                    <ErrorBannerWrap>
                        <Banner type={BannerType.ERROR}>
                            <FormattedMessage {...messages.errorRecaptcha} />
                        </Banner>
                    </ErrorBannerWrap>
                )}
                <FormField>
                    <PrimaryButton
                        isFullWidth
                        isLoading={loading}
                        type="submit"
                        disabled={loadingTermsOfServiceVersion}
                    >
                        <FormattedMessage {...messages.createOrg} />
                    </PrimaryButton>
                </FormField>
            </form>
        </WhiteBox>
    );
}

const ErrorBannerWrap = styled.div`
    margin-bottom: 10px;
`;
