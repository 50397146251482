import {Modal} from '@/componentLibrary/blocks/Modals/Modal';
import {MODAL_SIZE} from '@/componentLibrary/blocks/Modals/Modal/constants';
import {PlainButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {SecondaryButton} from '@/componentLibrary/components/buttons/SecondaryButton';
import {useExtractPhraseConstants} from '@/hooks/useExtractPhraseConstants';
import {isDefined} from '@/utils/typeGuards/isDefined';

import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import messages from './messages';
import {DescriptionWrapper, InstructionImage} from './styled';
import {TutorialCardProps} from './types';

export function TutorialCard({
    title,
    description,
    nextButtonText,
    previousButtonText,
    skipButtonText,
    showPrevious = true,
    onSkip,
    onPrevious,
    onNext,
    cardImage,
    activeStep
}: TutorialCardProps) {
    const phrases = useExtractPhraseConstants(messages);
    const navigate = useNavigate();
    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);
    return (
        <Modal
            close={() => null}
            hideClose
            size={MODAL_SIZE.MEDIUM}
            isVerticallyCentered
            headerTitle={title}
            stepper={activeStep ? {noOfSteps: 3, activeStep: activeStep} : undefined}
            footer={{
                leftSecondaryAction: (
                    <PlainButton onClick={onSkip}>
                        {skipButtonText || phrases.skip}
                    </PlainButton>
                ),
                rightSecondaryAction: showPrevious ? (
                    <SecondaryButton onClick={onPrevious} isFullWidth>
                        {previousButtonText || phrases.previous}
                    </SecondaryButton>
                ) : (
                    <SecondaryButton isFullWidth onClick={goBack}>
                        {phrases.goBack}
                    </SecondaryButton>
                ),
                primaryAction: (
                    <PrimaryButton onClick={onNext} isFullWidth>
                        {nextButtonText || phrases.next}
                    </PrimaryButton>
                )
            }}
        >
            {isDefined(cardImage) && <InstructionImage src={cardImage} />}
            <DescriptionWrapper>{description}</DescriptionWrapper>
        </Modal>
    );
}
