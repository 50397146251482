import styled, {css} from 'styled-components';

import {SubtitleS2} from '@/componentLibrary/tokens/typography';
import {
    ColorBaseRed900,
    ColorFgError,
    ColorFgMuted,
    ColorFgSubtle,
    SpacingXxsmall,
    SpacingXxxsmall
} from '@/componentLibrary/tokens/variables';

import {Caption} from '../typography';

type Props = {
    $isRequired?: boolean;
    className?: string;
    $color?: string;
    htmlFor?: string;
    $hasSublabel?: boolean;
};

export const TextFieldLabel = styled.label<Props>`
    ${SubtitleS2};
    display: block;
    ${p => css`margin-bottom: ${p.$hasSublabel ? SpacingXxxsmall : SpacingXxsmall};`}
    color: ${({$color}) => $color};

    ${({$isRequired}) =>
        $isRequired &&
        css`
            &::after {
                color: ${ColorBaseRed900};
                content: ' *';
            }
        `}
`;

export const TextFieldSublabel = styled(Caption)`
    white-space: pre-wrap;
    color: ${ColorFgMuted};
    margin-bottom: ${SpacingXxsmall};
`;

export const TextFieldErrorMessage = styled(Caption)`
    display: block;
    margin-top: ${SpacingXxsmall};
    color: ${ColorFgError};
`;

export const TextFieldHelpText = styled(Caption)`
    display: block;
    margin-top: ${SpacingXxsmall};
    color: ${ColorFgSubtle};
`;
