import {defineMessages} from 'react-intl';

export default defineMessages({
    january: {id: 'app.general.date.january'},
    february: {id: 'app.general.date.february'},
    march: {id: 'app.general.date.march'},
    april: {id: 'app.general.date.april'},
    may: {id: 'app.general.date.may'},
    june: {id: 'app.general.date.june'},
    july: {id: 'app.general.date.july'},
    august: {id: 'app.general.date.august'},
    september: {id: 'app.general.date.september'},
    october: {id: 'app.general.date.october'},
    november: {id: 'app.general.date.november'},
    december: {id: 'app.general.date.december'},
    month: {id: 'app.general.date.month'},
    year: {id: 'app.general.date.year'},
    present: {id: 'app.pages.user.candidateRelevanceModal.workExperience.date.present'}
});
