import {LogicTest} from '@/pages/LogicTestIrt/pages/LogicTest';
import {useParams} from 'react-router-dom';

export const LogicTestWrapper = () => {
    const {logicTestId} = useParams();

    if (!logicTestId) {
        return null;
    }

    return <LogicTest isValidationTest logicTestId={logicTestId} />;
};
