import {parse} from 'qs';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';

import {useCreateAnonymousUser} from '@/api/users/useCreateAnonymousUser';

export function useDataCollectionLogic(showErrorToast: () => string) {
    const params = useParams<{studyId: string}>();
    const [doCreateAnonymousUser, {loading: creatingUser}] = useCreateAnonymousUser();
    const [isUserCreated, setIsUserCreated] = useState(false);
    const [externalId, setExternalId] = useState(null);
    const source = parse(location.search, {ignoreQueryPrefix: true}).source;
    const studyId = params.studyId;

    const createUser = useCallback(
        externalUserId => {
            if (!source || !studyId) {
                return;
            }
            doCreateAnonymousUser().then(({ok}) => {
                if (!ok) {
                    showErrorToast();
                } else {
                    setExternalId(externalUserId);
                    setIsUserCreated(true);
                }
            });
        },
        [doCreateAnonymousUser, showErrorToast, source, studyId]
    );

    return {
        creatingUser,
        createUser,
        externalId,
        isUserCreated,
        source,
        studyId
    };
}
