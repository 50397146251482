import {gql} from '../types/__generated__';

export const UPDATE_USER_RELEVANCE_INFORMATION = gql(`
    mutation updateUserRelevanceInformation($data: UpdateUserRelevanceInformationInput!) {
        updateUserRelevanceInformation(data: $data) {
            ok
            errorMessage
            userRelevanceInformation {
                userId
                preferredJobFamily
                interestedInLeadershipPositions
                preferredWorkLocations
                preferredSpecialties
                preferredCitiesWithMetaData {
                    id
                    name
                    countryName
                }
                spokenLanguages
                linkedinProfileUrl
                userExperiences {
                    id
                    jobTitle
                    description
                    startDate
                    endDate
                    companyName
                }
            }
        }
    }
`);

export const UPDATE_JOB_RELEVANCE_INFORMATION = gql(`
    mutation updateJobRelevanceData($data: UpdateJobRelevanceDataInput!) {
        updateJobRelevanceData(data: $data) {
            ok
            errorMessage
            jobRelevanceInformation {
                jobPositionId
                citiesWithMetaData {
                    id
                    name
                    countryName
                }
                jobFamily
                jobFamilySpecialties
                levelOfExperience
                hasLeadershipResponsibilities
                requiredLanguages
                roleDescription
                familiarityDescription
            }
        }
    }
`);

export const REJECT_JOB_RECOMMENDATION = gql(`
    mutation rejectJobRecommendation($data: RejectJobRecommendationInput!) {
        rejectJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
        }
    }
`);

export const ACCEPT_JOB_RECOMMENDATION = gql(`
    mutation acceptJobRecommendation($data: AcceptJobRecommendationInput!) {
        acceptJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
        }
    }
`);

export const APPLY_TO_JOB_RECOMMENDATION = gql(`
    mutation applyToJobRecommendation($data: ApplyToJobRecommendationInput!) {
        applyToJobRecommendation(data: $data) {
            ok
            errorMessage
            jobRecommendationId
        }
    }
`);

export const UPDATE_JOB_RECOMMENDATION_SETTINGS = gql(`
    mutation updateJobRecommendationSettings($data: UpdateJobRecommendationSettingsInput!) {
        updateJobRecommendationSettings(data: $data) {
            ok
            errorMessage
            jobMarketplaceSettings {
                recommendationsEnabled
            }
        }
    }
`);

export const UPDATE_ORGANIZATION_MARKETPLACE_SETTINGS = gql(`
    mutation updateOrganizationMarketplaceSettings(
        $data: UpdateOrganizationMarketplaceSettingsInput!
    ) {
        updateOrganizationMarketplaceSettings(data: $data) {
            ok
            errorMessage
            organizationMarketplaceSettings {
                organizationId
                autoPublishJobsToMarketplace
            }
        }
    }
`);

export const TRIGGER_LINKEDIN_USER_DATA_SYNC = gql(`
    mutation triggerLinkedinUserDataSync($data: TriggerLinkedinUserDataSyncInput!) {
        triggerLinkedinUserDataSync(data: $data) {
            ok
            errorMessage
        }
    }
`);
