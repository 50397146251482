import {PlainNavLinkButton} from '@/componentLibrary/components/buttons/PlainButton';
import {PrimaryButton} from '@/componentLibrary/components/buttons/PrimaryButton';
import {RecommendationRelevanceForm} from '@/pages/User/components/RecommendationRelevanceForm';
import {userLinks} from '@/pages/User/links';
import {isDefined} from '@/utils/typeGuards/isDefined';
import {useDataCollectionNonEuLogic} from './logic';
import {ActionRow, Actions, Wrapper} from './styled';
import {Props} from './types';

export function DataCollectionNonEu({userId, organizationId, onSuccess}: Props) {
    const {
        phrases,
        relevanceFormModalLogic,
        relevanceParametersOptions,
        userRelevanceInformation,
        onRelevanceFormSubmitSuccess
    } = useDataCollectionNonEuLogic({
        userId,
        onSuccess
    });

    if (relevanceFormModalLogic.modalIsOpen) {
        return (
            <RecommendationRelevanceForm
                close={relevanceFormModalLogic.closeModal}
                userId={userId}
                relevanceParametersOptions={relevanceParametersOptions}
                userRelevanceInformation={userRelevanceInformation}
                onSubmit={onRelevanceFormSubmitSuccess}
            />
        );
    }

    return (
        <Wrapper>
            <Actions>
                <ActionRow>
                    <PrimaryButton onClick={relevanceFormModalLogic.toggleModal}>
                        {phrases.addInformation}
                    </PrimaryButton>
                    {isDefined(organizationId) && (
                        <PlainNavLinkButton to={userLinks.jobApplication(organizationId)}>
                            {phrases.maybeLater}
                        </PlainNavLinkButton>
                    )}
                </ActionRow>
            </Actions>
        </Wrapper>
    );
}
