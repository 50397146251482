import {Wrapper} from './styled';

import {H4} from '@/componentLibrary/components/typography';
import icon1 from './assets/icon1.svg';
import icon2 from './assets/icon2.svg';
import icon3 from './assets/icon3.svg';
import {Props} from './types';

export const HighlightedJobCard = ({title, index}: Props) => {
    return (
        <Wrapper $width={288 + index * 16}>
            <img src={[icon1, icon2, icon3][index]} />
            <H4>{title}</H4>
        </Wrapper>
    );
};
